<script setup>
import {ref} from "vue";

const props = defineProps(['order']);

const reason_for_non_payment = ref('');
const errors = ref([]);
const lost_during_transportation_reason = ref('');
const fire = ref('');
const goods_stolen = ref('');
const flood = ref('');
const date_of_occurance = ref('');
const makeClaim = () => {
    axios.post('/apis/v4/insurance/claim', {
        order: props.order,
        reason_for_non_payment: reason_for_non_payment.value,
        lost_during_transportation_reason: lost_during_transportation_reason.value,
        fire: fire.value,
        goods_stolen: goods_stolen.value,
        flood: flood.value,
        date_of_occurance: date_of_occurance.value,
    });
}
</script>

<template>
    <div>
        <div class="row">
            <div class="col-md-12">
                <div class="alert alert-warning" role="alert">
                    <strong>If the buyer cannot make payment now, please fill this form to see the support they are
                        eligible for.</strong>
                </div>
            </div>
        </div>
        <div class="row">
            <form action="">
                <div class="row gap-3">
                    <div class="col-md-12">
                        <!--Label: Why Can't the buyer make payment?, Attributes:reason_for_non_payment -->
                        <div class="form-floating">
                            <select type="text" class="form-control form-select" id="reason_for_non_payment"
                                    v-model="reason_for_non_payment"
                                    aria-describedby="reason_for_non_payment-help"
                                    :class="[errors.reason_for_non_payment ? 'is-invalid': '',!errors.reason_for_non_payment && Object.keys(errors).length > 1 ? 'is-valid': '']"
                            >
                                <option value="goods_lost_in_transportation">The goods were lost during transportation
                                </option>
                                <option value="goods_lost_by_seller_in_storage">The seller lost the goods in their
                                    storage
                                </option>
                                <option value="goods_lost_by_buyer_in_storage">The buyer lost the goods in their
                                    storage
                                </option>
                                <option value="buyer_was_sick">The buyer was sick</option>
                                <option value="buyer_family_member_was_sick">My family member of the buyer was
                                    sick/died
                                </option>
                                <option value="buyer_is_dead">The buyer is dead</option>
                            </select>
                            <label for="reason_for_non_payment">Why Can't the buyer make payment?</label>
                            <div class="invalid-feedback" v-if="errors.reason_for_non_payment">
                                {{ errors.reason_for_non_payment.toString() }}
                            </div>
                        </div>
                    </div>
                    <div class="col-md-12">
                        <div>
                            <p>What happened in transit.</p>
                        </div>
                        <div class="row">
                            <div class="col-md-6">
                                <div class="form-check form-switch form-check-inline">
                                    <label for="lost_during_transportation_reason" class="form-label">Accident</label>
                                    <input type="checkbox" class="form-check-input"
                                           id="lost_during_transportation_reason"
                                           v-model="lost_during_transportation_reason"
                                           aria-describedby="lost_during_transportation_reason-help"
                                           :class="[errors.lost_during_transportation_reason ? 'is-invalid': '',!errors.lost_during_transportation_reason && Object.keys(errors).length > 1 ? 'is-valid': '']"
                                           placeholder="What happened in transit" required>
                                    <div class="invalid-feedback" v-if="errors.lost_during_transportation_reason">
                                        {{ errors.lost_during_transportation_reason.toString() }}
                                    </div>
                                </div>
                                <div class="form-check form-switch">
                                    <label for="fire" class="form-label">Fire</label>
                                    <input type="checkbox" class="form-check-input" id="fire" v-model="fire"
                                           aria-describedby="fire-help"
                                           :class="[errors.fire ? 'is-invalid': '',!errors.fire && Object.keys(errors).length > 1 ? 'is-valid': '']"
                                    >
                                    <div class="invalid-feedback" v-if="errors.fire">
                                        {{ errors.fire.toString() }}
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="form-check form-switch form-check-inline">
                                    <label for="goods_stolen" class="form-label">Goods stolen</label>
                                    <input type="checkbox" class="form-check-input" id="goods_stolen"
                                           v-model="goods_stolen"
                                           aria-describedby="goods_stolen-help"
                                           :class="[errors.goods_stolen ? 'is-invalid': '',!errors.goods_stolen && Object.keys(errors).length > 1 ? 'is-valid': '']"
                                           placeholder="Goods stolen" required>
                                    <div class="invalid-feedback" v-if="errors.goods_stolen">
                                        {{ errors.goods_stolen.toString() }}
                                    </div>
                                </div>
                                <div class="form-check form-switch form-check-inline">
                                    <label for="flood" class="form-label">Flood</label>
                                    <input type="checkbox" class="form-check-input" id="flood" v-model="flood"
                                           aria-describedby="flood-help"
                                           :class="[errors.flood ? 'is-invalid': '',!errors.flood && Object.keys(errors).length > 1 ? 'is-valid': '']"
                                           placeholder="Flood" required>
                                    <div class="invalid-feedback" v-if="errors.flood">
                                        {{ errors.flood.toString() }}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-md-12">
                        <!--Label: When did the incident occur, Attributes:date_of_occurance -->
                        <div class="form-floating">
                            <input type="date" class="form-control" id="date_of_occurance" v-model="date_of_occurance"
                                   aria-describedby="date_of_occurance-help"
                                   :class="[errors.date_of_occurance ? 'is-invalid': '',!errors.date_of_occurance && Object.keys(errors).length > 1 ? 'is-valid': '']"
                            >
                            <label for="date_of_occurance" class="form-label">When did the incident occur</label>
                            <div class="invalid-feedback" v-if="errors.date_of_occurance">
                                {{ errors.date_of_occurance.toString() }}
                            </div>
                        </div>
                    </div>
                    <div class="col-md-12">
                        <!--Label: Upload a hospital report or death certificate, Attributes:hospital_report -->
                        <div class="form-group">
                            <label for="hospital_report">Upload a hospital report or death certificate</label>

                            <input type="file" class="form-control form-control-lg" id="hospital_report"
                                   aria-describedby="hospital_report-help"
                                   :class="[errors.hospital_report ? 'is-invalid': '',!errors.hospital_report && Object.keys(errors).length > 1 ? 'is-valid': '']"
                            >
                            <div class="invalid-feedback" v-if="errors.hospital_report">
                                {{ errors.hospital_report.toString() }}
                            </div>
                        </div>
                    </div>
                    <div class="col-md-12">
                        <div class="d-flex justify-content-end">
                            <button class="btn btn-success" @click.prevent="makeClaim()">
                                Submit
                            </button>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </div>
</template>

<style scoped>

</style>
