<script setup>
import {onMounted, ref} from "vue";

const markets = ref([]);
const selectedMarkets = ref([]);
const pagination = ref('');
const links = ref([]);
const confirmingSelection = ref(false);
const policies = ref(false);

const confirmSelection = () => {
    confirmingSelection.value = true;
    axios.post('/apis/v4/insurance/activate', {
        'markets': selectedMarkets.value
    }).then(resp => {
        confirmingSelection.value = false;
        var myModalEl = document.querySelector(
            "#addinsurance"
        );
        var modal = bootstrap.Modal.getOrCreateInstance(myModalEl);
        modal.toggle();
        getMarkets();
        selectedMarkets.value = [];
    }).catch(() => {
        confirmingSelection.value = false;

    }).finally(() => {
        confirmingSelection.value = false;
    });
}
onMounted(() => {
    selectedMarkets.value = [];
    getMarkets();
});
const claims = ref([]);
function getMarkets(url = `/apis/v4/insurance/setup?page=1`) {
    axios.get(url).then(resp => {
        markets.value = resp.data.markets.data;
        pagination.value = resp.data.markets;
        if (Object.keys(pagination.value.links).length > 4) {
            links.value = pagination.value.links.splice(pagination.value?.current_page, 3);
        }
        policies.value = resp.data.policies;
        claims.value = resp.data.claims;
    });
}
</script>

<template>
    <div class="card">
        <div class="card-body">
            <ul class="nav nav-pills mb-3" role="tablist">
                <li class="nav-item" role="presentation">
                    <a class="nav-link active" data-bs-toggle="pill" href="#primary-pills-home" role="tab"
                       aria-selected="true">
                        <div class="d-flex align-items-center">
                            <div class="tab-icon"><i class='bx bx-home font-18 me-1'></i>
                            </div>
                            <div class="tab-title">Markets</div>
                        </div>
                    </a>
                </li>
                <li class="nav-item" role="presentation">
                    <a class="nav-link" data-bs-toggle="pill" href="#primary-pills-profile" role="tab"
                       aria-selected="false">
                        <div class="d-flex align-items-center">
                            <div class="tab-icon"><i class='bx bx-user-pin font-18 me-1'></i>
                            </div>
                            <div class="tab-title">Policies</div>
                        </div>
                    </a>
                </li>
                <li class="nav-item" role="presentation">
                    <a class="nav-link" data-bs-toggle="pill" href="#primary-pills-contact" role="tab"
                       aria-selected="false">
                        <div class="d-flex align-items-center">
                            <div class="tab-icon"><i class='bx bx-microphone font-18 me-1'></i>
                            </div>
                            <div class="tab-title">Claims</div>
                        </div>
                    </a>
                </li>
            </ul>
            <div class="tab-content" id="pills-tabContent">
                <div class="tab-pane fade show active" id="primary-pills-home" role="tabpanel">
                    <div>
                        <div class="table-responsive">
                            <div class="d-flex justify-content-end" v-if="Object.keys(selectedMarkets).length > 0">
                                <button class="btn btn-outline-secondary btn-sm" data-bs-toggle="modal"
                                        data-bs-target="#addinsurance">
                                    Add Insurance
                                    <i class="fas fa-plus-circle fa-sm fa-fw"></i>
                                </button>
                                <div class="modal fade" id="addinsurance" tabindex="-1" role="dialog"
                                     aria-labelledby="addinsuranceId"
                                     aria-hidden="true">
                                    <div class="modal-dialog" role="document">
                                        <div class="modal-content">
                                            <div class="modal-header">
                                                <h4 class="modal-title" id="addinsuranceId">Add Insurance </h4>
                                                <button type="button" class="btn-close" data-bs-dismiss="modal"
                                                        aria-label="Close">
                                                </button>
                                            </div>
                                            <div class="modal-body">
                                                <div class="container-fluid">
                                                    <div class="row">
                                                        <p>
                                                            <strong>{{ Object.keys(selectedMarkets).length }}</strong>
                                                            markets will have their insurance activated.
                                                        </p>

                                                    </div>
                                                </div>
                                            </div>
                                            <div class="modal-footer">
                                                <button type="button" class="btn btn-outline-secondary"
                                                        data-bs-dismiss="modal">Cancel
                                                </button>
                                                <button type="button" class="btn btn-success"
                                                        @click.prevent="confirmSelection">
                                                    Confirm
                                                    <i class="fa fa-spin fa-spinner" v-if="confirmingSelection"></i>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <table class="table table-striped table-condensed table-fit table-sm">
                                <thead>
                                <tr>
                                    <th></th>
                                    <th>#</th>
                                    <th>Name</th>
                                    <th>Claims Made</th>
                                    <th>Transactions Insured</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr v-for="(market,index) in markets">
                                    <td scope="row">
                                        <div class="form-check">
                                            <label class="form-check-label">
                                                <input class="form-check-input" name="chooseforinsurance"
                                                       id="chooseforinsurance"
                                                       type="checkbox" :value="market.id"
                                                       v-model="selectedMarkets"
                                                       aria-label="Choose For Insurance">
                                            </label>
                                        </div>
                                    </td>
                                    <td scope="row">
                                        {{ (index + 1) }}
                                    </td>
                                    <td>{{ market.name }}</td>
                                    <td>0</td>
                                    <td>0</td>
                                </tr>
                                </tbody>
                            </table>
                            <div class="d-flex justify-content-end">
                                <nav aria-label="Page navigation example">
                                    <ul class="pagination">
                                        <li class="page-item">
                                            <a class="page-link" @click.prevent="getMarkets(pagination.prev_page_url)">
                                                Previous
                                            </a>
                                        </li>

                                        <li class="page-item" :class="link.active ? 'active': ''" v-for="link in links">
                                            <a class="page-link" @click.prevent="getMarkets(link.url)">
                                                {{ link.label }}
                                            </a>
                                        </li>
                                        <li class="page-item">
                                            <a class="page-link" @click.prevent="getMarkets(pagination.next_page_url)">
                                                Next
                                            </a>
                                        </li>
                                    </ul>
                                </nav>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="tab-pane fade" id="primary-pills-profile" role="tabpanel">
                    <div class="table-responsive">
                        <table class="table table-condensed table-sm">
                            <thead>
                            <tr>
                                <th>#</th>
                                <th>Market</th>
                                <th>Policy Number</th>
                                <!--                                <th>Type</th>-->
                                <th>Active Period</th>
                                <th>Premium Amount</th>
                                <th>Status</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr v-for="(policy,index) in policies">
                                <td scope="row">{{ index + 1 }}</td>
                                <td>{{ policy.market?.name }}</td>
                                <td>{{ policy.policy_number }}</td>
                                <!--                                <td>{{policy.type}}</td>-->
                                <td>
                                    <p class="text-muted tw-font-medium tw-text-sm tw-m-0 tw-p-0">
                                        {{ policy.start_date }}
                                        &mdash;
                                        {{ policy.end_date }}
                                    </p>
                                    <p class="text-muted tw-text-sm tw-m-0 tw-p-0">
                                        <span class="badge bg-secondary">Auto Renewing</span>

                                    </p>
                                </td>
                                <td>NGN {{ new Intl.NumberFormat().format(policy.amount) }}</td>
                                <td>
                                    <span class="badge bg-success">{{ policy.status }}</span>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div class="tab-pane fade" id="primary-pills-contact" role="tabpanel">
                    <div class="table-responsive">
                        <table class="table">
                            <thead class="thead-inverse">
                            <tr>
                                <th>#</th>
                                <th>Market</th>
                                <th>Claim Number</th>
                                <th>Claim Date</th>
                                <th>Amount Claimed</th>
                                <th>Amount Approved</th>
                                <th>Status</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr v-for="(claim,index) in claims">
                                <td scope="row">{{index + 1}}</td>
                                <td>
                                    <p class="tw-m-0 tw-text-sm tw-p-0">
                                        {{ claim.market.name}}
                                    </p>
                                    <p class="tw-m-0 tw-text-sm tw-p-0 text-muted">
                                        {{ claim.user.name}}
                                    </p>
                                </td>
                                <td>{{claim.claim_number}}</td>
                                <td>{{claim.claim_date}}</td>
                                <td>{{claim.amount_claimed}}</td>
                                <td>{{claim.amount_approved}}</td>
                                <td>
                                    <span class="badge bg-secondary">
                                    {{claim.status}}

                                    </span>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped>

</style>
