<script setup>

import {onMounted, ref} from "vue";
import Swal from 'sweetalert2';
import _ from 'lodash';
const props = defineProps([
  'call_center_verified',
  'user_id',
  'user',
  'auth_id',
  'market_id',
]);
const errors = ref({});
const call_center_verified = ref('');
const confirm_user_bvn = ref('');
const livephotoverified = ref('');
const confirm_user_nin = ref('');
const confirm_shop_address = ref('');
const bank_statement_matches = ref('');
const bank_statement_shows_inflows = ref('');
const saving = ref(false);
const verifications = ref({});

onMounted(() => {
  call_center_verified.value = props.call_center_verified ? 'Verified' : 'Not Verified';

  if (props.user?.finance_verifications?.metadata?.failed == null ||
      props.user?.finance_verifications?.metadata?.failed == undefined) {
  }else{
    verifications.value = props.user?.finance_verifications?.metadata?.failed;
  }
  confirm_user_bvn.value = _.lowerCase(props.user?.finance_verifications?.verifications.confirm_user_bvn ?? '');
  confirm_user_nin.value = _.lowerCase(props.user?.finance_verifications?.verifications.confirm_user_nin ?? '');
  confirm_shop_address.value = _.lowerCase(props.user?.finance_verifications?.verifications.confirm_shop_address ?? '');
  bank_statement_matches.value = _.lowerCase(props.user?.finance_verifications?.verifications.bank_statement_matches,'');
  bank_statement_shows_inflows.value = _.lowerCase(props.user?.finance_verifications?.verifications.bank_statement_shows_inflows,'');
});

function saveFinanceQuestions() {
  saving.value = true;

  // send v-models to /apis/v4/ken
  axios.post('/apis/v4/finance-user-compliance', {
    call_center_verified: call_center_verified.value,
    confirm_user_bvn: confirm_user_bvn.value,
    confirm_user_nin: confirm_user_nin.value,
    livephotoverified: livephotoverified.value,
    confirm_shop_address: confirm_shop_address.value,
    bank_statement_matches: bank_statement_matches.value,
    bank_statement_shows_inflows: bank_statement_shows_inflows.value,
    user_id: props.user_id,
    auth_id: props.auth_id,
    market_id: props.market_id,
  }).then(resp => {
    saving.value = false;
    Swal.fire({
      'text': "Compliance updated",
      'position': 'top-end',
      showConfirmButton: false,
      showCloseButton: false,
      timer: 1000
    });
    location.reload();

  }).catch(err => {
    saving.value = false;
  }).finally(() => {
    saving.value = false;
  });
}
</script>

<template>
  <div>

    <div class="card" v-if="Object.keys(verifications).length > 0">
      <div class="card-body">
        <h4 class="card-title">Failed Verifications</h4>

        <table class="table table-borderless" style="width: 20rem">
          <tbody>
          <tr v-for="(verification,key,index) in verifications">
            <td>{{ index + 1 }}</td>
            <td>{{ key }}</td>
            <td>
              <i class="fa fa-shield-xmark text-danger"></i>
            </td>
          </tr>
          </tbody>
        </table>

      </div>
    </div>
    <div class="card">
      <div class="card-body">
        <h4 class="card-title">Finance Compliance</h4>

        <div class="row g-3">
          <div class="col-md-6">
            <!--Label: Has the user completed Call Center Verification, Attributes:call_center_verified -->
            <div class="form-floating">

              <input type="text" class="form-select" id="call_center_verified" v-model="call_center_verified"
                     aria-describedby="call_center_verified-help"
                     disabled
                     :class="[errors.call_center_verified ? 'is-invalid': '',!errors.call_center_verified && Object.keys(errors).length > 1 ? 'is-valid': '']"
              >
              <label for="call_center_verified">Has the user completed Call Center Verification</label>
              <div class="invalid-feedback" v-if="errors.call_center_verified">
                {{ errors.call_center_verified.toString() }}
              </div>
            </div>
          </div>
            <div class="col-md-6">
                <!--Label: Live Photo Verified, Attributes:livephotoverified -->
                <div class="form-floating">
                    <select type="text" class="form-control form-select" id="livephotoverified" v-model="livephotoverified"
                           aria-describedby="livephotoverified-help"
                           :class="[errors.livephotoverified ? 'is-invalid': '',!errors.livephotoverified && Object.keys(errors).length > 1 ? 'is-valid': '']"
                          >
                        <option value="verified">Verified</option>
                        <option value="not_verified">Not Verified</option>
                    </select>
                    <label for="livephotoverified">Live Photo Verified</label>

                    <div class="invalid-feedback" v-if="errors.livephotoverified">
                        {{ errors.livephotoverified.toString() }}
                    </div>
                </div>
            </div>
          <div class="col-md-6">
            <!--Label: Confirm User BVN, Attributes:confirm_user_bvn -->
            <div class="form-floating">
              <select type="text" class="form-select" id="confirm_user_bvn" v-model="confirm_user_bvn"
                      aria-describedby="confirm_user_bvn-help"
                      :class="[errors.confirm_user_bvn ? 'is-invalid': '',!errors.confirm_user_bvn && Object.keys(errors).length > 1 ? 'is-valid': '']"
              >
                <option value="" disabled>Select Option</option>
                <option value="correct">Correct</option>
                <option value="incorrect">Incorrect</option>
              </select>
              <label for="confirm_user_bvn">Confirm User BVN</label>
              <div class="invalid-feedback" v-if="errors.confirm_user_bvn">
                {{ errors.confirm_user_bvn.toString() }}
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <!--Label: Confirm User Leader NIN, Attributes:confirm_user_nin -->
            <div class="form-floating">

              <select type="text" class="form-select" id="confirm_user_nin" v-model="confirm_user_nin"
                      aria-describedby="confirm_user_nin-help"
                      :class="[errors.confirm_user_nin ? 'is-invalid': '',!errors.confirm_user_nin && Object.keys(errors).length > 1 ? 'is-valid': '']"
              >
                <option value="" disabled>Select Option</option>
                <option value="correct">Correct</option>
                <option value="incorrect">Incorrect</option>
              </select>
              <label for="confirm_user_nin">Confirm User Leader NIN</label>
              <div class="invalid-feedback" v-if="errors.confirm_user_nin">
                {{ errors.confirm_user_nin.toString() }}
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <!--Label: Confirm Shop Address, Attributes:confirm_shop_address -->
            <div class="form-floating">

              <select type="text" class="form-select" id="confirm_shop_address" v-model="confirm_shop_address"
                      aria-describedby="confirm_shop_address-help"
                      :class="[errors.confirm_shop_address ? 'is-invalid': '',!errors.confirm_shop_address && Object.keys(errors).length > 1 ? 'is-valid': '']"
              >
                <option value="" disabled>Select Option</option>
                <option value="correct">Correct</option>
                <option value="incorrect">Incorrect</option>
              </select>
              <label for="confirm_shop_address">Confirm Shop Address</label>
              <div class="invalid-feedback" v-if="errors.confirm_shop_address">
                {{ errors.confirm_shop_address.toString() }}
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <!--Label: Bank statement matches market account and confirmed, Attributes:bank_statement_matches -->
            <div class="form-floating">

              <select type="text" class="form-select" id="bank_statement_matches" v-model="bank_statement_matches"
                      aria-describedby="bank_statement_matches-help"
                      :class="[errors.bank_statement_matches ? 'is-invalid': '',!errors.bank_statement_matches && Object.keys(errors).length > 1 ? 'is-valid': '']"
              >
                <option value="" disabled>Select Option</option>
                <option value="correct">Correct</option>
                <option value="incorrect">Incorrect</option>
              </select>
              <label for="bank_statement_matches">Bank statement matches market account and confirmed</label>
              <div class="invalid-feedback" v-if="errors.bank_statement_matches">
                {{ errors.bank_statement_matches.toString() }}
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <!--Label: Bank Statement shows inflow of transactions and payments, Attributes:bank_statement_shows_inflows -->
            <div class="form-floating">

              <select type="text" class="form-select" id="bank_statement_shows_inflows"
                      v-model="bank_statement_shows_inflows"
                      aria-describedby="bank_statement_shows_inflows-help"
                      :class="[errors.bank_statement_shows_inflows ? 'is-invalid': '',!errors.bank_statement_shows_inflows && Object.keys(errors).length > 1 ? 'is-valid': '']"
              >
                <option value="" disabled>Select Option</option>
                <option value="correct">Correct</option>
                <option value="incorrect">Incorrect</option>
              </select>
              <label for="bank_statement_shows_inflows">Bank Statement shows inflow of transactions and payments</label>
              <div class="invalid-feedback" v-if="errors.bank_statement_shows_inflows">
                {{ errors.bank_statement_shows_inflows.toString() }}
              </div>
            </div>
          </div>
          <div class="col-md-12">
            <div class="d-flex justify-content-end">
              <button class="btn btn-outline-secondary btn-sm"
                      :disabled="saving"
                      @click="saveFinanceQuestions"
              >
                Save
                <i class="fa fa-save" v-if="!saving"></i>
                <i class="fa fa-spin fa-spinner" v-else></i>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>

</template>

<style scoped>

</style>
