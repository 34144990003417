<script setup>
import UserIcons from "../../../Verification/UserIcons.vue";
import {onMounted, ref} from "vue";
import {useAppStore} from "../../../../../store/app";
import {useAuthStore} from "../../../../../store/auth";

const currency = import.meta.env.VITE_LOCALIZATION_COUNTRY_CURRENCY_SIGN;
const subscriptionFee = import.meta.env.VITE_MARKET_MONTHLY_SUBSCRIPTION_FEE;
const props = defineProps(['market', 'leader', 'url', 'subscription','subscription_expiry', 'subscriptions_url', 'token', 'next_actions'])
const link = ref('');
const actionsAllDone = ref(true);
const app = useAppStore();
const authStore = useAuthStore();

onMounted(() => {

    if (props.next_actions.length) {

        props.next_actions.forEach((action) => {
            actionsAllDone.value = actionsAllDone.value && action.status === 'done';
        });

        if (!actionsAllDone.value) {
            bootstrap.Modal.getOrCreateInstance(document.querySelector("#actionModal")).show();
        }
    }
});

function onClickContinue(link) {
    if (link === location.pathname) {
        bootstrap.Modal.getOrCreateInstance(document.querySelector("#actionModal")).hide();
    } else {
        location.href = link;
    }
}
</script>

<template>
    <div class="card custom-card-coamana">
        <div class="card-body">
            <div class="row mt-0 pt-0">
                <table class="table mt-0 pt-0">
                    <tbody>
                    <tr>
                        <th>Market Name</th>
                        <td>
                            {{ market.name ?? '—' }} <br>
                            <span class="text-muted small tw-italic">
                                {{ market.l_g_a?.name }} - {{ market.geo_state?.name }}
                          </span>
                        </td>
                    </tr>
                    <tr>
                        <th>Market Leader</th>
                        <td>
                            <div class="">
                                <div class="d-flex">
                                    {{ leader?.name }}
                                    <span class="tw-ml-1">
                                    <UserIcons :user="leader"></UserIcons>
                                    </span>
                                </div>
                            </div>

                            <br>
                            <span class="text-muted tw-italic">{{ leader?.phone }}</span>
                        </td>
                    </tr>
                    <tr>
                        <th>Trade Limit</th>
                        <td>
                            {{ market.market_limit?.name ?? '-' }}
                        </td>
                    </tr>
                    <tr>
                        <th>Market Credit Score</th>
                        <td>
                            {{ market?.credit_score }}
                        </td>
                    </tr>
                    <tr>
                        <td colspan="2">
                            <table class="table table-borderless mt-0 pt-0">
                                <tbody>
                                <tr>
                                    <td>
                                        <span class="tw-font-medium">
                                            Trust Level
                                        </span>
                                    </td>
                                    <td> {{ market.market_limit?.name ?? '-' }}</td>
                                </tr>
                                <tr>
                                    <td>
                                          <span class="tw-font-medium">
                                        Verification Status
                                          </span>
                                    </td>
                                    <td>
                                        <template v-if="market?.market_limit?.id">
                                            Verified
                                        </template>
                                        <template v-else>
                                            Not Verified
                                        </template>
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                        </td>

                    </tr>
                    <tr>
                        <th>Subscription Plan</th>
                        <td>
                            {{ subscription }}<br>
                            <template v-if="subscription ==='Free'">
                                {{ }}
                                <template v-if="app.country_abbr == 'ke'">
                                    <div class="tw-mt-2">
                                        <div class="tw-flex tw-justify-between tw-items-center tw-bg-gray-100 tw-rounded-lg tw-p-3 tw-mb-2">
                                            <span class="tw-text-gray-600 tw-font-medium">Paybill:</span>
                                            <span class="tw-text-gray-800 tw-font-semibold">4109245</span>
                                        </div>
                                        <div class="tw-flex tw-justify-between tw-items-center tw-bg-gray-100 tw-rounded-lg tw-p-3 tw-mb-2">
                                            <span class="tw-text-gray-600 tw-font-medium">Account Number:</span>
                                            <span class="tw-text-gray-800 tw-font-semibold">SUB#{{ authStore.market.id }}</span>
                                        </div>
                                        <div class="tw-flex tw-justify-between tw-items-center tw-bg-gray-100 tw-rounded-lg tw-p-3">
                                            <span class="tw-text-gray-600 tw-font-medium">Amount:</span>
                                            <span class="tw-text-gray-800 tw-font-semibold">{{currency}}{{ subscriptionFee }}</span>
                                        </div>

                                    </div>
                                    <div class="tw-mt-3 tw-bg-yellow-50 tw-border tw-border-yellow-300 tw-text-yellow-800 tw-rounded-lg tw-p-4 tw-flex tw-items-start tw-gap-3">
                                        <i class="fas fa-exclamation-circle tw-text-yellow-500 tw-text-xl tw-flex-shrink-0"></i>
                                        <p class="tw-text-sm">
                                            Once you make payment, please refresh this page.
                                        </p>
                                    </div>
                                </template>
                                <template v-else>
                                    <a class="btn btn-outline-secondary btn-sm mt-2"
                                       :href="`${subscriptions_url}/upgrade?token=${token}&market_id=${market.id}`"
                                       target="_blank">
                                        Upgrade
                                        <i class="fa-duotone fa-turn-up"></i>
                                    </a>
                                </template>
                            </template>
                        </td>
                    </tr>
                    <tr>
                        <th>Subscription Expiry</th>
                        <td>
                            {{ subscription_expiry }}
                        </td>
                    </tr>
                    <tr>
                        <th>Market URL</th>
                        <td>
                            <a :href="url" target="_blank">
                                Visit Website
                                <i class="fa fa-link"></i>
                            </a>
                            <br>
                        </td>
                    </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
    <!-- Modal -->
    <div
        v-if="next_actions.length > 0"
        class="modal fade" id="actionModal"
        tabindex="-1" role="dialog"
        aria-labelledby="actionModal"
        aria-hidden="true"
    >
        <div class="modal-dialog modal-fade" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h4 class="modal-title" id="thankYouId poppins-bold" style="color: #4b9d30">Market Onboarding</h4>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <div class="col-12" style="padding: 15px" v-for="next_action in next_actions">
                        <h5>
                            <span v-if="next_action.status === 'done'" class="text-success">
                                <i class="fa fa-check-circle"></i>
                                {{ next_action.action }}
                            </span>
                            <span v-else-if="next_action.status === 'active'">
                                <i class="fa fa-check-circle"></i>
                                {{ next_action.action }}
                            </span>
                            <span v-else>
                                <i class="fa-regular fa-circle"></i>
                                 {{ next_action.action }}
                            </span>
                        </h5>
                        <p v-if="next_action.status === 'active'" style="padding-left:10px"
                           :set="link = next_action.next_page">
                            {{ next_action.message }}
                        </p>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-success" @click.prevent="onClickContinue(link)">
                        Continue
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped>
.custom-card-coamana {
    border-radius: 0.25rem;
    border: 0.2px solid #1d242e;
    box-shadow: none;
}

.img-coamana {
    width: 75px;
    height: 75px;
    border-radius: 50% !important;
    background-position: center;
    background-size: cover;
}

/* .rounded-thumbnail img {
} */
</style>
