<script setup>

import {onMounted, ref} from "vue";
import {Bootstrap5Pagination} from 'laravel-vue-pagination';
import moment from "moment/moment";
import Swal from "sweetalert2";

const currency = import.meta.env.VITE_LOCALIZATION_COUNTRY_CURRENCY_SIGN;
const swalWithBootstrapButtons = Swal.mixin({
    customClass: {
        confirmButton: "btn btn-success mx-3",
        denyButton: "btn btn-danger"
    },
    buttonsStyling: false,
});

const posApplications = ref({});
const errors = ref({});
const searchApplication = ref('');
const approving = ref(false);
const delivering = ref(false);
const requesting = ref(false);
const rejecting = ref(false);
const assigning = ref(false);

function updateStatus(posApplication, status) {
    if (status === 'assign') {
        processAssigning(posApplication);
    }else if (status === 'reject') {
        processRejection(posApplication);
    }else{
        processUpdatingOtherStatus(posApplication, status);
    }
}

async function processAssigning(posApplication) {
    assigning.value = true;

    await swalWithBootstrapButtons.fire({
        position: 'top',
        icon: 'info',
        title: '<h4>You are about to assign this POS application</h4>',
        html: `
                <div class="form-group">
                    <input type="text" id="txtTerminalId" class="form-control mb-3" placeholder="POS Terminal ID or Number">
                    <input type="text" id="txtSerialNumber" class="form-control" placeholder="POS Serial Number">
                </div>
            `,
        focusConfirm: false,
        showDenyButton: true,
        confirmButtonText: 'Assign',
        denyButtonText: 'Cancel',
        preConfirm: () => {
            let terminalId = document.getElementById("txtTerminalId").value;
            let serialNumber = document.getElementById("txtSerialNumber").value;

            if (!terminalId) {
                Swal.showValidationMessage("POS Terminal ID is required.");
                return false;
            }

            if (!serialNumber) {
                Swal.showValidationMessage("POS Serial Number is required.");
                return false;
            }

            return [terminalId, serialNumber];
        }
    }).then((result) => {
        if (result.isConfirmed) {
            axios
                .put('/apis/v4/saas/pos-applications/' + posApplication.id, {
                    status: 'assigned',
                    terminal_id: result.value[0],
                    serial_number: result.value[1],
                }).then(() => {
                    location.reload();
                }).catch((error) => {
                    assigning.value = false;
                    let errorMsg = error?.response?.data?.trace?.error[0] ?? 'Error assigning POS application.';

                    Swal.fire({
                        position: 'top',
                        icon: 'error',
                        title: `<h5>${errorMsg}</h5>`,
                    });
                });
        }else{
            assigning.value = false;
        }
    });
}

function processRejection(posApplication) {
    rejecting.value = true;

    swalWithBootstrapButtons.fire({
        position: 'top',
        icon: 'info',
        title: '<h4>You are about to reject this POS application</h4>',
        html: '<p style="font-size: 16px;"><b>The paid amount will be refunded back to the user\'s wallet.</b></p>',
        showDenyButton: true,
        confirmButtonText: 'Reject',
        denyButtonText: 'Cancel',
        focusConfirm: false,
    }).then((result) => {
        if (result.isConfirmed) {
            axios
                .put('/apis/v4/saas/pos-applications/' + posApplication.id, {
                    status: 'closed'
                }).then(() => {
                    location.reload();
                }).catch((error) => {
                    rejecting.value = false;
                    let errorMsg = error?.response?.data?.trace?.error[0] ?? 'Error rejecting POS application.';

                    Swal.fire({
                        position: 'top',
                        icon: 'error',
                        title: `<h5>${errorMsg}</h5>`,
                    });
                });
        }

        rejecting.value = false;
    })
}

function processUpdatingOtherStatus(posApplication, status) {
    let stat = '';
    let action = '';
    let confirmBtnText = '';

    if (status === 'request') {
        requesting.value = true;
        action = 'requesting';
        confirmBtnText = 'Make Request';
        stat = 'requested';
    }else if (status === 'deliver') {
        delivering.value = true;
        status = 'mark as delivered';
        action = 'marking as delivered for';
        confirmBtnText = 'Mark Delivered';
        stat = 'delivered';
    }else{
        approving.value = true;
        action = 'approving';
        confirmBtnText = 'Approve';
        stat = 'approved';
    }

    swalWithBootstrapButtons.fire({
        position: 'top',
        icon: 'info',
        title: `<h4>You are about to ${status} this POS application</h4>`,
        showDenyButton: true,
        confirmButtonText: confirmBtnText,
        denyButtonText: 'Cancel',
        focusConfirm: false,
    }).then((result) => {
        if (result.isConfirmed) {
            axios
                .put('/apis/v4/saas/pos-applications/' + posApplication.id, {
                    status: stat
                }).then(() => {
                location.reload();
            }).catch((error) => {
                approving.value = false;
                requesting.value = false;
                delivering.value = false;
                let errorMsg = error?.response?.data?.trace?.error[0] ?? `Error ${action} POS application.`;

                Swal.fire({
                    position: 'top',
                    icon: 'error',
                    title: `<h5>${errorMsg}</h5>`,
                });
            });
        }

        approving.value = false;
        requesting.value = false;
        delivering.value = false;
    })
}


const getPosApplication = async (page = 1) => {
    await axios.get(`/apis/v4/saas/pos-applications?page=${page}&keyword=${searchApplication.value}`).then(resp => {
        posApplications.value = resp.data.data;
    }).catch(err => {
        errors.value = err.response.data.errors;
    });
}

function setSearchPosApplication() {
    let urlParams = new URLSearchParams(window.location.search);
    searchApplication.value = urlParams.has('search') ? urlParams.get('search') : '';
}

function formatDate(value) {
    return moment(value).format('DD/MM/YYYY HH:mm:ss');
}

onMounted(function () {
    setSearchPosApplication();
    getPosApplication();
});

</script>

<template>
    <div>
        <div class="row">
            <div class="card">
                <div class="card-body">
                    <div class="col-12">
                        <form action="/four/admin/finance/pos-applications" method="GET">
                            <div class="col-md-6">
                                <div class="input-group mb-3">
                                    <input type="text" class="form-control"
                                           placeholder="Search by user name, market name or status"
                                           name="search"
                                           id="searchPosApplication"
                                           :value="searchApplication"
                                           aria-label="Search POS Application" aria-describedby="button-addon2">
                                    <button class="btn btn-outline-secondary" type="submit" id="performSearchApplication">
                                        Search
                                        <i class="fa fa-search"></i>
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                    <div class="table-responsive">
                        <table class="table">
                            <thead v-if="posApplications && posApplications.data && posApplications.data.length > 0">
                            <tr>
                                <th>#</th>
                                <th>User</th>
                                <th>Market</th>
                                <th>Status</th>
                                <th>Paid Amount</th>
                                <th>Terminal</th>
                                <th>Serial</th>
                                <th>Date</th>
                                <th>Action</th>
                                <th></th>
                            </tr>
                            </thead>
                            <tbody v-if="posApplications && posApplications.data && posApplications.data.length > 0">
                            <tr v-for="(posApplication,index) in posApplications.data" :key="posApplication.id">
                                <td scope="row">{{ index + 1 }}</td>
                                <td>{{ posApplication.user.name }}</td>
                                <td>{{ posApplication.market.name }}</td>
                                <td>
                                    <div class="d-flex align-items-center">
                                        <span v-if="posApplication.status === 'pending'" class="badge bg-warning text-dark">Pending</span>
                                        <span v-else-if="posApplication.status === 'approved'" class="badge bg-light text-dark">Approved</span>
                                        <span v-else-if="posApplication.status === 'assigned'" class="badge bg-primary">Assigned</span>
                                        <span v-else-if="posApplication.status === 'active'" class="badge bg-success">Active</span>
                                        <span v-else-if="posApplication.status === 'requested'" class="badge bg-info text-dark">Requested</span>
                                        <span v-else-if="posApplication.status === 'delivered'" class="badge bg-dark">Delivered</span>
                                        <span v-else class="badge bg-danger">Closed</span>
                                    </div>
                                </td>
                                <td>{{ currency }}{{ posApplication.paid_amount.toLocaleString() }}</td>
                                <td>{{ posApplication.terminal_id ?? '---' }}</td>
                                <td>{{ posApplication.serial_number ?? '---' }}</td>
                                <td>{{ formatDate(posApplication.created_at) }}</td>
                                <td>
                                    <button
                                        v-if="posApplication.status === 'pending'"
                                        class="btn btn-sm btn-success"
                                        @click="updateStatus(posApplication,'approve')"
                                        :disabled="approving"
                                    >
                                        Approve
                                        <i class="fa fa-spin fa-spinner" v-if="approving"></i>
                                    </button>
                                    <button
                                        v-else-if="posApplication.status === 'approved'"
                                        class="btn btn-sm btn-warning"
                                        @click="updateStatus(posApplication,'request')"
                                        :disabled="requesting"
                                    >
                                        Make Request
                                        <i class="fa fa-spin fa-spinner" v-if="requesting"></i>
                                    </button>
                                    <button
                                        v-else-if="posApplication.status === 'requested'"
                                        class="btn btn-sm btn-warning"
                                        @click="updateStatus(posApplication,'assign')"
                                        :disabled="assigning"
                                    >
                                        Assign
                                        <i class="fa fa-spin fa-spinner" v-if="assigning"></i>
                                    </button>
                                    <button
                                        v-else-if="posApplication.status === 'assigned'"
                                        class="btn btn-sm btn-warning"
                                        @click="updateStatus(posApplication,'deliver')"
                                        :disabled="delivering"
                                    >
                                        Mark Delivered
                                        <i class="fa fa-spin fa-spinner" v-if="delivering"></i>
                                    </button>
                                    &nbsp;
                                    <button
                                        v-if="posApplication.status === 'pending'"
                                        class="btn btn-sm btn-danger"
                                        @click="updateStatus(posApplication,'reject')"
                                        :disabled="rejecting"
                                    >
                                        Reject
                                        <i class="fa fa-spin fa-spinner" v-if="rejecting"></i>
                                    </button>
                                </td>
                            </tr>
                            </tbody>
                            <tbody v-else>
                            <tr>
                                <td align="center" colspan="7">No record found.</td>
                            </tr>
                            </tbody>
                        </table>
                        <div class="col-12">
                            <bootstrap5-pagination :data="posApplications" @pagination-change-page="getPosApplication" style="overflow: scroll"/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<style>
.page-link {
    color: #00ab4d;
}
.page-link:hover {
    color: #00ab4d;
}
.active>.page-link, .page-link.active {
    z-index: 3;
    color: var(--bs-pagination-active-color);
    background-color: #00ab4d;
    border-color: #00ab4d;
}
</style>
