<script setup>

import {onMounted, ref} from "vue";
import {Bootstrap5Pagination} from 'laravel-vue-pagination';
import moment from "moment/moment";
import SendOTPForPayment from "../../../wallet/payments/sendOTPForPayment.vue";
import ResendOTP from "../../../ResendOTP.vue";
import Swal from "sweetalert2";

const currency = import.meta.env.VITE_LOCALIZATION_COUNTRY_CURRENCY_SIGN;
const props = defineProps([
    'total',
    'totalPending',
    'totalEarning',
    'walletBalance',
    'user',
]);

const selectedTransaction = ref({});
const transactions = ref({});
const errors = ref({});
const childRef = ref();
const saving = ref(false);
const sentOtpToUser = ref(false);
const otp = ref('');

const buyerName = ref('');
const sellerName = ref('');
const buyerPhone = ref('');
const sellerPhone = ref('');
const parentType = ref('');
const transactionType = ref('');
const status = ref('');
const transactionDate = ref('');
const summaryLine = ref('');
const paidDate = ref('');
const transactionRef = ref('');
const transactionAmount = ref(0);

const setChildRef = (el) => {
    childRef.value = el;
};

function populateTransaction(transaction) {
    if (transaction) {
        let order = transaction.payable
        selectedTransaction.value = transaction;
        buyerName.value = order.buyer.name;
        sellerName.value = order.seller.name;
        buyerPhone.value = order.buyer.phone;
        sellerPhone.value = order.seller.phone;
        summaryLine.value = order.summary_line;
        parentType.value = transaction.payable_type;
        transactionType.value = transaction.type;
        transactionAmount.value = transaction.amount;
        transactionRef.value = transaction.transaction_ref;
        status.value = transaction.status;
        paidDate.value = formatDate(transaction.settled_on);
        transactionDate.value = formatDate(transaction.created_at);
    }
}

const getTransactions = async (page = 1) => {
    await axios.get(`/apis/v4/saas/transactions/member?page=${page}`).then( resp => {
        transactions.value = resp.data.data;
    }).catch(err => {
        errors.value = err.response.data.errors;
    });
}

function formatDate(value) {
    return moment(value).format('DD/MM/YYYY HH:mm:ss');
}

function currencyFormat(amount) {
    let amounts = new Intl.NumberFormat("en-KE", {
        maximumSignificantDigits: 3,
    }).format(amount);
    return `${currency}${amounts}`;
}

function sendOtp() {
    // bootstrap.Modal.getOrCreateInstance(document.querySelector("#paymentModal")).hide();
    sentOtpToUser.value = true;
    let payAmount = currencyFormat(selectedTransaction.value.amount);

    axios.post('/apis/v4/otp/send', {
        phone: props.user.phone,
        user_id: props.user.id,
        key: 'pay_for_order_from_transactions',
        message: `You are about to pay ${payAmount} from your wallet. ${payAmount} will be removed from your wallet. To accept, enter this code {otp}`
    }).then(function () {
        sentOtpToUser.value = true;
        childRef.value.startTimer();
    }).catch(function () {
        sentOtpToUser.value = false;
    });
}

function makePayment() {
    saving.value = true;

    axios.post('/apis/v4/otp/validate', {
        otp: otp.value,
        user_id: props.user.id,
        key: 'pay_for_order_from_transactions'
    }).then(() => {
        completePayment()
    }).catch((error) => {
        saving.value = false;
        errors.value = error?.response?.data?.errors ?? '';
    });
}

function completePayment() {
    saving.value = true;

    axios.post('/apis/v4/completePayment', {
        'user_id': props.user.id,
        'order_id': selectedTransaction.value.payable_id,
        'type': 'Order',
    }).then(() => {
        location.reload();
    }).catch(() => {
        saving.value = false;

        Swal.fire({
            position: 'top',
            icon: 'error',
            title: 'Error completing payment. Please try again and contact our support team if problem persists.',
        });
    });
}

onMounted(function () {
    getTransactions();
});

</script>

<template>
    <div>
        <div class="row row-cols-1 row-cols-md-2 row-cols-xl-3">
            <div class="col">
                <div class="card radius-10">
                    <div class="card-body">
                        <div class="d-flex align-items-center">
                            <div>
                                <p class="mb-0 text-secondary">{{ 'Total Transactions' }}</p>
                                <h6 class="my-1">
                                    {{ currency }}{{ total.toLocaleString() }}
                                </h6>
                                <p>{{ 'All transactions' }}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col">
                <div class="card radius-10">
                    <div class="card-body">
                        <div class="d-flex align-items-center">
                            <div>
                                <p class="mb-0 text-secondary">{{ 'Total Pending Payments' }}</p>
                                <h6 class="my-1">
                                    {{ currency }}{{ totalPending.toLocaleString() }}
                                </h6>
                                <p>{{ 'All unpaid credit transactions' }}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col">
                <div class="card radius-10">
                    <div class="card-body">
                        <div class="d-flex align-items-center">
                            <div>
                                <p class="mb-0 text-secondary">{{ 'Total Earnings' }}</p>
                                <h6 class="my-1">
                                    {{ currency }}{{ totalEarning.toLocaleString() }}
                                </h6>
                                <p>{{ 'Amount earned as an agent' }}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="card">
                <div class="card-body">
                    <div class="table-responsive">
                        <table class="table">
                            <thead v-if="transactions && transactions.data && transactions.data.length > 0">
                            <tr>
                                <th>#</th>
                                <th>Buyer Name</th>
                                <th>Seller Name</th>
                                <th>Transaction Type</th>
                                <th>Amount</th>
                                <th>Status</th>
                                <th>Action</th>
                            </tr>
                            </thead>
                            <tbody v-if="transactions && transactions.data && transactions.data.length > 0">
                            <tr v-for="(transaction,index) in transactions.data" :key="transaction.id">
                                <td scope="row">{{ index + 1 }}</td>
                                <td>{{ transaction.payable.buyer.name }}</td>
                                <td>{{ transaction.payable.seller.name }}</td>
                                <td>{{ transaction.type }}</td>
                                <td>{{ currency }}{{ transaction.amount.toLocaleString() }}</td>
                                <td v-if="transaction.status === 'Pending' || transaction.status === 'Accepted'" class="text-warning">
                                    Pending
                                </td>
                                <td v-else-if="transaction.status === 'Paid'" class="text-success">
                                    {{ transaction.status }}
                                </td>
                                <td v-else-if="transaction.status === 'Overdue'" class="text-danger">
                                    {{ transaction.status }}
                                </td>
                                <td v-else-if="transaction.status === 'Due' || transaction.status === 'DUE'" class="text-primary">
                                    Due
                                </td>
                                <td v-else-if="transaction.status === 'Automated Rejection' || transaction.status === 'Rejected'" class="text-info">
                                    {{ transaction.status }}
                                </td>
                                <td v-else>
                                    {{ transaction.status }}
                                </td>
                                <td>
                                    <button class="btn btn-sm btn-default"
                                            @click="populateTransaction(transaction)"
                                            data-bs-toggle="offcanvas" href="#viewTransaction" role="button"
                                            aria-controls="viewTransaction"
                                    >
                                        See More <i class="fa fa-angle-double-right fa-thin"></i>
                                    </button>
                                </td>
                            </tr>
                            </tbody>
                            <tbody v-else>
                            <tr>
                                <td align="center" colspan="7">No record found.</td>
                            </tr>
                            </tbody>
                        </table>
                        <bootstrap5-pagination :data="transactions" @pagination-change-page="getTransactions" align="center"/>
                    </div>
                    <div class="offcanvas offcanvas-end" tabindex="-1" id="viewTransaction"
                         aria-labelledby="viewTransactionLabel">
                        <div class="offcanvas-header">
                            <h5 class="offcanvas-title" id="viewTransactionLabel">Transaction Details</h5>
                            <button type="button" class="btn-close text-reset" data-bs-dismiss="offcanvas"
                                    aria-label="Close"></button>
                        </div>
                        <div class="offcanvas-body">
                            <div class="table-responsive">
                                <table class="table table-borderless">
                                    <tbody>
                                    <tr>
                                        <td>Buyer Name:</td>
                                        <td><b>{{ buyerName }}</b></td>
                                    </tr>
                                    <tr>
                                        <td>Seller Name:</td>
                                        <td><b>{{ sellerName }}</b></td>
                                    </tr>
                                    <tr>
                                        <td>Buyer Phone Number:</td>
                                        <td><b>{{ buyerPhone }}</b></td>
                                    </tr>
                                    <tr>
                                        <td>Seller Phone Number:</td>
                                        <td><b>{{ sellerPhone }}</b></td>
                                    </tr>
                                    <tr>
                                        <td>Products:</td>
                                        <td><b>{{ summaryLine }}</b></td>
                                    </tr>
                                    <tr>
                                        <td>Transaction Type:</td>
                                        <td><b>{{ transactionType }}</b></td>
                                    </tr>
                                    <tr>
                                        <td>Amount Paid:</td>
                                        <td><b>{{ currency }}{{ transactionAmount.toLocaleString() }}</b></td>
                                    </tr>
                                    <tr>
                                        <td>Agent Earning:</td>
                                        <td><b>{{ currency }}{{ 0 }}</b></td>
                                    </tr>
                                    <tr>
                                        <td>Transaction Date:</td>
                                        <td><b>{{ transactionDate }}</b></td>
                                    </tr>
                                    <tr v-if="status === 'Paid'">
                                        <td>Payment Date:</td>
                                        <td><b>{{ paidDate }}</b></td>
                                    </tr>
                                    <tr>
                                        <td>Transaction ID:</td>
                                        <td><b>{{ transactionRef }}</b></td>
                                    </tr>
                                    <tr>
                                        <td>Transaction Status:</td>
                                        <td v-if="status === 'Pending' || status === 'Accepted'" class="text-warning">
                                            Pending
                                        </td>
                                        <td v-else-if="status === 'Paid'" class="text-success">
                                            {{ status }}
                                        </td>
                                        <td v-else-if="status === 'Overdue'" class="text-danger">
                                            {{ status }}
                                        </td>
                                        <td v-else-if="status === 'Due' || status === 'DUE'" class="text-primary">
                                            Due
                                        </td>
                                        <td v-else-if="status === 'Automated Rejection' || status === 'Rejected'" class="text-info">
                                            {{ status }}
                                        </td>
                                        <td v-else>
                                            {{ status }}
                                        </td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div class="align-center" v-if="status !== 'Paid'" style="text-align: center">
                                <button
                                    class="btn btn-outline-success"
                                    data-bs-toggle="modal"
                                    data-bs-target="#paymentModal"
                                    style="width: 100%"
                                >
                                    Pay
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div
        class="modal fade"
        id="paymentModal"
        tabindex="-1"
        role="dialog"
        aria-labelledby="paymentModal"
        aria-hidden="true"
    >
        <div class="modal-dialog modal-dialog-centered" role="document">
            <div class="modal-content">
                <div class="modal-body">
                    <button style="float: right" type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"/>
                    <div class="col-12" style="padding: 10px">
                        <h4 class="text-success">Make Payment</h4>
                        <div class="col-12 mt-3">
                            <p>
                                The sum of
                                {{ currencyFormat(selectedTransaction?.amount) }}
                                will be deducted from your wallet to pay for the transaction
                            </p>
                            <div class="alert alert-warning" role="alert" v-if="parseFloat(selectedTransaction?.amount) > walletBalance">
                                <strong>
                                    You do not have enough funds in your wallet. Topup your wallet and try again.
                                </strong>
                            </div>
                        </div>

                        <div class="col-12" v-if="parseFloat(selectedTransaction?.amount) <= walletBalance">
                            <button class="btn btn-success" data-bs-toggle="modal"
                                    @click="sendOtp"
                                    data-bs-target="#otpModal">
                                Yes, Make Payment
                            </button>&nbsp;
                            <button type="button" class="btn btn-outline-warning" data-bs-dismiss="modal">No, Cancel Payment</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div
        class="modal fade"
        id="otpModal"
        tabindex="-1"
        role="dialog"
        aria-labelledby="otpModal"
        aria-hidden="true"
    >
        <div class="modal-dialog modal-dialog-centered" role="document">
            <div class="modal-content">
                <div class="modal-body">
                    <button style="float: right" type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"/>
                    <div class="col-12" style="padding: 10px">
                        <h4 class="text-success">Confirm Payment with OTP</h4>

                        <div class="mt-3">
                            <send-o-t-p-for-payment
                                :errors="errors"
                                @updatedotp="args => otp = args"
                            ></send-o-t-p-for-payment>
                        </div>

                        <div class="mt-2 mb-3">
                            <ResendOTP v-if="sentOtpToUser" :send-otp="sendOtp" :ref="setChildRef"/>
                        </div>

                        <button class="btn btn-success" style="width: 100%" @click.prevent="makePayment">
                            Pay
                            <i class="fa fa-spin fa-spinner" v-if="saving"></i>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<style>
.page-link {
    color: #00ab4d;
}
.page-link:hover {
    color: #00ab4d;
}
.active>.page-link, .page-link.active {
    z-index: 3;
    color: var(--bs-pagination-active-color);
    background-color: #00ab4d;
    border-color: #00ab4d;
}
</style>
