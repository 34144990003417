<script setup>
import {ref} from "vue";

const props = defineProps(['order']);

const pickuplocation = ref('');
const deliverylocation = ref('');
const driver_name = ref('');
const driver_phonenumber = ref('');
const driver_licence_number = ref('');
const vehicle_make = ref('');
const vehicle_model = ref('');
const vehicle_plate_number = ref('');
const amount_paid_for_logistics = ref('');

const errors = ref([]);
const page = ref(1);
const saving = ref(false);

const submitForm = () => {
    saving.value = true;
    axios.post('/apis/v4/insurance/cover', {
        order: props.order,
        pickuplocation: pickuplocation.value,
        deliverylocation: deliverylocation.value,
        driver_name: driver_name.value,
        driver_phonenumber: driver_phonenumber.value,
        driver_licence_number: driver_licence_number.value,
        vehicle_make: vehicle_make.value,
        vehicle_model: vehicle_model.value,
        vehicle_plate_number: vehicle_plate_number.value,
        amount_paid_for_logistics: amount_paid_for_logistics.value,
    }).then(resp=>{
        saving.value = false;
        let myModalEl = document.querySelector("#getcover");
        let modal = bootstrap.Modal.getOrCreateInstance(myModalEl);
        modal.toggle();
    }).catch(()=>{
        saving.value = false;
    });
}
</script>

<template>
    <div>
        <form action="">
            <div class="row gap-3" v-if="page === 1">
                <div class="col-md-12">
                    <!--Label: Pickup Location, Attributes:pickuplocation -->
                    <div class="form-floating">
                        <input type="text" class="form-control" id="pickuplocation" v-model="pickuplocation"
                               aria-describedby="pickuplocation-help"
                               :class="[errors.pickuplocation ? 'is-invalid': '',!errors.pickuplocation && Object.keys(errors).length > 1 ? 'is-valid': '']"
                               required>
                        <label for="pickuplocation">Pickup Location</label>
                        <div class="invalid-feedback" v-if="errors.pickuplocation">
                            {{ errors.pickuplocation.toString() }}
                        </div>
                    </div>
                </div>
                <div class="col-md-12">
                    <!--Label: Delivery Location, Attributes:deliverylocation -->
                    <div class="form-floating">
                        <input type="text" class="form-control" id="deliverylocation" v-model="deliverylocation"
                               aria-describedby="deliverylocation-help"
                               :class="[errors.deliverylocation ? 'is-invalid': '',!errors.deliverylocation && Object.keys(errors).length > 1 ? 'is-valid': '']"
                        >
                        <label for="deliverylocation">Delivery Location</label>
                        <div class="invalid-feedback" v-if="errors.deliverylocation">
                            {{ errors.deliverylocation.toString() }}
                        </div>
                    </div>
                </div>
                <div class="col-md-12">
                    <div class="d-flex justify-content-end">
                        <button class="btn btn-success" @click.prevent="page++">
                            Confirm
                        </button>
                    </div>
                </div>
            </div>
            <div class="row gap-2" v-else>
                <div class="col-md-12 tw-m-0 tw-p-0">
                    <div class="alert alert-warning tw-mb-0 tw-pb-0" role="alert">
                        <strong>We noticed that your route requires delivery.</strong>
                        <p>Please input the driver and vehicle details.</p>
                    </div>
                </div>
                <div class="col-md-12">
                    <!--Label: Driver Name, Attributes:driver_name -->
                    <div class="form-floating">
                        <input type="text" class="form-control" id="driver_name" v-model="driver_name"
                               aria-describedby="driver_name-help"
                               :class="[errors.driver_name ? 'is-invalid': '',!errors.driver_name && Object.keys(errors).length > 1 ? 'is-valid': '']"
                        >
                        <label for="driver_name">Driver Name</label>
                        <div class="invalid-feedback" v-if="errors.driver_name">
                            {{ errors.driver_name.toString() }}
                        </div>
                    </div>
                </div>
                <div class="col-md-12">
                    <!--Label: Driver Phone Number, Attributes:driver_phonenumber -->
                    <div class="form-floating">
                        <input type="text" class="form-control" id="driver_phonenumber" v-model="driver_phonenumber"
                               aria-describedby="driver_phonenumber-help"
                               :class="[errors.driver_phonenumber ? 'is-invalid': '',!errors.driver_phonenumber && Object.keys(errors).length > 1 ? 'is-valid': '']"
                        >
                        <label for="driver_phonenumber">Driver Phone Number</label>

                        <div class="invalid-feedback" v-if="errors.driver_phonenumber">
                            {{ errors.driver_phonenumber.toString() }}
                        </div>
                    </div>
                </div>
                <div class="col-md-12">
                    <!--Label: Driver Licence Number, Attributes:driver_licence_number -->
                    <div class="form-floating">
                        <input type="text" class="form-control" id="driver_licence_number"
                               v-model="driver_licence_number"
                               aria-describedby="driver_licence_number-help"
                               :class="[errors.driver_licence_number ? 'is-invalid': '',!errors.driver_licence_number && Object.keys(errors).length > 1 ? 'is-valid': '']"
                        >
                        <label for="driver_licence_number">Driver Licence Number</label>
                        <div class="invalid-feedback" v-if="errors.driver_licence_number">
                            {{ errors.driver_licence_number.toString() }}
                        </div>
                    </div>
                </div>
                <div class="col-md-12">
                    <!--Label: Vehicle Make, Attributes:vehicle_make -->
                    <div class="form-floating">
                        <input type="text" class="form-control" id="vehicle_make" v-model="vehicle_make"
                               aria-describedby="vehicle_make-help"
                               :class="[errors.vehicle_make ? 'is-invalid': '',!errors.vehicle_make && Object.keys(errors).length > 1 ? 'is-valid': '']"
                        >
                        <label for="vehicle_make">Vehicle Make</label>

                        <div class="invalid-feedback" v-if="errors.vehicle_make">
                            {{ errors.vehicle_make.toString() }}
                        </div>
                    </div>
                </div>
                <div class="col-md-12">
                    <!--Label: Vehicle Model, Attributes:vehicle_model -->
                    <div class="form-floating">
                        <input type="text" class="form-control" id="vehicle_model" v-model="vehicle_model"
                               aria-describedby="vehicle_model-help"
                               :class="[errors.vehicle_model ? 'is-invalid': '',!errors.vehicle_model && Object.keys(errors).length > 1 ? 'is-valid': '']"
                        >
                        <label for="vehicle_model">Vehicle Model</label>

                        <div class="invalid-feedback" v-if="errors.vehicle_model">
                            {{ errors.vehicle_model.toString() }}
                        </div>
                    </div>
                </div>
                <div class="col-md-12">
                    <!--Label: Vehicle Plate Number, Attributes:vehicle_plate_number -->
                    <div class="form-floating">
                        <input type="text" class="form-control" id="vehicle_plate_number" v-model="vehicle_plate_number"
                               aria-describedby="vehicle_plate_number-help"
                               :class="[errors.vehicle_plate_number ? 'is-invalid': '',!errors.vehicle_plate_number && Object.keys(errors).length > 1 ? 'is-valid': '']"
                        >
                        <label for="vehicle_plate_number">Vehicle Plate Number</label>
                        <div class="invalid-feedback" v-if="errors.vehicle_plate_number">
                            {{ errors.vehicle_plate_number.toString() }}
                        </div>
                    </div>
                </div>
                <div class="col-md-12">
                    <!--Label: How Much are you paying them, Attributes:amount_paid_for_logistics -->
                    <div class="form-floating">
                        <input type="text" class="form-control" id="amount_paid_for_logistics"
                               v-model="amount_paid_for_logistics"
                               aria-describedby="amount_paid_for_logistics-help"
                               :class="[errors.amount_paid_for_logistics ? 'is-invalid': '',!errors.amount_paid_for_logistics && Object.keys(errors).length > 1 ? 'is-valid': '']"
                        >
                        <label for="amount_paid_for_logistics">How Much are you paying them</label>

                        <div class="invalid-feedback" v-if="errors.amount_paid_for_logistics">
                            {{ errors.amount_paid_for_logistics.toString() }}
                        </div>
                    </div>
                </div>
                <div class="col-md-12">
                    <div class="d-flex justify-content-end">
                        <button class="btn btn-success" @click.prevent="submitForm">
                            Submit
                        </button>
                    </div>
                </div>
            </div>
        </form>
    </div>
</template>

<style scoped>

</style>
