<script setup>
import axios from "axios";
import {onMounted, ref, computed} from "vue";
import UserIcons from "../../Verification/UserIcons.vue";
import UserVerificationModal from "../../Verification/UserVerificationModal.vue";
import {usePermissions} from "../../../../store/permissions";
import InlineTopUp from "../../../../../js/components/wallet/payments/inlinetopup/InlineTopUp.vue";
import {useAppStore} from "../../../../store/app";
const app = useAppStore();

const permissions = usePermissions();
const props = defineProps(["member", "group_id", "market" , 'auth_user', 'virtual_bank_account']);
const locale = import.meta.env.VITE_LOCALIZATION_COUNTRY_ABBR;
const openUpgrade = ref(false);
const openVerifyUserModal = ref(false);
const toppedUpAmount = ref(0);

const loading = ref(false);

const approveAsMemberValue = !!ref(props.member?.pivot?.approved).value;
const approveForCreditValue = !!ref(
    props.member?.pivot?.approved_for_credit_purchase
).value;
const blacklistedValue = !!ref(props.member?.pivot?.black_listed).value;


// Define a computed property to determine the approval status
const approvalStatus = computed(() => {
  if (approveForCreditValue && approveAsMemberValue) {
    return 'Approved for Market &  Credit';
  } else if (approveForCreditValue.value) {
    return 'Approved for Credit';
  } else if (approveAsMemberValue.value) {
    return 'Approved by Market';
  } else {
    return 'Pending Approval';
  }
});

const blacklist = () => {
    loading.value = true;
    axios
        .post(`/c/group-member/${props.member?.id}/blackList`, {
            group_id: props?.group_id,
        })
        .then((resp) => {
            loading.value = false;
            window.location.reload();
        })
        .catch((err) => {
            loading.value = false;
            this.errors = err.response.data.errors;
        });
};
const removeUserFromMarket = () => {
    loading.value = true;
    axios
        .post(`/c/group-member/${props.member?.id}/remove`, {
            group_id: props?.group_id,
        })
        .then((resp) => {
            loading.value = false;
            window.location.reload();
        })
        .catch((err) => {
            loading.value = false;
            this.errors = err.response.data.errors;
        });
};

const approve = () => {
    loading.value = true;
    axios
        .post(`/c/group-member/${props.member?.id}/approve`, {
            group_id: props?.group_id,
        })
        .then((resp) => {
            loading.value = false;
            window.location.reload();
        })
        .catch((err) => {
            loading.value = false;
            this.errors = err.response.data.errors;
        });
};
const approveForCredit = () => {
    loading.value = true;
    axios
        .post(`/c/group-member/${props.member?.id}/approveforcredit`, {
            group_id: props?.group_id,
        })
        .then((resp) => {
            loading.value = false;
            window.location.reload();
        })
        .catch((err) => {
            loading.value = false;
            this.errors = err.response.data.errors;
        });
};
const toggleOpenUpgrade = () => {
    openUpgrade.value = !openUpgrade.value;
};
const toggleOpenVerifyUserModal = () => {
    openUpgrade.value = false;
    openVerifyUserModal.value = !openVerifyUserModal.value;
};

function makePayment() {
    let myModalEl = document.querySelector("#topupInline");
    let modal = bootstrap.Modal.getOrCreateInstance(myModalEl);
    modal.show();
}

const walletToppedUp = () => {
    console.log('I am here')
    user.wallet.current_balance = toppedUpAmount.value;
    let myModalEl = document.querySelector("#topupInline");
    let modal = bootstrap.Modal.getOrCreateInstance(myModalEl);
    modal.hide();
    window.location.reload();
};

</script>

<template>
    <div
        class="d-flex flex-row transaction-history-success-table py-2 px-4 my-2"
        style="border-radius: 0.25rem; border: 0.2px solid rgb(29, 36, 46)"
    >
        <div class="d-flex flex-column col-8">
            <div class="d-flex gap-2">
                <div>
                    <strong>{{ member.name }}
                    </strong>
                </div>
                <div>
                    <UserIcons :user="member"></UserIcons>

                </div>
            </div>
            <div class="success-text">
                {{ member.role }}
            </div>
        </div>
        <div class="d-flex flex-column col-2">
            <div>{{ member.phone }}</div>
            <div>{{ approvalStatus }}</div>
            <div
                class="success-text"
                style="cursor: pointer"
                @click="toggleOpenUpgrade"
            >
                <strong>View/Edit</strong>
            </div>
        </div
        >
    </div>

    <!-- User View and Edit Modal  -->
    <div
        v-if="openUpgrade"
        style="
            position: fixed;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            display: grid;
            place-items: center;
            padding: 1rem;
            background-color: rgba(0, 0, 0, 0.3);
            z-index: 50;
        "
    >
        <div
            class="card mb-3"
            style="
                position: relative;
                border-radius: 0.25rem;
                border: 0.2px solid #fff;
                box-shadow: none;
                max-width: 500px;
                width: 95%;
                padding: 1rem;
            "
        >
            <svg
                style="
                    position: absolute;
                    top: 0.5rem;
                    right: 0.5rem;
                    cursor: pointer;
                "
                @click="toggleOpenUpgrade"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <g clip-path="url(#clip0_8_6557)">
                    <path
                        d="M13.89 8.7L12 10.59L10.11 8.7C9.72 8.31 9.09 8.31 8.7 8.7C8.31 9.09 8.31 9.72 8.7 10.11L10.59 12L8.7 13.89C8.31 14.28 8.31 14.91 8.7 15.3C9.09 15.69 9.72 15.69 10.11 15.3L12 13.41L13.89 15.3C14.28 15.69 14.91 15.69 15.3 15.3C15.69 14.91 15.69 14.28 15.3 13.89L13.41 12L15.3 10.11C15.69 9.72 15.69 9.09 15.3 8.7C14.91 8.32 14.27 8.32 13.89 8.7ZM12 2C6.47 2 2 6.47 2 12C2 17.53 6.47 22 12 22C17.53 22 22 17.53 22 12C22 6.47 17.53 2 12 2ZM12 20C7.59 20 4 16.41 4 12C4 7.59 7.59 4 12 4C16.41 4 20 7.59 20 12C20 16.41 16.41 20 12 20Z"
                        fill="#1D242E"
                    />
                </g>
                <defs>
                    <clipPath id="clip0_8_6557">
                        <rect width="24" height="24" fill="white"/>
                    </clipPath>
                </defs>
            </svg>

            <div class="card-body">
                <div class="row">
                    <div class="d-flex justify-content-between">
                        <div>
                            <h4 class="text-success">
                                {{ member.name }}
                                <span class="fa fa-spin fa-spinner" v-if="loading"></span>
                            </h4>
                        </div>
                        <div>
                            <UserIcons :user="member"></UserIcons>
                        </div>
                    </div>
                </div>


                <div
                    style="
                        display: flex;
                        flex-direction: column;
                        justify-content: flex-start;
                        align-items: flex-start;
                        gap: 0.2rem;
                        width: 100%;
                    "
                >
                    <p style="margin: 0; color: #1d242e; font-size: 0.875rem">
                        <span style="font-weight: 700">Phone:</span>
                        <span v-if="auth_user.verification_status='verified'">{{ member?.phone ?? "" }}</span>
                        <span v-else>{{ member?.formattedphone ?? "" }}</span>
                    </p>
                    <p style="margin: 0; color: #1d242e; font-size: 0.875rem">
                        <span style="font-weight: 700">Gender:</span>
                        {{ member?.gender ?? "" }}
                    </p>
                    <p style="margin: 0; color: #1d242e; font-size: 0.875rem">
                        <span style="font-weight: 700">Age:</span>
                        {{ member?.age ?? "" }}
                    </p>
                    <p style="margin: 0; color: #1d242e; font-size: 0.875rem">
                        <span style="font-weight: 700">Designation:</span>
                        {{ member?.metadata?.designation ?? "" }}
                    </p>
                    <p style="margin: 0; color: #1d242e; font-size: 0.875rem">
                        <template v-if="locale === 'ke'">
                            <span style="font-weight: 700">KRA PIN:</span>
                        </template>
                        <template v-else>
                            <span style="font-weight: 700">BVN:</span>
                        </template>
                        *********
                    </p>
                    <p style="margin: 0; color: #1d242e; font-size: 0.875rem">
                            <span style="font-weight: 700">Credit Score:</span>
                        {{ member?.credit_score ?? "" }}
                    </p>
                    <p style="margin: 0; color: #1d242e; font-size: 0.875rem">
                        <span style="font-weight: 700">NIN:</span>
                        *********
                    </p>
                    <!-- <p style="margin: 0; color: #1d242e; font-size: 0.875rem"> -->
                    <!--     <span style="font-weight: 700">Shop Address:</span> -->
                    <!--     {{ member?.formattedphone ?? "" }} -->
                    <!-- </p> -->

                    <div
                        v-if="permissions.$can('Approve Members')"

                        style="
                            display: flex;
                            justify-content: space-between;
                            align-items: center;
                            gap: 0.5rem;
                            border-radius: 0.25rem;
                            border: 0.4px solid #1d242e;
                            padding: 0.5rem 1.2rem;
                            width: 100%;
                            margin-top: 0.5rem;
                            margin-bottom: 0.5rem;
                        "
                    >
                        <div
                            style="
                                display: flex;
                                flex-direction: column;
                                justify-content: flex-start;
                                align-items: flex-start;
                                gap: 0rem;
                            "
                        >
                            <p
                                style="
                                    margin: 0;
                                    color: #1d242e;
                                    font-size: 0.875rem;
                                    font-weight: 700;
                                "
                            >
                                Approval as Member
                            </p>
                            <p
                                style="
                                    margin: 0;
                                    color: #1d242e;
                                    font-size: 0.875rem;
                                "
                            >
                                This member is <span v-show="!approveAsMemberValue">not</span> approved
                            </p>
                        </div>
                        <div class="form-check form-switch">
                            <input
                                class="form-check-input"
                                type="checkbox"
                                role="switch"
                                id="approveAsMember"
                                v-model="approveAsMemberValue"
                                @click="approve"
                                :disabled="loading"
                            />
                        </div>
                    </div>
                    <div
                        style="
                            margin-bottom: 0.5rem;
                            display: flex;
                            justify-content: space-between;
                            align-items: center;
                            gap: 0.5rem;
                            border-radius: 0.25rem;
                            border: 0.4px solid #1d242e;
                            padding: 0.5rem 1.2rem;
                            width: 100%;
                        "
                    >
                        <div
                            style="
                                display: flex;
                                flex-direction: column;
                                justify-content: flex-start;
                                align-items: flex-start;
                                gap: 0rem;
                            "
                        >
                            <p
                                style="
                                    margin: 0;
                                    color: #1d242e;
                                    font-size: 0.875rem;
                                    font-weight: 700;
                                "
                            >
                                Approval for Credit
                            </p>
                            <p
                                style="
                                    margin: 0;
                                    color: #1d242e;
                                    font-size: 0.875rem;
                                "
                            >
                                This member is <span v-show="!approveForCreditValue" >not</span> approved for
                                credit
                            </p>
                        </div>
                        <div class="form-check form-switch">
                            <input
                                class="form-check-input"
                                type="checkbox"
                                role="switch"
                                id="approveForCredit"
                                v-model="approveForCreditValue"
                                @click="approveForCredit"
                                :disabled="loading"
                            />
                        </div>
                    </div>
                    <div
                        v-if="permissions.$can('Blacklist Members')"
                        style="
                            display: flex;
                            justify-content: space-between;
                            align-items: center;
                            gap: 0.5rem;
                            border-radius: 0.25rem;
                            border: 0.4px solid #1d242e;
                            padding: 0.5rem 1.2rem;
                            width: 100%;
                        "
                    >
                        <div
                            style="
                                display: flex;
                                flex-direction: column;
                                justify-content: flex-start;
                                align-items: flex-start;
                                gap: 0rem;
                            "
                        >
                            <p
                                style="
                                    margin: 0;
                                    color: #1d242e;
                                    font-size: 0.875rem;
                                    font-weight: 700;
                                "
                            >
                                Blacklist member
                            </p>
                            <p
                                style="
                                    margin: 0;
                                    color: #1d242e;
                                    font-size: 0.875rem;
                                "
                            >
                                This member is <span v-show="!blacklistedValue">not</span> blacklisted
                            </p>
                        </div>
                        <div class="form-check form-switch">
                            <input
                                class="form-check-input"
                                type="checkbox"
                                role="switch"
                                id="blacklistedValue"
                                v-model="blacklistedValue"
                                @click="blacklist"
                                :disabled="loading"
                            />
                        </div>
                    </div>
                    <div v-if="member.role != 'Market Leader'" class="row mt-3">
                        <div v-show="(parseFloat(auth_user.wallet.current_balance) <= parseFloat(app.userVerificationFee)) && member.verification_status != 'verified'" class="alert alert-danger" role="alert">
                            <strong>
                                You don't have enough funds in your wallet to be deducted for the verication fee. To
                                continue, click the button below to topup.
                            </strong>
                        </div>
                        <div class="d-flex justify-content-right">
                            <div v-if="member.verification_status != 'verified'">
                                <!-- if wallet >  500 for verification -->
                                <div v-show="parseFloat(auth_user.wallet.current_balance) > parseFloat(app.userVerificationFee)" >
                                    <button class="btn btn-sm btn-success me-2"
                                        @click="toggleOpenVerifyUserModal">
                                        <i class="fa fa-check fa-sm"></i>
                                        Verify Member
                                    </button>
                                </div>
                                <!-- if wallet <  500 for verification -->
                                 <div v-show="parseFloat(auth_user.wallet.current_balance) <= parseFloat(app.userVerificationFee)" >
                                    <button class="btn btn-sm btn-success me-2"
                                        @click="makePayment">
                                        <i class="fa-solid fa-wallet"></i>
                                        Top Up wallet
                                    </button>
                                </div>
                            </div>
                            <button class="btn btn-sm btn-danger"
                                    @click="removeUserFromMarket">
                                    <i class="fa fa-trash fa-sm"></i>
                                    Remove From My Market
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <!-- User verification Modal  -->
    <div v-if="openVerifyUserModal">
        <UserVerificationModal :member="member" :market="market" @close="openVerifyUserModal=false"/>
    </div>
    <!-- User Topup Modal  -->
    <div
        class="modal fade"
        id="topupInline"
        tabindex="-1"
        role="dialog"
        aria-labelledby="topupInline"
        aria-hidden="true">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h4 class="modal-title" id="topupInline">Make Verification Payment</h4>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <div class="container-fluid">
                        <InlineTopUp
                            ref="inlineTopUp"
                            :userobject="auth_user"
                            @walletToppedUp="walletToppedUp"
                            :amountToPay="parseFloat(app.userVerificationFee)"
                            :location_url="'/four/market/members'"
                            :virtual_bank_account="props.virtual_bank_account"
                            @walletToppedUpAmount="(args) => (toppedUpAmount = args)"></InlineTopUp>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped></style>
