<script setup>
import {ref, computed, watch, onMounted} from 'vue';
import { useAppStore } from '../../../../../store/app';
import moment from 'moment';
import Swal from "sweetalert2";
import ResendOTP from "../../../../ResendOTP.vue"
import SendOTPForPayment from "../../../../wallet/payments/sendOTPForPayment.vue"

const props = defineProps({
  upcoming_payment: {
    type: Object,
    required: true,
  },

  auth_user: {
    type: Object,
    required: true,
  },
});

const app = useAppStore();
const openModal = ref(false);
const currentStep = ref(1);
const currencySign = app.currencySign;
const errors = ref([]);
const amount = ref(props.upcoming_payment.amount);
const agentFee = ref(0);
const totalAmount = ref(props.upcoming_payment.amount);
const selectedWalletUser = ref(null);
const sentOtpToUser = ref(false);
const otp = ref('');
const childRef = ref();
const saving = ref(false);
onMounted(function () {
    agentFee.value = props.upcoming_payment.amount * 0.01
});
const handleOpenModal = () => {
    openModal.value = true;
    currentStep.value = 1;
};

const closeModal = () => {
    openModal.value = false;
};

const goToStep = (step) => {
  currentStep.value = step;
};

const numberToMoney = (amount) => {
  amount = currencySign + ' ' + parseFloat(amount).toFixed(2);
  return amount;
};

const formattedDate = computed(() => {
  return moment(props.upcoming_payment?.upcoming_at).format('MMMM Do, YYYY');
});

const statusClass = (status) => {
  if (status === 'Pending' || status === 'OverDue') {
    return 'text-warning';
  } else if (status === 'Paid' || status === 'Accepted') {
    return 'text-success';
  } else if (status === 'Automated Rejection' || status === 'Rejected') {
    return 'text-danger';
  }
  return '';
};

const getStatusText = (status) => {
  switch (status) {
    case 'Pending':
      return 'Pending';
    case 'Paid':
      return 'Paid';
    case 'Accepted':
      return 'Accepted';
    case 'OverDue':
      return 'OverDue';
    case 'DUE':
      return 'Due';
    case 'Automated Rejection':
      return 'Automatically Rejected';
    case 'Rejected':
      return 'Rejected';
    default:
      return status;
  }
};

const selectWalletUser = (user) => {

    if (totalAmount.value > parseFloat(user.wallet.current_balance)) {
        errors.value = {totalAmount: "Insufficient balance in selected wallet."};

        return;
    }

    selectedWalletUser.value = user;
    goToStep(2);
};

const setChildRef = (el) => {
    childRef.value = el;
};

function sendOTP() {
    sentOtpToUser.value = true;

    axios.post('/apis/v4/otp/send', {
        'phone': selectedWalletUser.value.phone,
        'user_id': selectedWalletUser.value.id,
        'message':'Your one-time password to complete payment on Amana Market is {otp} and is valid for the next 2 minutes.\n',
        'key': 'pay_for_fee',
    }).then(function () {
        sentOtpToUser.value = true;
        childRef.value.startTimer();
    }).catch(function () {
        sentOtpToUser.value = false;
    });

    goToStep(3);
}

function validateOTP() {
    saving.value = true;

    axios.post('/apis/v4/otp/validate', {
        otp: otp.value,
        user_id: selectedWalletUser.value.id,
        key: 'pay_for_fee',
    }).then(() => {
        completePayments(props.upcoming_payment.amount);
    }).catch((error) => {
        errors.value = error?.response?.data?.errors ?? '';
    });
}

function completePayments(amount) {
    saving.value = true;

    axios.post('/apis/v4/completePayment', {
        'user_id': selectedWalletUser.value.id,
        'order_id': props.upcoming_payment.id,
        'type': props.upcoming_payment.payable_type,
        'amount': amount,
        'agent_fee': agentFee.value ? Math.max(0, parseFloat(agentFee.value)) : null,
    }).then(() => {
          Swal.fire({
            position: 'center',
            icon: 'sucess',
            title: 'Transaction Successful',
            timer: 9000
        }).then(() => {
            location.reload();
        });
        location.reload();
    }).catch(() => {
        saving.value = false;
        Swal.fire({
            position: 'top',
            icon: 'error',
            title: 'Error completing payment. Please try again and contact our support team if problem persists.',
            timer: 9000
        }).then(() => {
            location.reload();
        });
    });
}

watch(agentFee, async (newFee, oldFee) => {
    totalAmount.value = parseFloat(amount.value)
    newFee = newFee ? Math.max(0, parseFloat(newFee)) : 0;
    totalAmount.value += parseFloat(newFee);
})

</script>

<template>
  <div>
    <div class="d-flex justify-content-center">
      <div class="card" :style="{ width: '100%', maxWidth: '600px' }">
        <div class="card-body">
          <h4 class="card-title">Transaction Details</h4>
          <div class="d-flex flex-column justify-content-start align-items-center p-2 border border-success rounded">
            <div class="d-md-flex d-flex-column mb-2 gap w-100 my-1 toast-title text-success">
              <p class="w-50 d-flex justify-content-start justify-content-md-end align-items-center m-0 px-2">Fee Name:</p>
              <div class="w-50 d-flex justify-content-start align-items-center m-0 px-2">{{ upcoming_payment.payable?.name }}</div>
            </div>
            <div class="d-md-flex d-flex-column mb-2 gap w-100 my-1 toast-title">
              <p class="w-50 d-flex justify-content-start justify-content-md-end align-items-center m-0 px-2">Fee Amount:</p>
              <div class="w-50 d-flex justify-content-start align-items-center m-0 px-2">{{ numberToMoney(upcoming_payment.amount ?? 0) }}</div>
            </div>
            <div class="d-md-flex d-flex-column mb-2 gap w-100 my-1">
              <p class="w-50 d-flex justify-content-start justify-content-md-end align-items-center m-0 px-2">Market Name:</p>
              <div class="w-50 d-flex justify-content-start align-items-center m-0 px-2">{{ upcoming_payment.market?.name }}</div>
            </div>
            <div class="d-md-flex d-flex-column mb-2 gap w-100 my-1">
              <p class="w-50 d-flex justify-content-start justify-content-md-end align-items-center m-0 px-2">Due Date:</p>
              <div class="w-50 d-flex justify-content-start align-items-center m-0 px-2">{{ formattedDate }}</div>
            </div>
            <div class="d-md-flex d-flex-column mb-2 gap w-100 my-1">
              <p class="w-50 d-flex justify-content-start justify-content-md-end align-items-center m-0 px-2">Amount Due:</p>
              <div class="w-50 d-flex justify-content-start align-items-center m-0 px-2">{{ upcoming_payment.amount }}</div>
            </div>
            <div class="d-md-flex d-flex-column mb-2 gap w-100 my-1">
              <p class="w-50 d-flex justify-content-start justify-content-md-end align-items-center m-0 px-2" :class="statusClass(upcoming_payment.status)">Status:</p>
              <div class="w-50 d-flex justify-content-start align-items-center m-0">
                <p class="w-100 d-flex justify-content-start align-items-center m-0 px-2" :class="statusClass(upcoming_payment.status)">{{ getStatusText(upcoming_payment.status) }}</p>
              </div>
            </div>
            <div v-if="upcoming_payment.status == 'Paid'">
              <tr>
                <th> Paid</th>
                <td>
                  <i class="fa fa-shield-check fa-2x text-success"></i>
                </td>
              </tr>
            </div>
            <div v-else>
                <div class="d-flex justify-content-center">
                    <button class="btn btn-success" @click="handleOpenModal()">Pay</button>
                </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- Modal -->
    <div v-if="openModal" class="modal-overlay">
        <div class="card mb-3 modal-content">
            <svg class="modal-close-icon"
                @click="closeModal"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <g clip-path="url(#clip0_8_6557)">
                    <path
                        d="M13.89 8.7L12 10.59L10.11 8.7C9.72 8.31 9.09 8.31 8.7 8.7C8.31 9.09 8.31 9.72 8.7 10.11L10.59 12L8.7 13.89C8.31 14.28 8.31 14.91 8.7 15.3C9.09 15.69 9.72 15.69 10.11 15.3L12 13.41L13.89 15.3C14.28 15.69 14.91 15.69 15.3 15.3C15.69 14.91 15.69 14.28 15.3 13.89L13.41 12L15.3 10.11C15.69 9.72 15.69 9.09 15.3 8.7C14.91 8.32 14.27 8.32 13.89 8.7ZM12 2C6.47 2 2 6.47 2 12C2 17.53 6.47 22 12 22C17.53 22 22 17.53 22 12C22 6.47 17.53 2 12 2ZM12 20C7.59 20 4 16.41 4 12C4 7.59 7.59 4 12 4C16.41 4 20 7.59 20 12C20 16.41 16.41 20 12 20Z"
                        fill="#1D242E" />
                </g>
                <defs>
                    <clipPath id="clip0_8_6557">
                        <rect width="24" height="24" fill="white" />
                    </clipPath>
                </defs>
            </svg>

            <div class="card-body">
                <div v-if="currentStep === 1">
                    <h5 class="text-success">Select wallet and add fee </h5>
                    <p><strong>
                        Fee amount: {{ currencySign }}{{ amount }}
                        <span v-if="auth_user.id !== upcoming_payment.user_id">
                            <br/>
                            Total amount: {{ currencySign }}{{ totalAmount }}
                        </span>
                    </strong></p>

                    <div class="col-12">
                        <div class="form-floating" v-if="auth_user.id !== upcoming_payment.user_id">
                            <input
                                type="number"
                                disabled
                                id="agentFee"
                                v-model="agentFee"
                                class="form-control mt-1"
                                placeholder="Input your fee"
                            />
                            <label for="agentFee">You can add your fee for helping with this transaction</label>
                        </div>

                        <p v-if="errors.totalAmount" class="alert alert-danger mt-2 mb-1">
                            {{ errors.totalAmount.toString() }}
                        </p>

                        <button
                            class="btn btn-success button-spacing"
                            @click="selectWalletUser(auth_user)"
                        >
                            <strong>My wallet</strong>
                            <br>
                             {{ numberToMoney(auth_user.wallet?.current_balance) }}
                        </button>
                        <button v-if="auth_user.id !== upcoming_payment.user_id" type="submit" class="btn btn-outline-warning button-spacing" @click="selectWalletUser(upcoming_payment.user)">
                              {{ upcoming_payment.user?.first_name }}'s wallet
                              <!-- <br> -->
                               <!-- {{ numberToMoney( upcoming_payment.user?.wallet.current_balance) }} -->
                        </button>
                    </div>
                </div>

                <div v-if="currentStep === 2">
                    <h5 class="text-success">Make Payment</h5>
                    <div>
                        <p><strong>The Sum of {{ currencySign }} {{ amount }} will be deducted from {{ selectedWalletUser.name }}'s wallet to pay for the transaction</strong></p>
                        <button type="button" @click="sendOTP()" class="btn btn-success button-spacing">Yes, Make Payment</button>
                        <button type="button" @click="closeModal" class="btn btn-outline-warning button-spacing mt-3">No Cancel Payment</button>
                    </div>
                </div>

                <div v-if="currentStep === 3">
                  <div>
                    <div v-if="sentOtpToUser === true" class="mt-3">
                      <send-o-t-p-for-payment
                            :errors="errors"
                            @updatedotp="(args) => (otp = args)"
                      ></send-o-t-p-for-payment>
                      <button type="button" style="width: 100%" class="btn btn-success button-spacing mt-3" @click="validateOTP()">
                        Pay
                        <i class="fa fa-spin fa-spinner" v-if="saving"></i>
                      </button>
                    </div>
                    <div v-else>
                      <div class="col-12 mt-3">
                        <div class="alert alert-danger" role="alert">
                          <div class="d-flex justify-content-between">
                            <div>
                              <i class="fa fa-info-circle fa-3x"></i>
                            </div>
                            <div class="px-4 flex-grow-1">
                              <p>
                                There was an error sending OTP to the <strong>phone number</strong> associated with your account. Please check your details and try again. If the problem persists, please contact our support team.
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="mt-2 mb-3">
                        <ResendOTP v-if="sentOtpToUser" :ref="setChildRef" :send-otp="sendOTP"/>
                    </div>
                  </div>
                </div>
            </div>
        </div>
    </div>
</template>


<style scoped>
.modal-overlay {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: grid;
  place-items: center;
  padding: 1rem;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 50;
}
.modal-content {
  position: relative;
  border-radius: 0.25rem;
  border: 0.2px solid #fff;
  box-shadow: none;
  max-width: 500px;
  width: 95%;
  padding: 1rem;
  background-color: white;
}
.modal-close-icon {
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
  cursor: pointer;
}
.modal-body {
  padding: 1rem;
}

.modal-body {
    padding: 1rem;
}

.button-spacing {
  margin-top: 1rem;
  margin-right: 1rem;
}
</style>
