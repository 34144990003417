<script setup>
import {computed, onMounted, ref, watch} from "vue";

const props = defineProps([
    "product",
    "amount",
    "quantity",
    "isAgentOrder",
    "agentFee",
    "buyer",
    "borrowinglimit_clear",
    "balance_clear_prop",
    "market",
    "borrowinglimit_clear_prop",
    "overdue_charge",
]);
const emit = defineEmits([
    "enablepayment",
    "totalamountbilled",
    "selectedmethod",
    "updatenumberofdays",
    'isInvalidDaysToPay'
]);

const charges = ref("");
const currency = import.meta.env.VITE_LOCALIZATION_COUNTRY_CURRENCY_SIGN;
const errors = ref("");
const number_of_days_to_pay = ref();
const selectedmethod = ref("");
const balance_clear = ref(0);
const buyer_balance = ref(0);
const buyer_credit_balance = ref(0);
const credit_limit_clear = ref(0);
const forsomeoneelse = ref(false);
const withoutMarketInsurance = ref({});
const validNumberOfDays = ref(false);

defineExpose({
    updatePaymentMethod,
    resetMethod,
    updateBalance,
    updateCreditBalance,
    updateBalanceCredit,
});

onMounted(function () {
    balance_clear.value = props.balance_clear_prop;

    /*optimize this as its a quick fix*/
    axios.get("/userFees?method=wallet").then((resp) => {
        charges.value = resp.data;
        withoutMarketInsurance.value = resp.data;
        selectedmethod.value = "wallet";
        validNumberOfDays.value = number_of_days_to_pay.value <= charges?.value.bnpl?.daily_fee_max_days ?? 365;
    });
});

const invalidDaysToPay = computed(() => {
    return (selectedmethod.value === 'credit' || selectedmethod.value === 'buyer_credit') &&
        (!number_of_days_to_pay.value || number_of_days_to_pay.value < 0 || !validNumberOfDays.value);
});

watch(number_of_days_to_pay, function (newValue, oldValue) {
    updatePaymentMethod(selectedmethod.value ?? "credit");
    validNumberOfDays.value = false;

    if (newValue > (charges?.value.bnpl?.daily_fee_max_days ?? 365)) {
        errors.value = {
            number_of_days_to_pay: `The maximum number of days you can take to pay is ${charges?.value.bnpl?.daily_fee_max_days ?? '365'}.`,
        };
    } else if (!number_of_days_to_pay.value || number_of_days_to_pay.value < 1) {
        errors.value = {
            number_of_days_to_pay: `Please input a valid number of days`,
        };
    } else {
        errors.value = {};
        validNumberOfDays.value = true;
    }

    emit("updatenumberofdays", newValue);
    totalAmount();
});

watch(selectedmethod, async (newValue, oldValue) => {
    totalAmount();
});

watch(invalidDaysToPay, async (newValue, oldValue) => {
    emit("isInvalidDaysToPay", newValue);
});

function updateBalance(balance) {
    balance_clear.value = balance;
    // totalAmount();
}

function updateCreditBalance(balance) {
    credit_limit_clear.value = balance;
    // totalAmount();
}

function updateBalanceCredit(balance) {
    credit_limit_clear.value = balance;
    forsomeoneelse.value = true;
}

function currencyFormat(amount) {
    let amounts = new Intl.NumberFormat("en-KE", {
        maximumSignificantDigits: 3,
    }).format(amount);
    return `${currency}${amounts}`;
}

function emitSelectedAndPayment(method, enablePayment = false){
    emit("selectedmethod", method);
    emit("enablepayment", enablePayment, method);
}

async function updatePaymentMethod(method) {
    selectedmethod.value = method;
    buyer_balance.value = props.buyer?.wallet?.current_balance ?? 0;
    buyer_credit_balance.value = props.buyer?.agent_capital?.balance ?? 0;

    if (method === "wallet" || method === "buyer_wallet") {
        charges.value = withoutMarketInsurance.value;
        let balance = method === "wallet" ? props.balance_clear : buyer_balance.value;

        if (props.amount + 0.025 * props.amount < parseInt(balance)) {
            emitSelectedAndPayment(method);
            return;
        }
    }

    if (method === "credit" || method === "buyer_credit") {
        await axios.get("/userFees").then((resp) => {
            charges.value = resp.data;
            selectedmethod.value = method;
            validNumberOfDays.value = number_of_days_to_pay.value <= charges?.value.bnpl?.daily_fee_max_days ?? 365;
        });

        let balance = method === "credit" ? credit_limit_clear.value : buyer_credit_balance.value;
        let total = props.amount + 0.025 * props.amount + 0.003 * props.amount * number_of_days_to_pay.value;

        if (total < parseInt(balance)) {
            emitSelectedAndPayment(method);
            return;
        }
    }

    emitSelectedAndPayment(method, true);
}

function resetMethod() {
    document.getElementById("pills-home-tab").click();
}

function totalAmount() {
    let fee = 0;
    let days = number_of_days_to_pay.value ? number_of_days_to_pay.value : 0;

    if (selectedmethod.value === "credit" || selectedmethod.value === "buyer_credit") {
        let bnplFee = 0;
        let bnplDailyFee = 0;

        if (charges.value) {
            if (charges.value.bnpl) {
                bnplFee = charges.value.bnpl.fee;
                bnplDailyFee = charges.value.bnpl.daily_fee;
            }
        }

        fee = (bnplFee + days * bnplDailyFee) * props.amount;
    }

    if (selectedmethod.value === "wallet" || selectedmethod.value === "buyer_wallet") {
        let marketFee = 0;

        if (charges.value) {
            if (charges.value.marketFee) {
                marketFee = charges.value.marketFee.fee;
            }
        }

        fee = marketFee * props.amount;
    }
    let totalCharges = props.amount * charges.value.charges_total;
    let total = fee + totalCharges + props.amount + props.agentFee;
    emit("totalamountbilled", total);
    return total;
}
</script>

<template>
    <div class="row">
        <ul class="nav nav-pills mb-3" id="pills-tab" role="tablist">
            <li class="nav-item" role="presentation">
                <button
                    class="nav-link"
                    :style="selectedmethod === 'wallet' ? { backgroundColor: '#00AB4D', color: 'white' } : { color: 'green' }"
                    @click="updatePaymentMethod('wallet')"
                    id="wallet-summary-tab"
                    data-bs-toggle="pill"
                    data-bs-target="#wallet-summary"
                    type="button"
                    role="tab"
                    aria-controls="wallet-summary"
                    aria-selected="true"
                >
                    Pay With Wallet
                </button>
            </li>
            <li class="nav-item" role="presentation" v-if="isAgentOrder">
                <button
                    class="nav-link"
                    :style="selectedmethod === 'buyer_wallet' ? { backgroundColor: '#00AB4D', color: 'white' } : { color: 'green' }"
                    id="wallet-summary-tab"
                    @click="updatePaymentMethod('buyer_wallet')"
                    data-bs-toggle="pill"
                    data-bs-target="#wallet-summary"
                    type="button"
                    role="tab"
                    aria-controls="wallet-summary"
                    aria-selected="false"
                >
                    Pay With {{ buyer?.first_name ?? 'Buyer' }}'s Wallet
                </button>
            </li>
            <li class="nav-item" role="presentation">
                <button
                    class="nav-link"
                    :style="selectedmethod === 'credit' ? { backgroundColor: '#00AB4D', color: 'white' } : { color: 'green' }"
                    id="credit-summary-tab"
                    @click="updatePaymentMethod('credit')"
                    data-bs-toggle="pill"
                    data-bs-target="#credit-summary"
                    type="button"
                    role="tab"
                    aria-controls="credit-summary"
                    aria-selected="false"
                >
                    Pay With Credit
                </button>
            </li>
            <li class="nav-item" role="presentation" v-if="isAgentOrder">
                <button
                    class="nav-link"
                    :style="selectedmethod === 'buyer_credit' ? { backgroundColor: '#00AB4D', color: 'white' } : { color: 'green' }"
                    id="credit-summary-tab"
                    @click="updatePaymentMethod('buyer_credit')"
                    data-bs-toggle="pill"
                    data-bs-target="#credit-summary"
                    type="button"
                    role="tab"
                    aria-controls="credit-summary"
                    aria-selected="false"
                >
                    Pay With {{ buyer?.first_name ?? 'Buyer' }}'s Credit
                </button>
            </li>
        </ul>
        <div class="tab-content" id="pills-tabContent">
            <div
                class="tab-pane fade show active"
                id="wallet-summary"
                role="tabpanel"
                aria-labelledby="wallet-summary-tab"
            >
                <div class="card bg-blue" v-if="selectedmethod === 'wallet' || selectedmethod === 'buyer_wallet'">
                    <div class="card-body">
                        <h4 class="card-title text-black">Payment Summary</h4>

                        <div class="col-12 text-black">
                            <div class="transaction-history-success-table d-flex d-md-none p-4 mt-3">
                                <div class="flex-grow-1 d-flex flex-column align-items-start">
                                    <p class="m-0 icon-active-color fw-bold">Total Due</p>
                                    <p class="m-0 icon-active-color fw-bold">{{ currency }}{{ totalAmount().toLocaleString() }}</p>
                                    <p class="m-0 mt-2">Payment to Seller</p>
                                    <p class="m-0">{{ currencyFormat(props.amount) }}</p>
                                    <p class="m-0 mt-2">Amana Market Charges</p>
                                    <p class="m-0">{{ currencyFormat((charges.marketFee?.fee ?? 0) * props.amount) }}</p>
                                    <p class="m-0 mt-2" v-if="isAgentOrder">Agent Fee</p>
                                    <p class="m-0" v-if="isAgentOrder">{{ currencyFormat(props.agentFee) }}</p>
                                    <p class="m-0 mt-2">{{ market }}
                                        Market Charges
                                        <span class="text-muted">{{ charges.all_charges }}</span>
                                    </p>
                                    <p class="m-0"></p>
                                    <p class="m-0">{{currencyFormat(charges.charges_total * props.amount) }}</p>
                                    <p class="m-0 mt-2">Wallet Balance</p>
                                    <div style="font-weight: bold">
                                        <div v-if="selectedmethod === 'wallet'">
                                            <div class="m-0 text-danger" v-if="totalAmount() > parseInt(balance_clear_prop)">
                                                {{ currencyFormat(parseInt(balance_clear_prop)) }}
                                            </div>
                                            <div v-else>{{ currencyFormat(parseInt(balance_clear_prop)) }}</div>
                                        </div>
                                        <div v-else>xxxxxx</div>
                                    </div>
                                </div>
                            </div>
                            <div class="transaction-history-success-table d-flex p-4 d-none d-md-flex">
                                <div class="flex-grow-1 d-flex flex-column align-items-center">
                                    <p class="m-0 icon-active-color fw-bold">Total Due</p>
                                    <p class="m-0">Payment to Seller</p>
                                    <p class="m-0">Amana Market Charges</p>
                                    <p class="m-0">{{ market }} Market Charges
                                        <span class="text-muted">
                                            {{ charges.all_charges }}
                                        </span>
                                    </p>
                                    <p class="m-0" v-if="isAgentOrder">Agent Fee</p>
                                    <p class="m-0">Wallet Balance</p>
                                </div>
                                <div class="flex-grow-1">
                                    <p class="m-0 icon-active-color fw-bold">{{ currency }}{{ totalAmount().toLocaleString() }}</p>
                                    <p class="m-0">{{ currencyFormat(props.amount) }}</p>
                                    <p class="m-0">{{ currencyFormat((charges.marketFee?.fee ?? 0) * props.amount) }}</p>
                                    <p class="m-0">{{ currencyFormat(charges.charges_total * props.amount) }}</p>
                                    <p class="m-0" v-if="isAgentOrder">{{ currencyFormat(agentFee) }}</p>
                                    <div style="font-weight: bold">
                                        <div v-if="selectedmethod === 'wallet'">
                                            <div class="m-0 text-danger" v-if="totalAmount() > parseInt(balance_clear_prop)">
                                                {{ currencyFormat(parseInt(balance_clear_prop)) }}
                                            </div>
                                            <div v-else>{{ currencyFormat(parseInt(balance_clear_prop)) }}</div>
                                        </div>
                                        <div v-else>xxxxxx</div>
                                    </div>
                                </div>
                            </div>
                            <div
                                class="d-flex mt-4 d-sm-block"
                                v-if="selectedmethod === 'wallet' && totalAmount() > parseInt(balance_clear_prop)"
                            >
                                <div
                                    :style="{background: '#fff', border: '1px solid #ff7900 !important', color: '#ff7900 !important'}"
                                    class="alert alert-danger m-0"
                                    role="alert"
                                >
                                    <div>
                                        <p>
                                            Your wallet balance is
                                            <strong>
                                                {{ currencyFormat(parseInt(balance_clear_prop)) }}
                                            </strong>
                                            proceed and top up wallet. Third
                                            party payment charges may apply.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div
                                class="d-flex mt-4 d-sm-block"
                                v-else-if="selectedmethod === 'buyer_wallet' && totalAmount() > parseInt(buyer_balance)"
                            >
                                <div
                                    :style="{background: '#fff', border: '1px solid red !important', color: 'red !important'}"
                                    class="alert alert-danger m-0"
                                    role="alert"
                                >
                                    <div>
                                        <p>
                                            {{ buyer?.first_name ?? 'Buyer' }}'s wallet balance is insufficient to
                                            make payment. Please top-up {{ buyer?.first_name ?? 'Buyer' }}'s wallet.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div
                class="tab-pane fade"
                id="credit-summary"
                role="tabpanel"
                aria-labelledby="credit-summary-tab"
            >
                <div class="card bg-blue" v-if="selectedmethod === 'credit' || selectedmethod === 'buyer_credit'">
                    <div class="card-body">
                        <h4 class="card-title text-black">Payment Summary</h4>

                        <div class="col-12 text-black">
                            <div class="transaction-history-success-table d-md-flex d-none p-4">
                                <div class="flex-grow-1 d-flex flex-column align-items-center">
                                    <p class="m-0 icon-active-color fw-bold">Total Due</p>
                                    <p class="m-0">Payment to Seller</p>
                                    <p class="m-0">Amana Market Charges</p>
                                    <p class="m-0">{{ market }} Market Charges
                                        <span class="text-muted">
                                            {{ charges.all_charges }}
                                        </span>
                                    </p>
                                    <p class="m-0" v-if="isAgentOrder">Agent Fee</p>
                                    <p class="m-0">Credit Balance</p>
                                </div>

                                <div class="flex-grow-1">
                                    <p class="m-0 icon-active-color fw-bold">{{ currency }}{{ totalAmount().toLocaleString() }}</p>
                                    <p class="m-0">{{ currencyFormat(props.amount) }}</p>
                                    <p class="m-0">
                                        {{
                                            currencyFormat(
                                                (charges?.bnpl?.fee ?? 0) * amount + (number_of_days_to_pay ?? 0) *
                                                (charges?.bnpl?.daily_fee ?? 0) * amount
                                            )
                                        }}
                                    </p>
                                    <p class="m-0">{{ currencyFormat(charges.charges_total * props.amount) }}</p>
                                    <p class="m-0" v-if="isAgentOrder">{{ currencyFormat(agentFee) }}</p>

                                    <div style="font-weight: bold">
                                        <div v-if="selectedmethod === 'credit'">
                                            <div class="text-danger" v-if="parseInt(borrowinglimit_clear) < amount">
                                                {{ currencyFormat(parseInt(borrowinglimit_clear)) }}
                                            </div>
                                            <div v-else>
                                                {{ currencyFormat(parseInt(borrowinglimit_clear)) }}
                                            </div>
                                        </div>
                                        <div v-else>xxxxxx</div>
                                    </div>
                                </div>
                            </div>
                            <div class="transaction-history-success-table d-flex d-md-none p-4">
                                <div class="flex-grow-1 d-flex flex-column align-items-start">
                                    <p class="m-0 icon-active-color fw-bold">Total Due</p>
                                    <p class="m-0 icon-active-color fw-bold">{{ currency }}{{ totalAmount().toLocaleString() }}</p>
                                    <p class="m-0">Payment to Seller</p>
                                    <p class="m-0">{{ currencyFormat(props.amount) }}</p>
                                    <p class="m-0 mt-2">Amana Market Charges</p>
                                    <p class="m-0">
                                        {{
                                            currencyFormat(
                                                (charges?.bnpl?.fee ?? 0) * amount + (number_of_days_to_pay ?? 0) *
                                                (charges?.bnpl?.daily_fee ?? 0) * amount
                                            )
                                        }}
                                    </p>
                                    <p class="m-0 mt-2">{{ market }} Market Charges
                                        <span class="text-muted">
                                            {{ charges.all_charges }}
                                        </span>
                                    </p>
                                    <p class="m-0">{{ currencyFormat(charges.charges_total * props.amount) }}</p>
                                    <p class="m-0" v-if="isAgentOrder">Agent Fee</p>
                                    <p class="m-0" v-if="isAgentOrder">{{ currencyFormat(agentFee) }}</p>
                                    <p class="m-0 mt-2">Credit Balance</p>
                                    <div style="font-weight: bold">
                                        <div v-if="selectedmethod === 'credit'">
                                            <div class="text-danger" v-if="parseInt(borrowinglimit_clear) < amount">
                                                {{ currencyFormat(parseInt(borrowinglimit_clear)) }}
                                            </div>
                                            <div v-else>
                                                {{ currencyFormat(parseInt(borrowinglimit_clear)) }}
                                            </div>
                                        </div>
                                        <div v-else>xxxxxx</div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-12 mt-3">
                                <!--Label: Number of days to pay, Attributes:number_of_days_to_pay -->
                                <div class="form-floating">
                                    <input
                                        type="number"
                                        class="form-control"
                                        id="number_of_days_to_pay"
                                        v-model="number_of_days_to_pay"
                                        style="border: #8a8e92 1px solid"
                                        aria-describedby="number_of_days_to_pay-help"
                                        :class="[
                                            errors.number_of_days_to_pay
                                                ? 'is-invalid'
                                                : '',
                                            !errors.number_of_days_to_pay &&
                                            Object.keys(errors).length > 1
                                                ? 'is-valid'
                                                : '',
                                        ]"
                                    />
                                    <label class="form-label" for="number_of_days_to_pay" style="color: #8a8e92">
                                        <strong> Number of days to pay</strong>
                                    </label>
                                    <div class="invalid-feedback" v-if="errors.number_of_days_to_pay">
                                        {{ errors.number_of_days_to_pay.toString() }}
                                    </div>
                                </div>
                            </div>
                            <div class="d-md-flex mt-4" v-if="selectedmethod === 'credit' && parseInt(borrowinglimit_clear) < amount">
                                <div :style="{background: '#fff', border: '1px solid red !important', color: 'red !important'}"
                                    class="alert alert-danger m-0"
                                    role="alert"
                                >
                                    <div>
                                        <p>
                                            Your credit balance is
                                            <strong>
                                                {{ currencyFormat(parseInt(borrowinglimit_clear)) }}
                                            </strong>
                                            which is insufficient to make payment. Please top-up credit wallet.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div class="d-md-flex mt-4" v-else-if="selectedmethod === 'buyer_credit' && parseInt(buyer_credit_balance) < amount">
                                <div :style="{background: '#fff', border: '1px solid red !important', color: 'red !important'}"
                                     class="alert alert-danger m-0"
                                     role="alert"
                                >
                                    <div>
                                        <p>
                                            {{ buyer?.first_name ?? 'Buyer' }}'s credit balance is insufficient to
                                            make payment. Please top-up {{ buyer?.first_name ?? 'Buyer' }}'s credit wallet.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div v-else>
                                <div v-if="overdue_charge > 0" class="alert alert-warning mt-4" role="alert">
                                    <div class="d-flex justify-content-between">
                                        <div><i class="fa fa-info-circle fa-3x"></i></div>
                                        <div class="px-4 flex-grow-1">
                                            <p>
                                                Overdue charges of <strong>{{ overdue_charge }}%</strong> on the order total
                                                <strong>i.e {{ currency }}{{ parseFloat((overdue_charge/100) * totalAmount()).toFixed(2) }}</strong>
                                                will be applied if the credit payment is not made within the
                                                specified number of days.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped></style>
