<script setup>

import {onMounted, ref} from "vue";
import {Bootstrap5Pagination} from 'laravel-vue-pagination';
import moment from "moment/moment";
import Swal from "sweetalert2";
import wallet from "../../../../wallet/index.vue";

const currency = import.meta.env.VITE_LOCALIZATION_COUNTRY_CURRENCY_SIGN;

const withdrawalRequests = ref({});
const errors = ref({});
const searchRequest = ref('');
const approving = ref(false);
const rejecting = ref(false);
const checkedAll = ref(false);
const selectedRequests = ref([]);

const type = ref('');
const walletType = ref('');
const withdrawStatus = ref('');
const orderNumber = ref('');
const selectedWithdrawalRequest = ref({});

function populateWithdrawalRequest(withdrawalRequest) {
    if (withdrawalRequest) {
        selectedWithdrawalRequest.value = withdrawalRequest;
        type.value = withdrawalRequest.type.charAt(0).toUpperCase() + withdrawalRequest.type.slice(1);
        walletType.value = withdrawalRequest.wallet_type.charAt(0).toUpperCase() + withdrawalRequest.wallet_type.slice(1);
        withdrawStatus.value = withdrawalRequest.status.charAt(0).toUpperCase() + withdrawalRequest.status.slice(1);
        orderNumber.value = withdrawalRequest.meta?.order?.ref ?? withdrawalRequest.meta?.order ?? '---';
    }
}

function onClickSelectAll() {
    checkedAll.value = !checkedAll.value;
    selectedRequests.value = [];

    $(".cbx").each(function() {
        const idParts = this.id.split("_");
        let id = idParts[1]
        let status = idParts[2]

        if(status === 'pending'){
            this.checked = checkedAll.value

            if(checkedAll.value){
                selectedRequests.value.push(id)
            }
        }

    });
}

function approveOrReject(withdrawalRequest, status) {
    let swal;

    if (status === 'approved') {
        approving.value = true;

        swal = Swal.fire({
            position: 'top',
            icon: 'info',
            title: 'You are about to approve this withdrawal request',
            showDenyButton: true,
            confirmButtonText: 'Approve',
            denyButtonText: 'Cancel',
        });
    }else{
        rejecting.value = true;
        status = 'canceled';

        swal = Swal.fire({
            position: 'top',
            icon: 'info',
            title: 'You are about to reject this withdrawal request',
            showDenyButton: true,
            confirmButtonText: 'Reject',
            denyButtonText: 'Cancel',
        });
    }

    swal.then((result) => {
        if (result.isConfirmed) {
            axios
                .put('/apis/v4/saas/withdrawal-requests/' + withdrawalRequest.id, {
                    status: status
                })
                .then(() => {
                    location.reload();
                })
                .catch(() => {
                    Swal.fire({
                        position: 'top',
                        icon: 'error',
                        title: 'Error approving/rejecting withdrawal request.',
                    });
                }).finally(() => {
                    approving.value = false;
                    rejecting.value = false;
                });
        }else{
            approving.value = false;
            rejecting.value = false;
        }
    })
}

function approveOrRejectMulti(status) {
    let swal;
    let ids = selectedRequests.value;

    if(ids.length < 1) {
        return;
    }

    if (status === 'approved') {
        approving.value = true;

        swal = Swal.fire({
            position: 'top',
            icon: 'info',
            title: 'You are about to approve ' + ids.length + ' withdrawal requests',
            showDenyButton: true,
            confirmButtonText: 'Approve',
            denyButtonText: 'Cancel',
        });
    }else{
        rejecting.value = true;
        status = 'canceled';

        swal = Swal.fire({
            position: 'top',
            icon: 'info',
            title: 'You are about to reject ' + ids.length + ' withdrawal requests',
            showDenyButton: true,
            confirmButtonText: 'Reject',
            denyButtonText: 'Cancel',
        });
    }

    swal.then((result) => {
        if (result.isConfirmed) {
            axios
                .put('/apis/v4/saas/withdrawal-requests', {
                    status: status,
                    ids: ids
                })
                .then(() => {
                    location.reload();
                })
                .catch(() => {
                    Swal.fire({
                        position: 'top',
                        icon: 'error',
                        title: 'Error approving/rejecting ' + ids.length + ' withdrawal request.',
                    });
                }).finally(() => {
                approving.value = false;
                rejecting.value = false;
            });
        }else{
            approving.value = false;
            rejecting.value = false;
        }
    })
}

const getWithdrawalRequests = async (page = 1) => {
    await axios.get(`/apis/v4/saas/withdrawal-requests?page=${page}&keyword=${searchRequest.value}`).then(resp => {
        withdrawalRequests.value = resp.data.data;
    }).catch(err => {
        errors.value = err.response.data.errors;
    });
}

function setSearchWithdrawalRequest() {
    let urlParams = new URLSearchParams(window.location.search);
    searchRequest.value = urlParams.has('search') ? urlParams.get('search') : '';
}

function formatDate(value) {
    return moment(value).format('DD/MM/YYYY HH:mm:ss');
}

onMounted(function () {
    setSearchWithdrawalRequest();
    getWithdrawalRequests();
});

</script>

<template>
    <div>
        <div class="row">
            <div class="card">
                <div class="card-body">
                    <div class="col-12">
                        <form action="/four/admin/finance/withdrawal-requests" method="GET">
                            <div class="col-md-6">
                                <div class="input-group mb-3">
                                    <input type="text" class="form-control" placeholder="Search by name or status"
                                           name="search"
                                           id="searchWithdrawalRequest"
                                           :value="searchRequest"
                                           aria-label="Search Withdrawal Request" aria-describedby="button-addon2">
                                    <button class="btn btn-outline-secondary" type="submit" id="performSearchRequest">
                                        Search
                                        <i class="fa fa-search"></i>
                                    </button>
                                </div>
                            </div>
                            <div class="col-md-6 mb-3" v-if="selectedRequests.length > 0">
                                <button
                                    class="btn btn-sm btn-success"
                                    @click="approveOrRejectMulti('approved')"
                                    :disabled="approving"
                                >
                                    Approve ({{selectedRequests.length}}) Requests
                                    <i class="fa fa-spin fa-spinner" v-if="approving"></i>
                                </button>
                                &nbsp;
                                <button
                                    class="btn btn-sm btn-danger"
                                    @click="approveOrRejectMulti('reject')"
                                    :disabled="rejecting"
                                >
                                    Reject ({{selectedRequests.length}}) Requests
                                    <i class="fa fa-spin fa-spinner" v-if="rejecting"></i>
                                </button>
                            </div>
                        </form>
                    </div>
                    <div class="table-responsive">
                        <table class="table">
                            <thead v-if="withdrawalRequests && withdrawalRequests.data && withdrawalRequests.data.length > 0">
                            <tr>
                                <th><input class="form-check-input" type="checkbox" @click="onClickSelectAll"></th>
                                <th>#</th>
                                <th>User</th>
                                <th>Type</th>
                                <th>Amount</th>
                                <th>Current Balance</th>
                                <!--<th>Wallet</th>-->
                                <th>Status</th>
                                <th>Date</th>
                                <th>Action</th>
                                <th></th>
                            </tr>
                            </thead>
                            <tbody v-if="withdrawalRequests && withdrawalRequests.data && withdrawalRequests.data.length > 0">
                            <tr v-for="(withdrawalRequest,index) in withdrawalRequests.data" :key="withdrawalRequest.id">
                                <td>
                                    <input
                                        class="form-check-input cbx"
                                        type="checkbox"
                                        :id="`cbx_${withdrawalRequest.id}_${withdrawalRequest.status}`"
                                        name="cbxSelectedRequests"
                                        v-model="selectedRequests"
                                        :value="withdrawalRequest.id"
                                        :disabled="withdrawalRequest.status !== 'pending'"
                                    >
                                </td>
                                <td scope="row">{{ index + 1 }}</td>
                                <td>{{ withdrawalRequest.user.name }}</td>
                                <td>{{ withdrawalRequest.type.charAt(0).toUpperCase() + withdrawalRequest.type.slice(1) }}</td>
                                <td>{{ currency }}{{ withdrawalRequest.amount.toLocaleString() }}</td>
                                <td>{{ currency }}{{ withdrawalRequest.current_balance.toLocaleString() }}</td>
                                <!--<td>{{ withdrawalRequest.wallet_type }}</td>-->
                                <td>
                                    <div class="d-flex align-items-center text-primary" v-if="withdrawalRequest.status === 'pending'">
                                        <i class="bx bx-radio-circle-marked bx-burst bx-rotate-90 align-middle font-18 me-1"/>
                                        <span>Pending</span>
                                    </div>
                                    <div class="d-flex align-items-center text-danger" v-else-if="withdrawalRequest.status === 'canceled'">
                                        <span>Rejected</span>
                                    </div>
                                    <div class="d-flex align-items-center text-success" v-else>
                                        <span>Approved</span>
                                    </div>
                                </td>
                                <td>{{ formatDate(withdrawalRequest.created_at) }}</td>
                                <td>
                                    <button
                                        v-if="withdrawalRequest.status === 'pending' && selectedRequests.length === 0"
                                        class="btn btn-sm btn-success"
                                        @click="approveOrReject(withdrawalRequest,'approved')"
                                        :disabled="approving"
                                    >
                                        Approve
                                        <i class="fa fa-spin fa-spinner" v-if="approving"></i>
                                    </button>
                                    &nbsp;
                                    <button
                                        v-if="withdrawalRequest.status === 'pending' && selectedRequests.length === 0"
                                        class="btn btn-sm btn-danger"
                                        @click="approveOrReject(withdrawalRequest,'reject')"
                                        :disabled="rejecting"
                                    >
                                        Reject
                                        <i class="fa fa-spin fa-spinner" v-if="rejecting"></i>
                                    </button>
                                </td>
                                <td>
                                    <button class="btn btn-sm btn-outline-default"
                                            @click="populateWithdrawalRequest(withdrawalRequest)"
                                            data-bs-toggle="offcanvas" href="#viewWithdrawalRequest" role="button"
                                            aria-controls="viewWithdrawalRequest"
                                    >
                                        See More <i class="fa fa-angle-double-right fa-thin"></i>
                                    </button>
                                </td>
                            </tr>
                            </tbody>
                            <tbody v-else>
                            <tr>
                                <td align="center" colspan="7">No record found.</td>
                            </tr>
                            </tbody>
                        </table>
                        <div class="col-12">
                            <bootstrap5-pagination :data="withdrawalRequests" @pagination-change-page="getWithdrawalRequests" style="overflow: scroll"/>
                        </div>
                    </div>
                    <div class="offcanvas offcanvas-end" tabindex="-1" id="viewWithdrawalRequest"
                         aria-labelledby="viewWithdrawalRequestLabel">
                        <div class="offcanvas-header">
                            <h5 class="offcanvas-title" id="viewWithdrawalRequestLabel">Withdrawal Request Details</h5>
                            <button type="button" class="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
                        </div>
                        <div class="offcanvas-body">
                            <div class="table-responsive">
                                <table class="table table-borderless">
                                    <tbody>
                                    <tr>
                                        <td>User:</td>
                                        <td><b>{{ selectedWithdrawalRequest?.user?.name }}</b></td>
                                    </tr>
                                    <tr>
                                        <td>Type:</td>
                                        <td><b>{{ type }}</b></td>
                                    </tr>
                                    <tr v-if="type === 'Order'">
                                        <td>Order #:</td>
                                        <td><b>{{ orderNumber }}</b></td>
                                    </tr>
                                    <tr>
                                        <td>Wallet Type:</td>
                                        <td><b>{{ walletType }}</b></td>
                                    </tr>
                                    <tr>
                                        <td>Amount:</td>
                                        <td class="text-success">
                                            <b>{{ currency }}{{ selectedWithdrawalRequest?.amount?.toLocaleString() }}</b>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Current Balance:</td>
                                        <td class="text-primary">
                                            <b>{{ currency }}{{ selectedWithdrawalRequest?.current_balance?.toLocaleString() }}</b>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Account Name:</td>
                                        <td><b>{{ selectedWithdrawalRequest?.account_name }}</b></td>
                                    </tr>
                                    <tr>
                                        <td>Account Number:</td>
                                        <td><b>{{ selectedWithdrawalRequest?.account_number }}</b></td>
                                    </tr>
                                    <tr>
                                        <td>Bank:</td>
                                        <td><b>{{ selectedWithdrawalRequest?.bank?.name }}</b></td>
                                    </tr>
                                    <tr>
                                        <td>Status:</td>
                                        <td v-if="withdrawStatus === 'Pending'" class="text-warning">
                                            {{ withdrawStatus }}
                                        </td>
                                        <td v-else-if="withdrawStatus === 'Approved'" class="text-success">
                                            {{ withdrawStatus }}
                                        </td>
                                        <td v-else-if="withdrawStatus === 'Canceled'" class="text-danger">
                                            Rejected
                                        </td>
                                        <td v-else>
                                            {{ withdrawStatus }}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Withdrawal Date:</td>
                                        <td><b>{{ formatDate(selectedWithdrawalRequest?.created_at) }}</b></td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div class="align-center" v-if="withdrawStatus === 'Pending'" style="text-align: center">
                                <button
                                    class="btn btn-success"
                                    @click="approveOrReject(selectedWithdrawalRequest,'approved')"
                                    :disabled="approving"
                                    style="width: 100%"
                                >
                                    Approve
                                    <i class="fa fa-spin fa-spinner" v-if="approving"></i>
                                </button>
                                <button
                                    class="btn btn-danger mt-2"
                                    @click="approveOrReject(selectedWithdrawalRequest,'reject')"
                                    :disabled="rejecting"
                                    style="width: 100%"
                                >
                                    Reject
                                    <i class="fa fa-spin fa-spinner" v-if="rejecting"></i>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<style>
.page-link {
    color: #00ab4d;
}
.page-link:hover {
    color: #00ab4d;
}
.active>.page-link, .page-link.active {
    z-index: 3;
    color: var(--bs-pagination-active-color);
    background-color: #00ab4d;
    border-color: #00ab4d;
}
</style>
