<script setup>
import {ref} from "vue";
import Swal from "sweetalert2";
import axios from "axios";
import ResendOTP from "../ResendOTP.vue";

const props = defineProps(["type", "type_id", "typeObject", "market", "total_amount", "current_user_id"]);
const childRef = ref();
const errors = ref({});
const sentOtp = ref(false);
const saving = ref(false);

const amount = ref(null);
const otp = ref('');
const approvalotp = ref('');
const withdrawId = ref('');

const setChildRef = (el) => {
    childRef.value = el;
};

function validateAmount() {
    if (!amount.value || amount.value < 1) {
        errors.value = {amount: "Amount is required."};
        return false;
    }

    if (amount.value > props.total_amount) {
        errors.value = {amount: "Cashout amount is greater than wallet balance."};
        return false;
    }

    return true;
}

async function payout() {
    saving.value = true;
    sentOtp.value = true;

    if(!validateAmount()){
        saving.value = false;
        sentOtp.value = false;
        return;
    }

    await axios.post('/apis/v4/saas/cashouts/otp', {
        'type': props.type,
        'amount': amount.value,
        'bank_code': props.typeObject.cashout_bank_code,
        'account_number': props.typeObject.cashout_account_number,
        'account_name': props.typeObject.cashout_account_name,
        'market_id': props.market.id,
        'charge_id': props.typeObject.id,
        'type_creator_id': props.current_user_id,
    }).then(resp => {
        withdrawId.value = resp.data.data.withdrawal_id;
        errors.value = {};
        childRef.value.startTimer();

    }).catch(err => {
        sentOtp.value = false;
        let valError = err.response?.data.errors;
        errors.value = valError ? valError : "";

        if(!valError){
            Swal.fire({
                position: 'top',
                icon: 'error',
                title: 'Error cashing out. Please try again and contact our support team if problem persists.',
                timer: 9000
            });
        }
    }).finally(() => {
        saving.value = false;
    });
}

async function completePayout(){
    saving.value = true;

    await axios.post('/apis/v4/saas/withdrawal-requests', {
        'withdrawal_id': withdrawId.value,
        'otp': otp.value,
        'approval_otp': approvalotp.value,
        'type': props.type,
        'wallet_type': props.type,
        'wallet_id': props.type_id,
        'market_id': props.market.id,
    }).then(resp => {
        Swal.fire({
            text: "Cashout Completed",
            position: "top",
            showConfirmButton: true,
            icon: "success",
        }).then(() => {
            location.reload();
        });

    }).catch(err => {
        let valError = err.response?.data.errors;
        errors.value = valError ? valError : "";

        if(!valError){
            Swal.fire({
                position: 'top',
                icon: 'error',
                title: 'Error completing cashout. Please try again and contact our support team if problem persists.',
                timer: 9000
            });
        }
    }).finally(() => {
        saving.value = false;
    });
}

</script>

<template>
    <form action="">
        <div class="row g-3">
            <div class="col-md-12">
                <!--Label: Amount, Attributes:amount -->
                <div class="form-floating">
                    <input type="number" class="form-control" id="amount" v-model="amount"
                           aria-describedby="amount-help"
                           placeholder="Input amount to cashout"
                           :class="[errors.amount ? 'is-invalid': '',!errors.amount && Object.keys(errors).length > 1 ? 'is-valid': '']"
                           :disabled="sentOtp"
                           required
                    >
                    <div class="invalid-feedback" v-if="errors.amount">
                        {{ errors.amount.toString() }}
                    </div>
                </div>
            </div>
            <div class="col-md-12" v-if="sentOtp">
                <!--Label: Otp, Attributes:otp -->
                <div class="form-floating">
                    <input type="text" class="form-control" id="otp" v-model="otp"
                           aria-describedby="otp-help"
                           placeholder="Input OTP"
                           :class="[errors.otp ? 'is-invalid': '',!errors.otp && Object.keys(errors).length > 1 ? 'is-valid': '']"
                           :required="sentOtp"
                    >
                    <div class="invalid-feedback" v-if="errors.otp">
                        {{ errors.otp.toString() }}
                    </div>
                    <div class="mt-2">
                        <ResendOTP v-if="sentOtp" :send-otp="payout" :ref="setChildRef"/>
                    </div>
                </div>
            </div>
            <div v-if="sentOtp">
                <!--Label: Approval OTP, Attributes:approvalotp -->
                <div class="form-floating">
                    <input type="text" class="form-control" id="approvalotp" v-model="approvalotp"
                           aria-describedby="approvalotp-help"
                           :class="[errors.approval_otp ? 'is-invalid': '',!errors.approval_otp && Object.keys(errors).length > 1 ? 'is-valid': '']"
                           >
                    <label for="approvalotp">Approval OTP</label>
                    <div class="invalid-feedback" v-if="errors.approval_otp">
                        {{ errors.approval_otp.toString() }}
                    </div>
                </div>
            </div>
            <div class="col-md-12">
                <button v-if="!sentOtp"
                        class="btn btn-success"
                        :disabled="saving"
                        id="payoutButton"
                        @click.prevent="payout">
                    Payout
                    <i class="fa fa-spin fa-spinner" v-if="saving"></i>
                </button>
                <button v-else
                        class="btn btn-success"
                        :disabled="saving"
                        id="completePayoutButton"
                        @click.prevent="completePayout">
                    Complete Payout
                    <i class="fa fa-spin fa-spinner" v-if="saving"></i>
                </button>
            </div>
        </div>

    </form>
</template>

<style scoped>

</style>
