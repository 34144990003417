<script setup>
import Accordion from "../MemberCompliance/Verifications/Accordion.vue";
import {onMounted, ref} from "vue";
import CameraCapture from "../../../../userpicture/CameraCapture.vue";
import axios from "axios";
import Swal from "sweetalert2";
import {useAppStore} from "../../../../../store/app";
import Subscription from "../../../subscriptions/Subscription.vue";
import moment from "moment/moment";


const props = defineProps([
    "subscription",
    "leader",
    "market",
    "subscriptions_url",
    "token",
    "verifications",
    "subscription_plan_code",
]);

const app = useAppStore();
const first_name = ref(props.leader.first_name ?? "");
const last_name = ref(props.leader.last_name ?? "");
const date_of_birth = ref(props.leader.dob ?? moment().subtract(18, 'years').format('YYYY-MM-DD'));
const phone_number = ref(props.leader.phone ?? "");
const photoFile = ref("");
const currency = import.meta.env.VITE_LOCALIZATION_COUNTRY_CURRENCY_SIGN;
const subscriptionFee = import.meta.env.VITE_MARKET_MONTHLY_SUBSCRIPTION_FEE;
const country = import.meta.env.VITE_LOCALIZATION_COUNTRY_ABBR;
const rentalAgreementFile = ref("");
const saving = ref(false);
const showForm = ref(false);

/*Form inputs*/
const errors = ref([]);
const bvn = ref("");
const nin = ref("");
const marketAddress = ref("");
const market_city = ref("");
const proof_of_ownership = ref("");
const marketVerification = ref("");
/*Form inputs*/

const submitting = ref(false);

const finance_verifications = ref([]);
const bvnVerification = ref(null);
const krapinVerification = ref(null);
const ninVerification = ref(null);
const confirmLocationVerification = ref(null);
const livePhotoVerification = ref(null);
const callCenterVerification = ref("");

onMounted(() => {
    bvn.value = props.leader.bvn;
    nin.value = props.leader.national_id;
    marketAddress.value = props.market.address;
    market_city.value = props.market.city;
    marketVerification.value = props.market?.verification_status;

    getVerifications();
});

const setPhotoURL = (e) => {
    photoFile.value = e;
};
const submit = () => {
    let formData = new FormData();

    formData.append("bvn", bvn.value);
    formData.append("national_id", nin.value);
    formData.append("kra_pin", "");
    formData.append("market_address", marketAddress.value);
    formData.append("market_city", market_city.value);
    formData.append("utility_upload", rentalAgreementFile.value);
    submitting.value = true;

    axios
        .post("/apis/v4/verifications/market/savedetails", formData)
        .then(() => {
            errors.value = [];
            submitting.value = false;

            if (props.subscription === "Free") {
                planSubscription();
            } else {
                getVerifications();
                qoreIdVerifications();
            }
        })
        .catch((err) => {
            Swal.fire("There has been an error", "error");
            // errors.value = err.response.data.errors;
            submitting.value = false;
        })
        .finally(() => {
            submitting.value = false;
        });
};

const getVerifications = () => {
    axios.get("/apis/v4/saas/verifications").then((resp) => {
        //debugger;
        callCenterVerification.value = resp.data.data?.recent_results?.call_center;
        bvnVerification.value = resp.data.data.recent_results.bvn;
        krapinVerification.value = resp.data.data.recent_results.krapin;
        ninVerification.value = resp.data.data.recent_results.nin;
        confirmLocationVerification.value = resp.data.data.recent_results.address;
        livePhotoVerification.value = resp.data.data.recent_results.face_match;
    });
};

const qoreIdVerifications = () => {
    submitting.value = true;

    const form = new FormData();

    form.append("verification_type", "market");
    form.append("market_id", props.market?.id);
    form.append("user_id", props.leader?.id);

    // bvn-verification-form-append
    if (!bvnVerification.value || bvnVerification.value === "failed") {
        form.append("types[]", "bvn");
        form.append("bvn", bvn.value);
    }

    // nin-verification-form-append
    if (!ninVerification.value || ninVerification.value === "failed") {
        form.append("types[]", "nin");
        form.append("nin", nin.value);
    }

    // face-match-verification-form-append
    if (photoFile.value && (!livePhotoVerification.value || livePhotoVerification.value === "failed")) {
        form.append("types[]", "face_match");
        form.append("face_match[photo]", photoFile.value ? photoFile.value : null);

        form.append("face_match[id_number]", bvn.value);
        form.append("face_match[id_type]", "bvn");
        form.append("face_match[id_number]", nin.value);
        form.append("face_match[id_type]", "nin");
    }

    // address-verification-form-append
    if (!confirmLocationVerification.value || confirmLocationVerification.value === "failed") {
        form.append("types[]", "address");
        form.append("address[lga]", props.market?.l_g_a?.name);
        form.append("address[state]", props.market?.geo_state?.name);
        form.append("address[city]", market_city.value);
        form.append("address[street]", marketAddress.value);
    }

    axios
        .post("/apis/v4/saas/verifications", form)
        .then((resp) => {
            submitting.value = false;

            broadcastListener(resp.data.data);

            let myModalEl = document.querySelector("#thankYou");
            let modal = bootstrap.Modal.getOrCreateInstance(myModalEl);
            modal.toggle();

            getVerifications();
        })
        .catch((err) => {
            Swal.fire(
                `${err.response?.data?.message ?? "There were issues with your core id verification"}`,
                "",
                "error"
            );
        })
        .finally(() => {
        });
};

const broadcastListener = (data) => {
    data.forEach((verification) => {
        Echo.channel("verifications" + verification["id"]).listen(".verification", () => {
            getVerifications();
        });
    });

    marketBroadcastListener(props.market.id);
};

const marketBroadcastListener = (id) => {
    Echo.channel("market.update" + id).listen(".market.update", (e) => {
        marketVerification.value = e.market.verification_status;
    });
};

const subscriptionBroadcastListener = (subscriptionId) => {

    Echo.channel(`subscriptions.${subscriptionId}`)
        .listen(".subscription.updated", (e) => {

            if(e.id === subscriptionId){
                let status = e.status;
                let paymentStatus = e.payment_status;

                bootstrap.Modal.getOrCreateInstance(document.querySelector("#subscriptionModal")).hide();
                bootstrap.Modal.getOrCreateInstance(document.querySelector("#verifyDetailsSaved")).hide();

                if(status === 'active' && paymentStatus === 'paid'){
                    qoreIdVerifications();
                }else{
                    Swal.fire({
                        position: 'top',
                        icon: 'error',
                        title: 'Subscription Unsuccessful',
                        text: 'Subscription to our Monthly Plan was unsuccessful. Please try again or contact support for assistance if problem persists.',
                    }).then(() => {
                        location.reload();
                    })
                }
            }
    });
};

const rentalAgreement = (e) => {
    rentalAgreementFile.value = e.target.files[0];
};

function handleDragOver(e) {
    e.dataTransfer.dropEffect = "copy";
    e.preventDefault();
}

const updateProfile = () => {
    saving.value = true;
    axios
        .post("/apis/v4/editingProfile", {
            first_name: first_name.value,
            last_name: last_name.value,
        })
        .then(() => {
            saving.value = false;
        });
};

const getProgressBarWidth = () => {
    const value = props.market.market_limit?.name.replace(/[^\d.]/g, "");
    const level = parseInt(value);
    const percentage = (level / 8) * 100;
    return isNaN(percentage) ? 0 : percentage;
};

function reload() {
    location.reload();
}

function planSubscription() {
    let myModalEl = document.querySelector("#verifyDetailsSaved");
    let modal = bootstrap.Modal.getOrCreateInstance(myModalEl);
    modal.show();
}

const appStore = useAppStore();
function makePayment() {

    if (appStore.country_abbr === 'ke') {
        bootstrap.Modal.getOrCreateInstance(document.querySelector("#kesubscription")).show();
        return;
    }

    axios
        .post("/apis/v4/saas/ps-subscriptions", {
            plan: 'paid',
            market_id: props.market.id,
            email: props.leader.email ?? 'kennedy.mutisya@coamana.com',
            plan_code: props.subscription_plan_code,
            callback_url: window.location.origin + '/subscribed-plans',
        })
        .then((resp) => {
            let url = resp?.data?.data?.data?.authorization_url;
            let subscriptionId = resp?.data?.data?.data?.subscription_id;

            if (!url) {
                throw new Error("There was an error with the payment gateway");
            }

            $("#subscriptionModal .modal-body").html(
                '<iframe width="100%" height="100%" allowtransparency="true" src="' + url + '"/>'
            );

            subscriptionBroadcastListener(subscriptionId);

            bootstrap.Modal.getOrCreateInstance(document.querySelector("#subscriptionModal")).show();
        })
        .catch((err) => {
            Swal.fire({
                icon: 'error',
                title: `${err.response?.data?.message ?? "There were issues with your core id verification"}`,
            });
        });
}

// Determine the BVN value based on the country
let bvnValue = "";
let ninValue = "";

if (country === "ke") {
    bvnValue = "KRA PIN";
    ninValue = "National ID";
} else {
    bvnValue = "BVN";
    ninValue = "NIN";
}

const updateupgrade = (e) => {
};

</script>

<template>
    <div>
        <div
            v-if="marketVerification == null || marketVerification == ''"
            class="card mb-3"
            style="border-radius: 0.25rem; box-shadow: none">
            <div class="card-body text-white" style="background-color: #1d242e; border-radius: 8px">
                <h4 class="text-white">Get Verified</h4>
                <p>
                    Get verified on Amana Market so other traders and farmers can trust your market and your vendors can
                    access financing options depending on your region.
                </p>
                <p class="text-warning" v-if="subscription === 'Free'">
                    <strong>
                        Please note that verification attracts a quality assurance and management fee of {{
                            currency
                        }}{{ subscriptionFee }} per month
                    </strong>
                </p>

                <button
                    class="btn btn-success btn-view btn-sm tw-rounded tw-font-bold"
                    style="
                        background-color: white !important;
                        color: #1d242e;
                        border-color: transparent;
                        box-shadow: none;
                    "
                    @click="showForm = !showForm">
                    Start Verification
                </button>
            </div>
        </div>
        <div v-else-if="marketVerification === 'partial_verified'">
            <div class="card mb-3 card-pending">
                <div class="card-body">
                    <h4 class="card-title">Market Verification is in Progress</h4>

                    <div class="card" style="border-radius: 2em">
                        <div class="card-body">
                            <div class="d-flex justify-content-between">
                                <div>
                                    <div class="circle-container">
                                        <div class="circle-text">
                                            <p class="small" style="color: #ee8509">0</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="flex-grow-1 align-self-center">
                                    <div class="d-flex">
                                        <div style="width: 100%" class="tw-ml-3 tw-mr-3">
                                            <div class="text-xs-center text-black" id="inprogress">
                                                Trust
                                                {{
                                                    market.market_limit?.name === "None"
                                                        ? "Level 0"
                                                        : market.market_limit?.name
                                                }}
                                            </div>
                                            <div class="progress">
                                                <div
                                                    class="progress-bar"
                                                    role="progressbar"
                                                    :style="{
                                                        width: getProgressBarWidth() + '%',
                                                        backgroundColor: '#ee8509',
                                                        color: 'green',
                                                    }"
                                                    aria-valuenow="90"
                                                    aria-valuemin="0"
                                                    aria-valuemax="100"></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <div class="circle-container">
                                        <div class="circle-text">
                                            <p class="small" style="color: #ee8509">8</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <p>
                        Your market verification is currently in progress. Once verification is complete you will be
                        notified
                    </p>
                    <!-- <div class="card-text">
                        <button
                            class="btn btn-success btn-view btn-sm tw-rounded tw-font-bold"
                            @click="showForm = !showForm">
                            View Status
                        </button>
                    </div> -->
                </div>
            </div>
        </div>
        <div v-else-if="marketVerification === 'verified'">
            <div class="card mb-3" style="background-color: #4b9d30">
                <div class="card-body">
                    <h4 class="card-title">Market is Verified.</h4>
                    <div class="card" style="border-radius: 2em">
                        <div class="card-body">
                            <div class="d-flex justify-content-between">
                                <div>
                                    <div class="circle-container" style="border-color: #4b9d30">
                                        <div class="circle-text">
                                            <p class="small">0</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="flex-grow-1 align-self-center">
                                    <div class="d-flex">
                                        <div style="width: 100%" class="tw-ml-3 tw-mr-3">
                                            <div class="text-xs-center text-black" id="progressId">
                                                Trust
                                                {{
                                                    market.market_limit?.name === "None"
                                                        ? "Level 0"
                                                        : market.market_limit?.name
                                                }}
                                            </div>
                                            <div class="progress">
                                                <div
                                                    class="progress-bar"
                                                    role="progressbar"
                                                    :style="{
                                                        width: getProgressBarWidth() + '%',
                                                        backgroundColor: '#ee8509',
                                                        color: 'green',
                                                    }"
                                                    aria-valuenow="12.5"
                                                    aria-valuemin="0"
                                                    aria-valuemax="100"></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <div class="circle-container" style="border-color: #4b9d30">
                                        <div class="circle-text">
                                            <p class="small">8</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <p class="text-white">
                        Your market is verified and is at
                        <b> {{ market.market_limit?.name === "None" ? "Level 0" : market.market_limit?.name }}</b>
                        and your members have access to
                        <b>{{ market.market_limit?.amount }}</b>
                    </p>
                    <div class="card-text">
                        <button
                            style="color: #4b9d30"
                            class="btn btn-view btn-sm tw-rounded tw-font-bold"
                            @click="showForm = !showForm">
                            View Status
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <div v-else-if="marketVerification === 'Unverified' || marketVerification === 'unverified'">
            <div class="card mb-3" style="background-color: #f0483e">
                <div class="card-body">
                    <h4 class="card-title">Market Verification Failed!</h4>
                    <div class="card" style="border-radius: 2em">
                        <div class="card-body">
                            <div class="d-flex justify-content-between">
                                <div>
                                    <div class="circle-container" style="border-color: #f0483e">
                                        <div class="circle-text">
                                            <p class="small" style="color: #f0483e">0</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="flex-grow-1 align-self-center">
                                    <div class="d-flex">
                                        <div style="width: 100%" class="tw-ml-3 tw-mr-3">
                                            <div class="text-xs-center text-black" id="progressId">Trust Level 0</div>
                                            <div class="progress">
                                                <div
                                                    class="progress-bar"
                                                    role="progressbar"
                                                    style="width: 0%; background-color: #f0483e"
                                                    aria-valuenow="25"
                                                    aria-valuemin="0"
                                                    aria-valuemax="100"></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <div class="circle-container" style="border-color: #f0483e">
                                        <div class="circle-text">
                                            <p class="small" style="color: #f0483e">8</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <p class="text-white">
                        Your market verification has failed. Please confirm your details to be verified
                    </p>
                    <div class="card-text">
                        <button
                            style="color: #f0483e"
                            class="btn btn-view btn-sm tw-rounded tw-font-bold"
                            @click="showForm = !showForm">
                            Update Details
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <div v-if="showForm">
            <Accordion key="callcenter">
                <template #title>
                    <span class="accordion-title poppins-semibold">Market Leader Call Center Verification </span>
                </template>
                <template #status>
                    <div v-if="callCenterVerification === 'queuing'">
                        <div
                            class="tw-inline-block tw-w-32 tw-px-3 tw-py-1 tw-rounded-full tw-bg-yellow-100 tw-text-yellow-800 tw-font-bold tw-text-sm tw-whitespace-nowrap tw-cursor-wait">
                            <i class="fa fa-circle-notch fa-spin fa-fw tw-text-yellow-600"></i>
                            Queueing...
                        </div>
                    </div>
                    <div v-if="callCenterVerification === 'running'">
                        <div
                            class="tw-inline-block tw-w-32 tw-px-3 tw-py-1 tw-rounded-full tw-bg-blue-100 tw-text-blue-800 tw-font-bold tw-text-sm tw-whitespace-nowrap tw-cursor-wait">
                            <i class="fa fa-cog fa-spin fa-fw tw-text-blue-600"></i>
                            Running...
                        </div>
                    </div>
                    <div
                        v-if="
                            callCenterVerification === null ||
                            callCenterVerification === 'On Hold' ||
                            callCenterVerification === 'pending'
                        ">
                        <svg width="34" height="34" viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <rect width="34" height="34" rx="17" fill="#FFF8EB"/>
                            <path
                                d="M21.0834 11.1665V12.9165C21.0834 15.1717 19.2552 16.9998 17.0001 16.9998M12.9167 11.1665V12.9165C12.9167 15.1717 14.7449 16.9998 17.0001 16.9998M17.0001 16.9998C19.2552 16.9998 21.0834 18.828 21.0834 21.0832V22.8332M17.0001 16.9998C14.7449 16.9998 12.9167 18.828 12.9167 21.0832V22.8332"
                                stroke="#D78100"
                                stroke-width="1.5"/>
                            <path
                                d="M12.3334 11.1665H21.6667M21.6667 22.8332H12.3334"
                                stroke="#D78100"
                                stroke-width="1.5"
                                stroke-linecap="round"/>
                        </svg>
                    </div>
                    <div v-if="callCenterVerification === 'success' || callCenterVerification === 'Success'">
                        <svg width="34" height="34" viewBox="0 0 34 34" fill="none">
                            <rect width="34" height="34" rx="17" fill="#41AE49"/>
                            <path
                                d="M12.9166 18.1665L14.9583 20.2082L21.0833 13.7915"
                                stroke="#F8FBF8"
                                stroke-width="1.5"
                                stroke-linecap="round"
                                stroke-linejoin="round"/>
                        </svg>
                    </div>
                    <div v-if="callCenterVerification === 'failed'">
                        <span class="badge badge-pill badge-danger">
                            <svg
                                width="34"
                                height="34"
                                viewBox="0 0 34 34"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg">
                                <rect width="34" height="34" rx="17" fill="#FFF2F0"/>
                                <path
                                    d="M21.0835 12.9165L12.9169 21.0832M12.9169 12.9165L21.0835 21.0832"
                                    stroke="#E2341D"
                                    stroke-width="1.5"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"/>
                            </svg>
                        </span>
                    </div>
                    <div v-else/>
                </template>
                <template #errors></template>
                <div>
                    <p v-if="callCenterVerification === 'InCorrect' || callCenterVerification === 'Failed'" class="text-danger">
                        Call verification failed
                    </p>
                    <div class="alert alert-secondary tw-rounded-2xl" role="alert">
                        <div class="d-flex">
                            <div>
                                <i class="fa-duotone fa-circle-info fa-4x"></i>
                            </div>
                            <div class="flex-grow-1 tw-ml-3 tw-text-black">
                                <p class="tw-antialiased tw-font-bold tw-text-sm">
                                    Call <a class="tw-text-gray-500" :href="`tel:${app.callCenterPhoneNumber}`">
                                    <strong>
                                        {{ app.callCenterPhoneNumber }}
                                    </strong>
                                </a> with your registered number to initiate your verification
                                    or wait for our team to call you on your registered number
                                </p>
                            </div>
                        </div>
                    </div>

                    <p class="tw-text-sm tw-font-semibold tw-text-gray-900">
                        Confirm your information below as provided in your National Id or {{ bvnValue }}
                    </p>
                </div>
                <div class="row gap-3 border-primary border-3">
                    <div class="col-12">
                        <!--Label: First Name, Attributes:first_name -->
                        <div class="form-floating">
                            <input
                                type="text"
                                class="form-control"
                                id="first_name"
                                v-model="first_name"
                                aria-describedby="first_name-help"
                                :class="[
                                errors.first_name ? 'is-invalid' : '',
                                !errors.first_name && Object.keys(errors).length > 1 ? 'is-valid' : '',
                            ]"
                            />
                            <label for="first_name">First Name</label>
                            <div class="invalid-feedback" v-if="errors.first_name">
                                {{ errors.first_name.toString() }}
                            </div>
                        </div>
                    </div>
                    <div class="col-12">
                        <!--Label: Last Name, Attributes:last_name -->
                        <div class="form-floating">
                            <input
                                type="text"
                                class="form-control"
                                id="last_name"
                                v-model="last_name"
                                aria-describedby="last_name-help"
                                :class="[
                                errors.last_name ? 'is-invalid' : '',
                                !errors.last_name && Object.keys(errors).length > 1 ? 'is-valid' : '',
                            ]"
                                placeholder="Last Name"
                                required/>
                            <label for="last_name">Last Name</label>
                            <div class="invalid-feedback" v-if="errors.last_name">
                                {{ errors.last_name.toString() }}
                            </div>
                        </div>
                    </div>
                    <div class="col-12">
                        <template v-if="app.country_abbr === 'ke'">
                            <!--Label: Date of Birth, Attributes:date_of_birth -->
                            <div class="form-floating">
                                <input type="date" class="form-control" id="date_of_birth" v-model="date_of_birth"
                                       aria-describedby="date_of_birth-help"
                                       :class="[errors.date_of_birth ? 'is-invalid': '',!errors.date_of_birth && Object.keys(errors).length > 1 ? 'is-valid': '']"
                                >
                                <label for="date_of_birth">Date of Birth</label>

                                <div class="invalid-feedback" v-if="errors.date_of_birth">
                                    {{ errors.date_of_birth.toString() }}
                                </div>
                            </div>
                        </template>
                    </div>
                    <div class="col-12">
                        <!--Label: Phone Number, Attributes:phone_number -->
                        <div class="form-floating">
                            <input
                                type="text"
                                disabled
                                class="form-control"
                                id="phone_number"
                                v-model="phone_number"
                                aria-describedby="phone_number-help"
                                :class="[
                                errors.phone_number ? 'is-invalid' : '',
                                !errors.phone_number && Object.keys(errors).length > 1 ? 'is-valid' : '',
                            ]"/>
                            <label for="phone_number">Phone Number</label>

                            <div class="invalid-feedback" v-if="errors.phone_number">
                                {{ errors.phone_number.toString() }}
                            </div>
                        </div>
                    </div>
                    <div class="d-flex justify-content-end">
                        <button class="btn btn-outline-secondary poppins-bold mt-3 underline" @click="updateProfile">
                            Save
                            <i class="fa fa-spin fa-spinner" v-if="saving"></i>
                        </button>
                    </div>
                </div>
            </Accordion>

            <!--    BVN Verification    -->
            <Accordion key="bvn">
                <template #title>
                    <span class="accordion-title poppins-semibold"> Market Leader {{ bvnValue }} </span>
                </template>
                <template #status>
                    <div v-if="(errors && errors.bvn)||(errors && errors.kra_pin)">
                        <div
                            class="tw-inline-block tw-w-22 tw-px-3 tw-py-1 tw-rounded-full tw-bg-red-100 tw-text-red-800 tw-font-bold tw-text-sm tw-whitespace-nowrap tw-cursor-wait">
                            <i class="fa fa-hexagon-exclamation fa-beat-fade fa-fw tw-text-red-600"></i>
                            Error
                        </div>
                    </div>
                    <template v-else>
                        <div
                            v-if="(bvnVerification === null || bvnVerification === 'pending') || krapinVerification === null || krapinVerification === 'pending'">
                            <svg
                                width="34"
                                height="34"
                                viewBox="0 0 34 34"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg">
                                <rect width="34" height="34" rx="17" fill="#FFF8EB"/>
                                <path
                                    d="M21.0834 11.1665V12.9165C21.0834 15.1717 19.2552 16.9998 17.0001 16.9998M12.9167 11.1665V12.9165C12.9167 15.1717 14.7449 16.9998 17.0001 16.9998M17.0001 16.9998C19.2552 16.9998 21.0834 18.828 21.0834 21.0832V22.8332M17.0001 16.9998C14.7449 16.9998 12.9167 18.828 12.9167 21.0832V22.8332"
                                    stroke="#D78100"
                                    stroke-width="1.5"/>
                                <path
                                    d="M12.3334 11.1665H21.6667M21.6667 22.8332H12.3334"
                                    stroke="#D78100"
                                    stroke-width="1.5"
                                    stroke-linecap="round"/>
                            </svg>
                        </div>
                        <div v-if="bvnVerification === 'success' || krapinVerification === 'success'">
                            <svg width="34" height="34" viewBox="0 0 34 34" fill="none">
                                <rect width="34" height="34" rx="17" fill="#41AE49"/>
                                <path
                                    d="M12.9166 18.1665L14.9583 20.2082L21.0833 13.7915"
                                    stroke="#F8FBF8"
                                    stroke-width="1.5"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"/>
                            </svg>
                        </div>
                        <div v-if="bvnVerification === 'failed'|| krapinVerification === 'failed'">
                            <span class="badge badge-pill badge-danger">
                                <svg
                                    width="34"
                                    height="34"
                                    viewBox="0 0 34 34"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <rect width="34" height="34" rx="17" fill="#FFF2F0"/>
                                    <path
                                        d="M21.0835 12.9165L12.9169 21.0832M12.9169 12.9165L21.0835 21.0832"
                                        stroke="#E2341D"
                                        stroke-width="1.5"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"/>
                                </svg>
                            </span>
                        </div>
                        <div v-else/>
                    </template>
                </template>
                <template #errors></template>
                <div>
                    <p v-if="bvnVerification === 'failed' || krapinVerification === 'failed'" class="text-danger">
                        {{ bvnValue }} verification failed.
                        Please check your {{ bvnValue }}, update and resubmit with the correct information
                    </p>
                    <div class="mb-2" style="color: #1d242e">Confirm your {{ bvnValue }} below</div>
                    <div class="form-floating mb-3">
                        <input
                            type="text"
                            class="form-control form-control-primary"
                            id="bvn"
                            v-model="bvn"
                            aria-describedby="bvn-help"
                            :class="[
                            errors.bvn ? 'is-invalid' : '',
                            !errors.bvn && Object.keys(errors).length > 1 ? 'is-valid' : '',
                        ]"
                            :disabled="
                            bvnVerification === null || bvnVerification === 'pending' || bvnVerification === 'success'
                        "/>

                        <label for="bvn" class="form-label">{{ bvnValue }}</label>
                    </div>

                    <div class="invalid-feedback" v-if="errors.bvn">
                        {{ errors.bvn.toString() }}
                    </div>
                </div>
            </Accordion>

            <!--    NIN Verification    -->
            <Accordion key="nin">
                <template #title>
                    <span class="accordion-title poppins-semibold"> Market Leader {{ ninValue }} Verification </span>
                </template>
                <template #status>
                    <div v-if="errors.national_id">
                        <div
                            class="tw-inline-block tw-w-22 tw-px-3 tw-py-1 tw-rounded-full tw-bg-red-100 tw-text-red-800 tw-font-bold tw-text-sm tw-whitespace-nowrap tw-cursor-wait">
                            <i class="fa fa-hexagon-exclamation fa-beat-fade fa-fw tw-text-red-600"></i>
                            Error
                        </div>
                    </div>
                    <template v-else>
                        <div v-if="ninVerification === null || ninVerification === 'pending'">
                            <svg
                                width="34"
                                height="34"
                                viewBox="0 0 34 34"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg">
                                <rect width="34" height="34" rx="17" fill="#FFF8EB"/>
                                <path
                                    d="M21.0834 11.1665V12.9165C21.0834 15.1717 19.2552 16.9998 17.0001 16.9998M12.9167 11.1665V12.9165C12.9167 15.1717 14.7449 16.9998 17.0001 16.9998M17.0001 16.9998C19.2552 16.9998 21.0834 18.828 21.0834 21.0832V22.8332M17.0001 16.9998C14.7449 16.9998 12.9167 18.828 12.9167 21.0832V22.8332"
                                    stroke="#D78100"
                                    stroke-width="1.5"/>
                                <path
                                    d="M12.3334 11.1665H21.6667M21.6667 22.8332H12.3334"
                                    stroke="#D78100"
                                    stroke-width="1.5"
                                    stroke-linecap="round"/>
                            </svg>
                        </div>
                        <div v-if="ninVerification === 'success'">
                            <svg width="34" height="34" viewBox="0 0 34 34" fill="none">
                                <rect width="34" height="34" rx="17" fill="#41AE49"/>
                                <path
                                    d="M12.9166 18.1665L14.9583 20.2082L21.0833 13.7915"
                                    stroke="#F8FBF8"
                                    stroke-width="1.5"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"/>
                            </svg>
                        </div>
                        <div v-if="ninVerification === 'failed'">
                            <span class="badge badge-pill badge-danger">
                                <svg
                                    width="34"
                                    height="34"
                                    viewBox="0 0 34 34"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <rect width="34" height="34" rx="17" fill="#FFF2F0"/>
                                    <path
                                        d="M21.0835 12.9165L12.9169 21.0832M12.9169 12.9165L21.0835 21.0832"
                                        stroke="#E2341D"
                                        stroke-width="1.5"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"/>
                                </svg>
                            </span>
                        </div>
                        <div v-else/>
                    </template>
                </template>
                <template #errors></template>
                <!--Label: National Identification, Attributes:nin -->
                <div class="">
                    <p v-if="ninVerification === 'failed'" class="text-danger">
                        {{ ninValue }} verification failed.
                        Please check your {{ ninValue }}, update and resubmit with the correct information
                    </p>
                    <div class="mb-2" style="color: #1d242e">Confirm your {{ ninValue }} below</div>
                    <div class="form-floating">
                        <input
                            type="text"
                            class="form-control"
                            id="nin"
                            v-model="nin"
                            aria-describedby="nin-help"
                            :class="[
                            errors.national_id ? 'is-invalid' : '',
                            !errors.national_id && Object.keys(errors).length > 1 ? 'is-valid' : '',
                        ]"
                            :disabled="ninVerification === null || ninVerification === 'pending' || ninVerification === 'success'
                        "/>
                        <label for="nin" class="form-label">National Identification</label>
                    </div>
                    <div class="invalid-feedback" v-if="errors.national_id">
                        {{ errors.national_id.toString() }}
                    </div>
                </div>
            </Accordion>

            <!--    Live Photo Verification    -->
            <Accordion key="liveVerification">
                <template #title>
                    <span class="accordion-title poppins-semibold"> Market Leader Live Photo </span>
                </template>
                <template #status>
                    <div v-if="livePhotoVerification === null || livePhotoVerification === 'pending'">
                        <svg width="34" height="34" viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <rect width="34" height="34" rx="17" fill="#FFF8EB"/>
                            <path
                                d="M21.0834 11.1665V12.9165C21.0834 15.1717 19.2552 16.9998 17.0001 16.9998M12.9167 11.1665V12.9165C12.9167 15.1717 14.7449 16.9998 17.0001 16.9998M17.0001 16.9998C19.2552 16.9998 21.0834 18.828 21.0834 21.0832V22.8332M17.0001 16.9998C14.7449 16.9998 12.9167 18.828 12.9167 21.0832V22.8332"
                                stroke="#D78100"
                                stroke-width="1.5"/>
                            <path
                                d="M12.3334 11.1665H21.6667M21.6667 22.8332H12.3334"
                                stroke="#D78100"
                                stroke-width="1.5"
                                stroke-linecap="round"/>
                        </svg>
                    </div>
                    <div v-if="livePhotoVerification === 'success'">
                        <svg width="34" height="34" viewBox="0 0 34 34" fill="none">
                            <rect width="34" height="34" rx="17" fill="#41AE49"/>
                            <path
                                d="M12.9166 18.1665L14.9583 20.2082L21.0833 13.7915"
                                stroke="#F8FBF8"
                                stroke-width="1.5"
                                stroke-linecap="round"
                                stroke-linejoin="round"/>
                        </svg>
                    </div>
                    <div v-if="livePhotoVerification === 'failed'">
                        <span class="badge badge-pill badge-danger">
                            <svg
                                width="34"
                                height="34"
                                viewBox="0 0 34 34"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg">
                                <rect width="34" height="34" rx="17" fill="#FFF2F0"/>
                                <path
                                    d="M21.0835 12.9165L12.9169 21.0832M12.9169 12.9165L21.0835 21.0832"
                                    stroke="#E2341D"
                                    stroke-width="1.5"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"/>
                            </svg>
                        </span>
                    </div>
                    <div v-else/>
                </template>
                <template #errors></template>

                <p v-if="livePhotoVerification === 'failed'" class="text-danger">
                    Live verification failed. Please take another photo and resubmit
                </p>
                <CameraCapture
                    @upload-file="setPhotoURL"
                    :uploaded_photo="null"
                    :user_id="leader.id"
                    :disabled_field="
                        livePhotoVerification === null ||
                        livePhotoVerification === 'pending' ||
                        livePhotoVerification === 'success'
                    "></CameraCapture>
            </Accordion>

            <!--    Location Verification    -->
            <Accordion key="location">
                <template #title>
                    <span class="accordion-title poppins-semibold"> Market Address Verification </span>
                </template>
                <template #status>
                    <div v-if="errors.market_address || errors.market_city">
                        <div
                            class="tw-inline-block tw-w-22 tw-px-3 tw-py-1 tw-rounded-full tw-bg-red-100 tw-text-red-800 tw-font-bold tw-text-sm tw-whitespace-nowrap tw-cursor-wait">
                            <i class="fa fa-hexagon-exclamation fa-beat-fade fa-fw tw-text-red-600"></i>
                            Error
                        </div>
                    </div>
                    <template v-else>
                        <div v-if="confirmLocationVerification === null || confirmLocationVerification === 'pending'">
                            <svg
                                width="34"
                                height="34"
                                viewBox="0 0 34 34"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg">
                                <rect width="34" height="34" rx="17" fill="#FFF8EB"/>
                                <path
                                    d="M21.0834 11.1665V12.9165C21.0834 15.1717 19.2552 16.9998 17.0001 16.9998M12.9167 11.1665V12.9165C12.9167 15.1717 14.7449 16.9998 17.0001 16.9998M17.0001 16.9998C19.2552 16.9998 21.0834 18.828 21.0834 21.0832V22.8332M17.0001 16.9998C14.7449 16.9998 12.9167 18.828 12.9167 21.0832V22.8332"
                                    stroke="#D78100"
                                    stroke-width="1.5"/>
                                <path
                                    d="M12.3334 11.1665H21.6667M21.6667 22.8332H12.3334"
                                    stroke="#D78100"
                                    stroke-width="1.5"
                                    stroke-linecap="round"/>
                            </svg>
                        </div>
                        <div v-if="confirmLocationVerification === 'success'">
                            <svg width="34" height="34" viewBox="0 0 34 34" fill="none">
                                <rect width="34" height="34" rx="17" fill="#41AE49"/>
                                <path
                                    d="M12.9166 18.1665L14.9583 20.2082L21.0833 13.7915"
                                    stroke="#F8FBF8"
                                    stroke-width="1.5"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"/>
                            </svg>
                        </div>
                        <div v-if="confirmLocationVerification === 'failed'">
                            <span class="badge badge-pill badge-danger">
                                <svg
                                    width="34"
                                    height="34"
                                    viewBox="0 0 34 34"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <rect width="34" height="34" rx="17" fill="#FFF2F0"/>
                                    <path
                                        d="M21.0835 12.9165L12.9169 21.0832M12.9169 12.9165L21.0835 21.0832"
                                        stroke="#E2341D"
                                        stroke-width="1.5"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"/>
                                </svg>
                            </span>
                        </div>
                        <div v-else/>
                    </template>
                </template>
                <template #errors></template>
                <p v-if="confirmLocationVerification === 'failed'" class="text-danger">
                    Address verification failed. Please confirm your market address and resubmit
                </p>
                <p class="mb-4">
                    Please input the address of your market and expect a phone call for physical verification of your
                    market.
                </p>
                <!--Label: Market Address, Attributes:marketaddress -->
                <div class="form-group">
                    <label for="marketaddress" class="form-label">Address</label>
                    <input
                        type="text"
                        class="form-control"
                        id="marketaddress"
                        v-model="marketAddress"
                        aria-describedby="marketaddress-help"
                        :class="[
                            errors.market_address ? 'is-invalid' : '',
                            !errors.market_address && Object.keys(errors).length > 1 ? 'is-valid' : '',
                        ]"
                        placeholder="Address"
                        required
                        :disabled="
                            confirmLocationVerification === null ||
                            confirmLocationVerification === 'pending' ||
                            confirmLocationVerification === 'success'
                        "/>
                    <div class="invalid-feedback" v-if="errors.market_address">
                        {{ errors.market_address.toString() }}
                    </div>
                </div>

                <!--Label: Market City, Attributes:market_city -->
                <div class="form-group mt-3">
                    <label for="market_city" class="form-label">City</label>
                    <input
                        type="text"
                        class="form-control"
                        id="market_city"
                        v-model="market_city"
                        aria-describedby="market_city-help"
                        :class="[
                            errors.market_city ? 'is-invalid' : '',
                            !errors.market_city && Object.keys(errors).length > 1 ? 'is-valid' : '',
                        ]"
                        placeholder="City"
                        required
                        :disabled="
                            confirmLocationVerification === null ||
                            confirmLocationVerification === 'pending' ||
                            confirmLocationVerification === 'success'
                        "/>
                    <div class="invalid-feedback" v-if="errors.market_city">
                        {{ errors.market_city.toString() }}
                    </div>
                </div>

                <div class="form-group mt-3 drag-target" @dragover.prevent="handleDragOver">
                    <div class="form-group mt-3">
                        <label for="proof_of_ownership" class="form-label"> Market utility bill, lease agreement or
                            picture of the market </label>
                        <input
                            type="file"
                            @change="rentalAgreement"
                            class="form-control"
                            id="proof_of_ownership"
                            aria-describedby="proof_of_ownership-help"
                            :class="[
                                errors.proof_of_ownership ? 'is-invalid' : '',
                                !errors.proof_of_ownership && Object.keys(errors).length > 1 ? 'is-valid' : '',
                            ]"
                            placeholder="Rent Agreement or Proof of Ownership"
                            required
                            :disabled="
                                confirmLocationVerification === null ||
                                confirmLocationVerification === 'pending' ||
                                confirmLocationVerification === 'success'
                            "/>
                        <div class="invalid-feedback" v-if="errors.proof_of_ownership">
                            {{ errors.proof_of_ownership.toString() }}
                        </div>
                    </div>
                </div>
            </Accordion>

            <!--    Submit Button    -->
            <!--    only show button if 1 or more of the verifications failed or not started   -->
            <div
                class="row"
                v-if="
                    !bvnVerification ||
                    bvnVerification === 'failed' ||
                    !ninVerification ||
                    ninVerification === 'failed' ||
                    !livePhotoVerification ||
                    livePhotoVerification === 'failed' ||
                    !confirmLocationVerification ||
                    confirmLocationVerification === 'failed'
                ">
                <div class="d-flex justify-content-end">
                    <!--    disable button if all verifications success/pending   -->
                    <button
                        class="btn btn-success mr-3 mb-3"
                        @click.prevent="submit"
                        :disabled="
                            !(!bvnVerification || bvnVerification === 'failed') &&
                            !(!ninVerification || ninVerification === 'failed') &&
                            !(!livePhotoVerification || livePhotoVerification === 'failed') &&
                            !(!confirmLocationVerification || confirmLocationVerification === 'failed')
                        ">
                        Submit
                        <span class="fa fa-spin fa-spinner" v-if="submitting"></span>
                    </button>
                </div>
            </div>
        </div>
    </div>

    <!-- Modal -->
    <div class="modal fade" id="thankYou" tabindex="-1" role="dialog" aria-labelledby="thankYouId" aria-hidden="true">
        <div class="modal-dialog modal-lg" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h4 class="modal-title" id="thankYouId poppins-bold" style="color: #4b9d30">Thank you!!!</h4>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <p>
                        Your verification has been submitted and you will be notified once it is complete. Please note
                        that verification can take up to 7 days If your verification is not complete in 7 days, please
                        call {{ app.callCenterPhoneNumber }} to confirm your status
                    </p>
                    <button type="button" class="btn btn-success" @click.prevent="reload">Continue</button>
                </div>
            </div>
        </div>
    </div>

    <div
        class="modal fade"
        id="verifyDetailsSaved"
        tabindex="-1"
        role="dialog"
        aria-labelledby="verifyDetailsSaved"
        aria-hidden="true">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <div class="modal-body">
                    <div class="col-12" style="padding: 10px">
                        <h4 class="text-success">Your details have been saved!</h4>
                        <p>
                            In order to get and remain verified on Amana Market, your market needs to pay a monthly
                            quality assurance and management fee.
                        </p>
                        <p>
                            <strong>
                                Quality assurance and management costs {{ currency }} {{ subscriptionFee }} per month.
                            </strong>
                        </p>
                        <button class="btn btn-success" @click.prevent="makePayment">Pay</button>&nbsp;
                        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Pay later</button>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-outline-secondary" data-bs-dismiss="modal">Close</button>
                </div>
            </div>
        </div>
    </div>

    <div
        class="modal fade"
        id="subscriptionModal"
        tabindex="-1"
        role="dialog"
        aria-labelledby="subscriptionModal"
        aria-hidden="true">
        <div class="modal-dialog" role="document" style="height: 70%">
            <div class="modal-content" style="height: 100%">
                <div class="modal-body"></div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-outline-secondary" @click.prevent="reload">Close</button>
                </div>
            </div>
        </div>
    </div>
    <div class="modal fade" id="kesubscription" role="dialog"
         aria-labelledby="kenyaSubscription" aria-hidden="true">
        <div class="modal-dialog modal-fullscreen" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h4 class="modal-title" id="kenyaSubscription">Kenya Subscription </h4>
                    <button type="button" class="close" data-bs-dismiss="modal"
                            aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    <Subscription
                        @done="updateupgrade"
                        :currencysign="appStore.currencySign"
                        :market_id="props.market.id"
                        :email="`info@coamana.com`"
                        :uniqueid="`1243`"
                    />
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">
                        Close
                    </button>
                    <button type="button" class="btn btn-primary">Save</button>
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped>
.poppins-thin {
    font-family: "Poppins", sans-serif;
    font-weight: 100;
    font-style: normal;
}

.poppins-extralight {
    font-family: "Poppins", sans-serif;
    font-weight: 200;
    font-style: normal;
}

.poppins-light {
    font-family: "Poppins", sans-serif;
    font-weight: 300;
    font-style: normal;
}

.poppins-regular {
    font-family: "Poppins", sans-serif;
    font-weight: 400;
    font-style: normal;
}

.poppins-medium {
    font-family: "Poppins", sans-serif;
    font-weight: 500;
    font-style: normal;
}

.poppins-semibold {
    font-family: "Poppins", sans-serif;
    font-weight: 600;
    font-style: normal;
}

.poppins-bold {
    font-family: "Poppins", sans-serif;
    font-weight: 700;
    font-style: normal;
}

.poppins-extrabold {
    font-family: "Poppins", sans-serif;
    font-weight: 800;
    font-style: normal;
}

.poppins-black {
    font-family: "Poppins", sans-serif;
    font-weight: 900;
    font-style: normal;
}

.poppins-thin-italic {
    font-family: "Poppins", sans-serif;
    font-weight: 100;
    font-style: italic;
}

.poppins-extralight-italic {
    font-family: "Poppins", sans-serif;
    font-weight: 200;
    font-style: italic;
}

.poppins-light-italic {
    font-family: "Poppins", sans-serif;
    font-weight: 300;
    font-style: italic;
}

.poppins-regular-italic {
    font-family: "Poppins", sans-serif;
    font-weight: 400;
    font-style: italic;
}

.poppins-medium-italic {
    font-family: "Poppins", sans-serif;
    font-weight: 500;
    font-style: italic;
}

.poppins-semibold-italic {
    font-family: "Poppins", sans-serif;
    font-weight: 600;
    font-style: italic;
}

.poppins-bold-italic {
    font-family: "Poppins", sans-serif;
    font-weight: 700;
    font-style: italic;
}

.poppins-extrabold-italic {
    font-family: "Poppins", sans-serif;
    font-weight: 800;
    font-style: italic;
}

.poppins-black-italic {
    font-family: "Poppins", sans-serif;
    font-weight: 900;
    font-style: italic;
}

.accordion-title {
    color: #1d242e;
}

.card-title {
    color: white;
}

.card-pending {
    background-color: #ee8509;
    color: white;
}

.circle-container {
    width: 75px;
    height: 75px;
    border-radius: 50%;
    border: 2px solid #ee8509;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    font-size: 24px;
    font-weight: bold;
}

.circle-text {
    position: relative;
    top: 5px;
    color: #4b9d30;
}

.btn-view {
    color: #ee8509;
    background-color: white !important;
    border-color: transparent;
}
</style>
