<script setup>
import {onMounted, ref, watch} from "vue";
import Swal from "sweetalert2";
import axios from "axios";
import Multiselect from 'vue-multiselect'
import {useAppStore} from "../../store/app";

const props = defineProps(["members", "market"]);
const emit = defineEmits(['feeCreated']);

const errors = ref({});
const banks = ref(null);

const name = ref("");
const fee_type = ref("");
const amount = ref("");
const members = ref("");
const frequency = ref("");
const description = ref("");
const due_on = ref("");
const custom = ref(false);
const showPhoneNumber = ref(false);
const saving = ref(false);
const phone_number = ref("");
const bank_code = ref("");
const account_number = ref("");
const account_name = ref("");
const currency = import.meta.env.VITE_LOCALIZATION_COUNTRY_CURRENCY_SIGN;

function changeMember() {
    if (members.value === "phone") {
        showPhoneNumber.value = true;
        return;
    }
    showPhoneNumber.value = false;
    phone_number.value = "";
}

const appStore = useAppStore();
const finding = ref(false)
const review = ref(false)
const accountName = ref(false)
const accountFound = ref(false)
const saveDetails = () => {
    errors.value = {};
    finding.value = true;
    accountFound.value = false;
    if(appStore.country_abbr == 'ke'){
        finding.value = false;
        review.value = true;

        return;
    }

    axios
        .post("/apis/v4/bank-account-info", {
            bank: bank_code.value,
            account_number: account_number.value,
            amount_to_withdraw: 0,
            bank_type: 'paystack',
        })
        .then((resp) => {
            finding.value = false;
            let accName = resp.data?.accountDetails?.account_name ?? null;

            if(accName){
                accountFound.value = true;
                accountName.value = accName;
                errors.value = {};
                review.value = true;
            }else{
                errors.value = {account_number: 'Account not found', account_name: ' '};
                accountName.value = 'ACCOUNT NOT FOUND';
                accountFound.value = false;

            }
        })
        .catch((error) => {
            finding.value = false;
            errors.value = error?.response?.data?.errors ?? '';
            Swal.fire({
                text: "We could not verify your account number",
                position: "center",
                showConfirmButton: true,
                showCloseButton: false,
                icon: "danger",
            }).then(() => {
                location.reload();
            });

        })
}

function addFee() {
    saving.value = true;
    axios
        .post("/fee/create", {
            name: name.value,
            phoneNumber: getPhoneNumbersArray(phone_number.value),
            amount: amount.value,
            type: fee_type.value,
            members: members.value,
            frequency: frequency.value,
            description: description.value,
            due_on: due_on.value,
            bank_code: bank_code.value,
            account_number: account_number.value,
            account_name: account_name.value,
        })
        .then((resp) => {
            amount.value = "";
            fee_type.value = "";
            members.value = "";
            frequency.value = "";
            bank_code.value = "";
            account_number.value = "";
            account_name.value = "";
            errors.value = "";
            saving.value = false;

            afterCreateFee();
        })
        .catch((err) => {
            saving.value = false;
            errors.value = err.response.data.errors;
        })
        .finally(() => {
            saving.value = false;
        });
}

async function afterCreateFee(){

    await axios.get(`/apis/v4/saas/user-actions?market_id=${props.market.id}`).then((response) => {
        let actionsAllDone = true;
        let nextActions = response.data.data;

        if(nextActions.length){

            nextActions.forEach((action) => {
                actionsAllDone = actionsAllDone && action.status === 'done';
            });

            if(!actionsAllDone){
                emit('feeCreated')
            }else{
                Swal.fire({
                    text: "Fee Added Successfully",
                    position: "center",
                    showConfirmButton: true,
                    showCloseButton: false,
                    icon: "success",
                }).then(() => {
                    location.reload();
                });
            }
        }
    });
}

const getBanks = () => {
    axios.get('/apis/v4/saas/vfd/banks').then((response) => [
        banks.value = response.data.data
    ]);
}
function getMembers(){
    let options = [];

    props.members.forEach(member => {
        let memberName = member.name + ' (' + member.phone.substring(0,6) + 'xxx)';
        options.push({name: memberName, phone: member.phone});
    });

    return options;
}

function getPhoneNumbersArray(phoneNumbers){
    let numbers = [];

    if(phoneNumbers.length){
        phoneNumbers.forEach(data => {
            numbers.push(data.phone);
        });
    }

    return numbers;
}

onMounted(() => {
    getBanks();
})

</script>

<template>
    <div>
        <form action="" id="createFeeForm">
            <div class="row g-3">
                <div class="col-md-6">
                    <!--Label: Fee Name, Attributes:name -->
                    <div class="form-floating">
                        <input
                            type="text"
                            class="form-control"
                            id="name"
                            v-model="name"
                            aria-describedby="name-help"
                            :class="[
                                errors.name ? 'is-invalid' : '',
                                !errors.name && Object.keys(errors).length > 1
                                    ? 'is-valid'
                                    : '',
                            ]"
                        />
                        <label for="name">Fee Name</label>

                        <div class="invalid-feedback" v-if="errors.name">
                            {{ errors.name.toString() }}
                        </div>
                    </div>
                </div>
                <div class="col-md-6">
                    <!--Label: Fee Type, Attributes:fee_type -->
                    <div class="form-floating">
                        <select
                            class="form-control"
                            id="fee_type"
                            v-model="fee_type"
                            aria-describedby="fee_type-help"
                            :class="[
                                errors.type ? 'is-invalid' : '',
                                !errors.type &&
                                Object.keys(errors).length > 1
                                    ? 'is-valid'
                                    : '',
                            ]"
                        >
                            <option name="" id=""></option>
                            <option name="Capturing promotional content (e.g. videos/photos)">
                                Capturing promotional content (e.g. videos/photos)
                            </option>
                            <option name="Consultation with market management">
                                Consultation with market management
                            </option>
                            <option name="Contributions">Contributions</option>
                            <option name="Floor space fees">
                                Floor space fees
                            </option>
                            <option name="Insurance contributions">
                                Insurance contributions
                            </option>
                            <option name="Loading">Loading</option>
                            <option value="Market Insurance Contribution">
                                Market Insurance Contributions
                            </option>
                            <option name="Membership fees">
                                Membership fees
                            </option>
                            <option name="Non residence membership">
                                Non residence membership
                            </option>
                            <option name="Offloading">Offloading</option>
                            <option name="Parking">Parking</option>
                            <option name="Permit">Permit</option>
                            <option name="Procurement permit">
                                Procurement permit
                            </option>
                            <option name="Promotional event">
                                Promotional event
                            </option>
                            <option name="Promotional materials">
                                Promotional materials
                            </option>
                            <option name="Rental">Rental</option>
                            <option name="Research and interviews">
                                Research and interviews
                            </option>
                            <option name="Service charge">
                                Service charge
                            </option>
                            <option name="Sign post display">
                                Sign post display
                            </option>
                            <option name="Supply permit">Supply permit</option>
                            <option name="Tax" id="">Tax</option>
                            <option name="Other - Entry field to specify">
                                Other - Entry field to specify
                            </option>
                        </select>
                        <label for="fee_type">Fee Type</label>

                        <div class="invalid-feedback" v-if="errors.type">
                            {{ errors.type.toString() }}
                        </div>
                    </div>
                </div>
                <div class="col-md-6">
                    <!--Label: Description, Attributes:description -->
                    <div class="form-floating">
                        <textarea
                            type="text"
                            class="form-control"
                            id="description"
                            v-model="description"
                            aria-describedby="description-help"
                            :class="[
                                errors.description ? 'is-invalid' : '',
                                !errors.description &&
                                Object.keys(errors).length > 1
                                    ? 'is-valid'
                                    : '',
                            ]"
                        >
                        </textarea>
                        <label for="description">Description</label>

                        <div class="invalid-feedback" v-if="errors.description">
                            {{ errors.description.toString() }}
                        </div>
                    </div>
                </div>
                <div class="col-md-6">
                    <!--Label: Amount, Attributes:amount -->
                    <div class="form-floating">
                        <input
                            type="number"
                            class="form-control"
                            id="amount"
                            v-model="amount"
                            aria-describedby="amount-help"
                            :class="[
                                errors.amount ? 'is-invalid' : '',
                                !errors.amount && Object.keys(errors).length > 1
                                    ? 'is-valid'
                                    : '',
                            ]"
                        />
                        <label for="amount">Amount {{ currency }}</label>
                        <div class="invalid-feedback" v-if="errors.amount">
                            {{ errors.amount.toString() }}
                        </div>
                    </div>
                </div>
                <div class="col-md-6">
                    <!--Label: Due On, Attributes:due_on -->
                    <div class="form-floating">
                        <input
                            type="date"
                            class="form-control"
                            id="due_on"
                            v-model="due_on"
                            aria-describedby="due_on-help"
                            :class="[
                                errors.due_on ? 'is-invalid' : '',
                                !errors.due_on && Object.keys(errors).length > 1
                                    ? 'is-valid'
                                    : '',
                            ]"
                        />
                        <label for="due_on">Due On</label>

                        <div class="invalid-feedback" v-if="errors.due_on">
                            {{ errors.due_on.toString() }}
                        </div>
                    </div>
                </div>
                <div class="col-md-6">
                    <!--Label: Frequency, Attributes:frequency -->
                    <div class="form-floating">
                        <select
                            class="form-select"
                            id="frequency"
                            v-model="frequency"
                            aria-describedby="frequency-help"
                            :class="[
                                errors.frequency ? 'is-invalid' : '',
                                !errors.frequency &&
                                Object.keys(errors).length > 1
                                    ? 'is-valid'
                                    : '',
                            ]"
                        >
                            <option value=""></option>
                            <option value="One Time Payment">One Time Payment</option>
                            <option value="daily">Daily</option>
                            <option value="Weekly">Weekly</option>
                            <option value="Bi-Weekly">Bi-Weekly</option>
                            <option value="Monthly">Monthly</option>
                            <option value="Quarterly">Quarterly</option>
                            <option value="annually">
                                Annually from first date paid
                            </option>
                            <option value="Annually">Annually</option>
                            <option value="First Day of Month">
                                First Day of Month
                            </option>
                            <option value="First Day of Year">
                                First Day of Year
                            </option>
                            <option value="First Day of Quarter">
                                First Day of Quarter
                            </option>
                        </select>
                        <label for="frequency">Frequency</label>

                        <div class="invalid-feedback" v-if="errors.frequency">
                            {{ errors.frequency.toString() }}
                        </div>
                    </div>
                </div>
                <div class="col-md-6">
                    <!--Label: Select Members, Attributes:members -->
                    <div class="form-floating">
                        <select
                            class="form-select"
                            id="members"
                            v-model="members"
                            aria-describedby="members-help"
                            @change="changeMember"
                            :class="[
                                errors.members ? 'is-invalid' : '',
                                !errors.members &&
                                Object.keys(errors).length > 1
                                    ? 'is-valid'
                                    : '',
                            ]"
                        >
                            <option value="" disabled>Select Option</option>
                            <option value="all">All Members</option>
                            <option value="phone">Selected Members</option>
                        </select>

                        <label for="members">Fee For</label>

                        <div class="invalid-feedback" v-if="errors.members">
                            {{ errors.members.toString() }}
                        </div>
                    </div>
                </div>
                <template v-if="showPhoneNumber">
                    <div class="col-md-6">
                        <!--Label: Phone Number, Attributes:phone_number -->
                        <div class="form-floating">
                            <multiselect
                                v-model="phone_number"
                                :options="getMembers()"
                                :multiple="true"
                                :close-on-select="false"
                                select-label="select"
                                deselect-label="remove"
                                placeholder="Select Member"
                                label="name"
                                track-by="name"
                                :class="[
                                    errors.phoneNumber ? 'is-invalid' : '',
                                    !errors.phoneNumber && Object.keys(errors).length > 1 ? 'is-valid' : '',
                                ]"
                            >
                                <template #noResult>
                                    <span class="text-danger">Oops! Member not found.</span>
                                </template>
                            </multiselect>
                            <div
                                class="invalid-feedback"
                                v-if="errors.phoneNumber"
                            >
                                {{ errors.phoneNumber.toString() }}
                            </div>
                        </div>
                    </div>
                </template>
                <template v-else>
                    <div class="col-md-6"></div>
                </template>
                <div class="col-md-6">
                    <!--Label: Select Bank, Attributes:bank_code -->
                    <div class="form-floating">
                        <select
                            class="form-select"
                            id="bank_code"
                            :disabled="accountFound"
                            v-model="bank_code"
                            aria-describedby="bank_code-help"
                            :class="[
                                errors.bank_code ? 'is-invalid' : '',
                                !errors.bank_code &&
                                Object.keys(errors).length > 1
                                    ? 'is-valid'
                                    : '',
                            ]"
                        >
                            <option value=""></option>
                            <option v-for="bank in banks" :value="bank.id">{{ bank.name }}</option>
                        </select>

                        <label for="bank_code">Cashout Bank</label>
                        <div class="invalid-feedback" v-if="errors.bank_code">
                            {{ errors.bank_code.toString() }}
                        </div>
                    </div>
                </div>
                <div class="col-md-6">
                    <!--Label: Account Name, Attributes:account_name -->
                    <div class="form-floating">
                        <input
                            type="text"
                            class="form-control"
                            id="account_name"
                            :disabled="accountFound"
                            v-model="account_name"
                            aria-describedby="account_name-help"
                            :class="[
                                errors.account_name ? 'is-invalid' : '',
                                !errors.account_name && Object.keys(errors).length > 1
                                    ? 'is-valid'
                                    : '',
                            ]"
                        />
                        <label for="account_name">Cashout Account Name</label>
                        <div class="invalid-feedback" v-if="errors.account_name">
                            {{ errors.account_name.toString() }}
                        </div>
                    </div>
                </div>
                <div class="col-md-6">
                    <!--Label: Account Number, Attributes:account_number -->
                    <div class="form-floating">
                        <input
                            type="text"
                            :disabled="accountFound"
                            class="form-control"
                            id="account_number"
                            v-model="account_number"
                            aria-describedby="account_number-help"
                            :class="[
                                errors.account_number ? 'is-invalid' : '',
                                !errors.account_number && Object.keys(errors).length > 1
                                    ? 'is-valid'
                                    : '',
                            ]"
                        />
                        <label for="account_number">Cashout Account Number</label>
                        <div class="invalid-feedback" v-if="errors.account_number">
                            {{ errors.account_number.toString() }}
                        </div>
                    </div>
                </div>
                <div class="col-md-12" v-if="accountFound">
                    <div class="alert alert-secondary" role="alert">
                        <strong>Your account information has been verified.</strong>
                        <div class="table-responsive">
                            <table class="table">
                                <tbody>
                                <tr>
                                    <th>Account Name:</th>
                                    <th>{{ accountName }}</th>
                                </tr>
                                <tr>
                                    <th>Account Number:</th>
                                    <th>{{ account_number}}</th>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                <div class="col-md-12 d-flex justify-content-end">
                    <button
                        v-if="appStore.country_abbr == 'ke' && accountFound"
                        class="btn btn-outline-success"
                        :disabled="saving"
                        @click.prevent="addFee"
                    >
                        Create Fee
                        <i v-if="saving" class="fa fa-spin fa-spinner"></i>
                        <i v-else class="fa fa-plus-circle"></i>
                    </button>
                    <button
                        v-else-if="appStore.country_abbr == 'ng' && accountFound"
                        class="btn btn-outline-success"
                        :disabled="saving"
                        @click.prevent="addFee"
                    >
                        Create Fee
                        <i v-if="saving" class="fa fa-spin fa-spinner"></i>
                        <i v-else class="fa fa-plus-circle"></i>
                    </button>
                    <button v-else type="button" class="btn btn-primary" @click.prevent="saveDetails">
                        Find Account
                        <i class="fa fa-spin fa-spinner" v-if="finding"></i>
                    </button>
                </div>
            </div>
        </form>
    </div>
</template>

<style src="vue-multiselect/dist/vue-multiselect.css"></style>
<style scoped>
>>> .multiselect__tags {
    min-height: 58px !important;
    border: 1px solid #ced4da !important;
}

</style>
