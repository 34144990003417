<script setup>
import {ref} from "vue";

const props = defineProps(['transaction_id'])
const order_id = ref('');
const orders = ref([]);
const hasMadeSearch = ref(false);
const searchForOrders = () => {
    axios.get(`/apis/v4/mpesa/orders?filter[order_id]=${order_id.value}`)
        .then((resp) => {
            orders.value = resp.data.data;
            hasMadeSearch.value =true
        });
}
const linkOrder = (order) => {
    axios.post(`/apis/v4/mpesa/orders`, {
        'order_id': order,
        'transaction_id': props.transaction_id
    })
        .then((resp) => {
            searchForOrders()
        });
}
</script>

<template>
    <div>
        <form>
            <div class="input-group mb-3">
                <input type="text" id="orderSearchInput" class="form-control"
                       v-model="order_id"
                       required
                       placeholder="Enter order ID or phone number">
                <div class="input-group-append">
                    <button class="btn btn-primary" type="button" @click.prevent="searchForOrders">Search</button>
                </div>
            </div>
        </form>
        <div class="table-responsive">
            <table class="table table-striped table-hover">
                <thead class="thead-light">
                <tr>
                    <th scope="col">Order ID</th>
                    <th scope="col">Customer Name</th>
                    <th scope="col">Order Date</th>
                    <th scope="col">Total Amount</th>
                    <th scope="col">Action</th>
                </tr>

                </thead>
                <tbody id="ordersTableBody">
                <tr v-if="hasMadeSearch && Object.keys(orders).length < 1">
                    <td colspan="5">
                        <button type="button"
                                class="tw-relative tw-block tw-w-full tw-rounded-lg tw-border-2 tw-border-dashed tw-border-gray-300 tw-p-12 tw-text-center hover:tw-border-gray-400 focus:tw-outline-none focus:tw-ring-2 focus:tw-ring-indigo-500 focus:tw-ring-offset-2">
                            <svg class="tw-mx-auto tw-h-12 tw-w-12 tw-text-gray-400" stroke="currentColor" fill="none"
                                 viewBox="0 0 48 48" aria-hidden="true">
                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                                      d="M8 14v20c0 4.418 7.163 8 16 8 1.381 0 2.721-.087 4-.252M8 14c0 4.418 7.163 8 16 8s16-3.582 16-8M8 14c0-4.418 7.163-8 16-8s16 3.582 16 8m0 0v14m0-4c0 4.418-7.163 8-16 8S8 28.418 8 24m32 10v6m0 0v6m0-6h6m-6 0h-6"/>
                            </svg>
                            <span class="tw-mt-2 tw-block tw-text-sm tw-font-semibold tw-text-gray-900">We could not find an order with the search criteria provided.</span>
                        </button>
                    </td>
                </tr>
                <tr v-for="order in orders">
                    <td>{{ order?.order_id }}</td>
                    <td>{{ order?.buyer?.name }}</td>
                    <td>{{ order?.created_at }}</td>
                    <td>{{ order?.items_price }}</td>
                    <td>
                        <a class="btn btn-success btn-sm" @click.prevent.once="linkOrder(order?.id)">Link Order</a>
                    </td>
                </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>

<style scoped>

</style>
