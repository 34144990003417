<script setup>
import {ref} from "vue";

const checkingStatus = ref(false);
const hasNoResults = ref(false);
const checkTransactionStatus = () => {
    errors.value = [];
    transaction.value = [];
    checkingStatus.value = true;
    axios.post('/apis/v4/jenga/checktransaction/status', {
        transactionId: transactionId.value
    }).then((data) => {
        transaction.value = data.data.data;
        hasNoResults.value = data.data.status == false
        transactionId.value = '';
        checkingStatus.value = false;
    }).catch((err) => {
        errors.value = err.response.data.errors;
        checkingStatus.value = false;
    });
}
const transactionId = ref('')
const error = ref('')
const errors = ref('')
const transaction = ref('')
</script>

<template>
    <div>
        <form>
            <div class="col-12">
                <!--Label: Reference Number, Attributes:transactionId -->
                <div class="form-floating">
                    <input type="text" class="form-control" id="transactionId" v-model="transactionId"
                           aria-describedby="transactionId-help"
                           :class="[errors.transactionId ? 'is-invalid': '',!errors.transactionId && Object.keys(errors).length > 1 ? 'is-valid': '']"
                    >
                    <label for="transactionId">Reference Number</label>

                    <div class="invalid-feedback" v-if="errors.transactionId">
                        {{ errors.transactionId.toString() }}
                    </div>
                </div>
            </div>
            <div class="col-12 mt-3">
                <div class="d-flex justify-content-end">
                    <button @click.prevent="checkTransactionStatus" class="btn btn-outline-primary">
                        Check Status
                        <i class="fa fa-spin fa-spinner" v-if="checkingStatus"></i>
                        <i class="fas fa-check-circle" v-else></i>
                    </button>
                </div>
            </div>
        </form>
        <div v-if="transaction" class="mt-4">
            <div class="alert alert-success" role="alert">
                <h4 class="alert-heading">Transaction Success!</h4>
                <p>Status: <strong>{{ transaction.state }}</strong></p>
            </div>
            <ul class="list-group mb-4">
                <li class="list-group-item">
                    <strong>Transaction Reference:</strong> {{ transaction.transactionReference }}
                </li>
                <li class="list-group-item">
                    <strong>Amount:</strong> {{ transaction.amount }} KES
                </li>
                <li class="list-group-item">
                    <strong>Charge:</strong> {{ transaction.charge }} KES
                </li>
                <li class="list-group-item">
                    <strong>Service Name:</strong> {{ transaction.serviceName }}
                </li>
                <li class="list-group-item">
                    <strong>Date Created:</strong> {{ transaction.dateCreated }}
                </li>
            </ul>
            <h5 class="mb-3">Mobile Money Information</h5>
            <ul class="list-group">
                <li class="list-group-item">
                    <strong>Wallet Type:</strong> {{ transaction?.mobileMoneyInfo?.WalletType }}
                </li>
                <li class="list-group-item">
                    <strong>Response Message:</strong> {{ transaction?.mobileMoneyInfo?.ResponseMessage }}
                </li>
                <li class="list-group-item">
                    <strong>Third Party Transaction ID:</strong> {{ transaction?.mobileMoneyInfo?.ThirdPartyTranID }}
                </li>
                <li class="list-group-item">
                    <strong>Receiver Name:</strong> {{ transaction?.mobileMoneyInfo?.ReceiverName }}
                </li>
                <li class="list-group-item">
                    <strong>Receiver MSISDN:</strong> {{ transaction?.mobileMoneyInfo?.ReceiverMsisdn }}
                </li>
                <li class="list-group-item">
                    <strong>Response Description:</strong> {{ transaction?.mobileMoneyInfo?.ResponseDescription }}
                </li>
            </ul>
        </div>
        <div v-if="hasNoResults">
            <div class="tw-flex tw-flex-col tw-items-center tw-justify-center tw-bg-white tw-border tw-border-gray-200 tw-rounded-lg tw-shadow-sm tw-p-8 tw-max-w-sm tw-mx-auto">
                <div class="tw-bg-gray-100 tw-rounded-full tw-p-3 tw-mb-4">
                </div>
                <h3 class="tw-text-lg tw-font-semibold tw-text-gray-900 tw-mb-2">No items found</h3>
                <p class="tw-text-sm tw-text-gray-500 tw-text-center tw-mb-6">
                   Transaction could not be found
                </p>

            </div>
        </div>
        <div v-if="error" class="mt-4">
            <div class="alert alert-danger" role="alert">
                <strong>Error:</strong> {{ error }}
            </div>
        </div>
    </div>
</template>
