<script setup>
import SearchforSeller from "../wallet/payments/searchforSeller.vue";
import Stepper from "bs-stepper";
import {onMounted, ref} from "vue";
import PaymentPurpose from "../wallet/payments/PaymentPurpose.vue";
import PaymentSummary from "../wallet/payments/PaymentSummary.vue";
import SendOTPForPayment from "../wallet/payments/sendOTPForPayment.vue";
import moment from "moment";
import InlineTopUp from "../wallet/payments/inlinetopup/InlineTopUp.vue";
import ResendOTP from "../ResendOTP.vue";
import Swal from "sweetalert2";
import {useAuthStore} from "../../store/auth";
import {useMakeOrderStore} from "../../store/makeorder";
import axios from "axios";
import GetCover from "./GetCover.vue";

const app = useAuthStore();
const orderStore = useMakeOrderStore();
const props = defineProps([
    "borrowinglimit_clear",
    "balance_clear",
    "buyer_group_id",
    "market",
    "productstock",
    "members",
    "auth_id",
    "virtual_bank_account",
    "overdue_charge",
]);

const seller = ref({});
const user_id = ref(null);
const product = ref({});
const payment = ref("");
const amount = ref("");
const quantity = ref("");
const agentFee = ref(0);
const paymentsummary = ref("");
const inlineTopUp = ref({});
const paymentMethod = ref("");
const makePayment = ref(true);
const sendOTPToUser = ref(false);
const finishing = ref(false);
const showSummary = ref(false);
const numberofdays = ref("");
const selectedmethod = ref("");
const invalidDaysToPay = ref(false);
const otp = ref(false);
const errors = ref({});
const currency = import.meta.env.VITE_LOCALIZATION_COUNTRY_CURRENCY_SIGN;
const borrowingLimitNew = ref(0);

const paymentTotal = ref(0);
const walletBalance = ref(0);
const searchForSellerRef = ref("");
const toppedUpAmount = ref(0);
const amountToTopUp = ref(0);

const stepper1 = ref(null);
const childRef = ref();
const isAgentOrder = ref(false);
const shouldSendOtp = ref(false);

const setChildRef = (el) => {
    childRef.value = el;
};

function handleNextStepForAmount() {
    if (amount.value === "") {
        payment.value.productsRef.setAmountError("Please enter amount");
        return;
    }
    stepperNextPayment();
}

function changeUserWhoIsLoggedIn(data) {
    user_id.value = data;
    isAgentOrder.value = !!user_id.value;
}

function walletToppedUp() {
    paymentsummary.value.updateBalance(toppedUpAmount.value);
    walletBalance.value = walletBalance.value + toppedUpAmount.value;

    let myModalEl = document.querySelector("#topupInline");
    let modal = bootstrap.Modal.getOrCreateInstance(myModalEl);

    if (walletBalance.value < paymentTotal.value) {
        Swal.fire({
            position: "top",
            icon: "warning",
            title: "Topped up amount isn't sufficient to complete order. Please top up your wallet and try again.",
            timer: 9000,
        }).then(() => {
            location.href = "/four";
        });
    } else {
        modal.hide();

        makeOrder(true);
    }
}

defineExpose({
    walletToppedUp,
});

function updateTopUpAmount() {
    amountToTopUp.value = parseInt(paymentTotal.value) - parseInt(props.balance_clear);
    let otpUserId = props.auth_id;

    if (['buyer_wallet', 'buyer_credit'].includes(selectedmethod.value)) {
        otpUserId = orderStore.buyer.id;
    }

    axios
        .post("/apis/v4/otp/validate", {
            otp: otp.value,
            user_id: otpUserId,
            key: 'order_make_payment',
        })
        .then(() => {
            let myModalEl = document.querySelector("#topupInline");
            let modal = bootstrap.Modal.getOrCreateInstance(myModalEl);

            modal.show();
        })
        .catch((err) => {
            errors.value = err?.response?.data?.errors ?? '';
        });
}

function calculateDays(days) {
    return moment().add(days, "days");
}

function setSeller(data) {
    seller.value = data;
    payment.value.purpose = "";
    payment.value.products = {};
    payment.value.productsRef.userid = data.id;
    payment.value.productsRef.getItems();
    // goToNextPage();
}

function previous() {
    // debugger;
    stepper1.value.previous();
    childRef.value?.stopTimer();
}

function previousWithReset() {
    // debugger;
    key.value++;
    stepper1.value.previous();
}

function setProduct(data) {
    product.value = data;
    payment.value.productsRef.userid = data.id;
    payment.value.productsRef.selectProduct(data);
    // payment.value.productsRef.value.getItems()
    goToNextPage();
}

function goToNextPage() {
    stepper1.value.next();
}

function payments(enabled, method) {
    method = (method === 'buyer_wallet') ? 'wallet' : method;
    method = (method === 'buyer_credit') ? 'credit' : method;

    makePayment.value = enabled;
    paymentMethod.value = method;
}

onMounted(() => {
    walletBalance.value = props.balance_clear;
    borrowingLimitNew.value = props.borrowinglimit_clear;
    paymentsummary.value.updateCreditBalance(props.borrowinglimit_clear);

    if (props.productstock) {
        orderStore.selectedProduct = props.productstock.product;
        orderStore.seller = props.productstock.member;
        searchForSellerRef.value.updateSearch(
            props.productstock.member.phone,
            props.productstock,
        );
    }
    stepper1.value = new Stepper(document.querySelector("#stepper1"));
});

function totalBilled(amount) {
    paymentTotal.value = amount;
}

async function handleUserOverduePayments(buyerId) {

    await axios.get(`/apis/v4/saas/users/${buyerId}/upcoming-payments/overdue`)
        .then(response => {
            let payments = response?.data?.data ?? [];

            if (payments.length > 0) {
                sendOTPToUser.value = shouldSendOtp.value = false;
                let htmlRows = '';

                $.each(payments, function (index, payment) {
                    htmlRows += `
                            <tr>
                                <td>${index + 1}</td>
                                <td>${currency}${payment.amount.toLocaleString()}</td>
                                <td>${payment.payable_type}</td>
                                <td>${moment(payment.upcoming_at).format('DD MMM YYYY')}</td>
                            </tr>
                        `;
                });

                Swal.fire({
                    title: `<h5>You have ${payments.length} overdue payment(s). Settle payment(s) before making a new order.</h5>`,
                    icon: "error",
                    position: "top-center",
                    html: `
                            <table class="table table-striped table-bordered">
                                <tr>
                                    <th>#</th>
                                    <th>Amount</th>
                                    <th>Type</th>
                                    <th>Due Date</th>
                                </tr>`
                        + htmlRows + `
                            </table>
                        `,
                }).then(() => {
                    location.href = "/four";
                });
            }

        }).catch(function () {
            sendOTPToUser.value = shouldSendOtp.value = false;

            Swal.fire({
                position: "top-center",
                icon: "error",
                title: "<h5>Error while checking if user can continue with order. Please try again.</h5>",
                timer: 9000,
            }).then(() => {
                location.reload()
            });
        });
}

async function sendOTP() {
    sendOTPToUser.value = shouldSendOtp.value = true;
    let otpUserId = props.auth_id;
    let buyerId = orderStore.buyer.id;

    let strAmount = currency + amount.value.toLocaleString();
    let fromWhere = 'from your wallet';
    let removedFrom = 'wallet';

    if (['buyer_wallet', 'buyer_credit'].includes(selectedmethod.value)) {
        otpUserId = buyerId;
    }

    if (paymentMethod.value === 'credit') {
        fromWhere = 'on credit';
        removedFrom = 'limit';

        // sendOTPToUser & shouldSendOtp will be set to false if there are overdue payments
        await handleUserOverduePayments(buyerId);
    }

    if (shouldSendOtp.value) {
        await axios.post('/apis/v4/otp/send', {
            user_id: otpUserId,
            message: `You are about to pay ${strAmount} ${fromWhere}. ${strAmount} will be removed from your ${removedFrom}. To accept, enter this code {otp}.`,
            key: 'order_make_payment',
        }).then(function () {
            sendOTPToUser.value = true;
            childRef.value.startTimer();
        }).catch(function () {
            sendOTPToUser.value = false;
        });
    }
}

function makeOrder(otpValidated = false) {
    finishing.value = true;
    let otpUserId = props.auth_id;

    if (['buyer_wallet', 'buyer_credit'].includes(selectedmethod.value)) {
        otpUserId = orderStore.buyer.id;
    }

    if (otpValidated) {
        completeMakingOrder();
        return;
    }

    axios.post('/apis/v4/otp/validate', {
        otp: otp.value,
        user_id: otpUserId,
        key: 'order_make_payment',
    }).then(() => {
        completeMakingOrder();
    }).catch((error) => {
        finishing.value = false;
        errors.value = error?.response?.data?.errors ?? '';
    });
}

const createdOrder = ref('');

function completeMakingOrder() {
    let buyer = orderStore.buyer;

    axios
        .post("/api/paywithwallet/makeorder", {
            product: product.value,
            quantity: quantity.value ?? 1,
            amount: amount.value,
            seller: seller.value,
            user_id: user_id.value ? user_id.value : buyer.id,
            deduct_user_id: ['buyer_wallet', 'buyer_credit'].includes(selectedmethod.value) ? buyer.id : null,
            buyer_group_id: props.buyer_group_id,
            paymentMethod: paymentMethod.value,
            number_of_days: numberofdays.value,
            total: paymentTotal.value,
            auth_id: props.auth_id,
            agent_id: isAgentOrder.value ? props.auth_id ?? null : null,
            agent_fee: isAgentOrder.value ? agentFee.value : null,
            overdue_charge: props.overdue_charge,
            platform: 'amana-market',
        })
        .then((resp) => {
            errors.value = {};
            finishing.value = false;
            showSummary.value = true;
            showSummary.value = true;
            createdOrder.value = resp.data;
            // location.reload();
        })
        .catch((err) => {
            finishing.value = false;
            let valError = err.response.data.errors;
            errors.value = valError ? valError : "";

            Swal.fire({
                position: "top",
                icon: "error",
                title: err.response?.data?.message,
                timer: 9000,
            });
        });
}

function productset(productData) {
    product.value = productData;
}

function stepperNext() {
    stepper1.value.next();
}

function stepperNextPayment() {
    stepper1.value.next();
}

function stepperNextOTP() {
    sendOTP();
    stepper1.value.next();
}

function buyerWalletBalance() {
    return orderStore.buyer?.wallet?.current_balance ?? 0;
}

function buyerCreditBalance() {
    return orderStore.buyer?.agent_capital?.balance ?? 0;
}

const key = ref(0);
</script>
<template>
    <div>
        <div id="stepper1" class="bs-stepper" v-if="!showSummary">
            <div class="card">
                <div class="card-header">
                    <div
                        class="d-lg-flex flex-lg-row align-items-lg-center justify-content-lg-between"
                        role="tablist"
                        style="display: none !important"
                    >
                        <div class="step" data-target="#test-l-1">
                            <div
                                class="step-trigger"
                                role="tab"
                                id="stepper1trigger1"
                                aria-controls="test-l-1"
                            >
                                <div class="bs-stepper-circle">1</div>
                                <div class="">
                                    <h5 class="mb-0 steper-title">Seller Information</h5>
                                    <p class="mb-0 steper-sub-title">Enter the sellers phone number.</p>
                                </div>
                            </div>
                        </div>
                        <div class="bs-stepper-line"></div>
                        <div class="step" data-target="#test-l-2">
                            <div
                                class="step-trigger"
                                role="tab"
                                id="stepper1trigger2"
                                aria-controls="test-l-2"
                            >
                                <div class="bs-stepper-circle">2</div>
                                <div class="">
                                    <h5 class="mb-0 steper-title">Product Details</h5>
                                    <p class="mb-0 steper-sub-title">Choose a product.</p>
                                </div>
                            </div>
                        </div>
                        <div class="bs-stepper-line"></div>
                        <div class="step" data-target="#test-l-3">
                            <div
                                class="step-trigger"
                                role="tab"
                                id="stepper1trigger3"
                                aria-controls="test-l-3"
                            >
                                <div class="bs-stepper-circle">3</div>
                                <div class="">
                                    <h5 class="mb-0 steper-title">Payment Details</h5>
                                    <p class="mb-0 steper-sub-title">Choose Payment Method.</p>
                                </div>
                            </div>
                        </div>
                        <div class="bs-stepper-line"></div>
                        <div class="step" data-target="#test-l-4">
                            <div
                                class="step-trigger"
                                role="tab"
                                id="stepper1trigger4"
                                aria-controls="test-l-4"
                            >
                                <div class="bs-stepper-circle">4</div>
                                <div class="">
                                    <h5 class="mb-0 steper-title">Confirmation</h5>
                                    <p class="mb-0 steper-sub-title">Checkout</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="card-body">
                    <div class="bs-stepper-content">
                        <form onSubmit="return false">
                            <div
                                id="test-l-1"
                                role="tabpanel"
                                class="bs-stepper-pane"
                                aria-labelledby="stepper1trigger1"
                            >
                                <h5 class="mb-1">Seller Information</h5>
                                <p class="mb-4">
                                    Please enter the phone number of the user
                                    you want to purchase from
                                </p>

                                <div class="row g-3">
                                    <searchfor-seller
                                        ref="searchForSellerRef"
                                        :members="members"
                                        :auth-user="app.user"
                                        @productSeller="setSeller"
                                        :borrowinglimit_clear="borrowingLimitNew"
                                        @loggedInUserUpdate="changeUserWhoIsLoggedIn"
                                        @productChosen="setProduct"
                                    >
                                    </searchfor-seller>
                                </div>
                                <!---end row-->
                            </div>

                            <div
                                id="test-l-2"
                                role="tabpanel"
                                class="bs-stepper-pane"
                                aria-labelledby="stepper1trigger2"
                            >
                                <h5 class="mb-1">
                                    Product or Service Information
                                </h5>
                                <div class="row g-3">
                                    <payment-purpose
                                        :user="seller"
                                        :key="key"
                                        :is-agent-order="isAgentOrder"
                                        ref="payment"
                                        @setproduct="productset"
                                        @amountsetevent="(e) => (amount = e)"
                                        @quantitysetevent="(e) => (quantity = e)"
                                        @agentFeeSetEvent="(e) => (agentFee = e)"
                                    >
                                    </payment-purpose>

                                    <div class="col-12">
                                        <div class="d-flex align-items-center gap-3">
                                            <button
                                                :style="{color: '#ff7900 !important'}"
                                                class="btn warn-button-border"
                                                @click.prevent="previousWithReset"
                                            >
                                                <i class="bx bx-left-arrow-alt me-2"
                                                   :style="{color: '#ff7900 !important'}"></i>
                                                Previous
                                            </button>
                                            <button
                                                class="btn add-button-coamana text-white"
                                                @click.prevent="handleNextStepForAmount"
                                            >
                                                Next <i class="bx bx-right-arrow-alt ms-2"></i>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <!---end row-->
                            </div>

                            <div
                                id="test-l-3"
                                role="tabpanel"
                                class="bs-stepper-pane"
                                aria-labelledby="stepper1trigger3"
                            >
                                <div class="row">
                                    <div class="col-12"><h5>Payment</h5></div>
                                </div>

                                <div class="row g-3 mt-3">
                                    <PaymentSummary
                                        ref="paymentsummary"
                                        @enablepayment="payments"
                                        @totalamountbilled="totalBilled"
                                        :product="product"
                                        :market="market"
                                        :key="key"
                                        :amount="amount"
                                        :quantity="quantity"
                                        :is-agent-order="isAgentOrder"
                                        :agent-fee="agentFee"
                                        :buyer="orderStore.buyer"
                                        @selectedmethod="(args) => (selectedmethod = args)"
                                        @updatenumberofdays="(args) => (numberofdays = args)"
                                        :borrowinglimit_clear="borrowingLimitNew"
                                        :borrowinglimit_clear_prop="borrowingLimitNew"
                                        :balance_clear_prop="walletBalance"
                                        @isInvalidDaysToPay="(args) => (invalidDaysToPay = args)"
                                        :overdue_charge="overdue_charge"
                                    ></PaymentSummary>

                                    <div class="col-12">
                                        <div class="d-flex align-items-center gap-3">
                                            <button
                                                :style="{color: '#ff7900 !important'}"
                                                class="btn warn-button-border"
                                                @click.prevent="previous"
                                            >
                                                <i :style="{color: '#ff7900 !important'}"
                                                   class="bx bx-left-arrow-alt me-2"></i>
                                                Previous
                                            </button>
                                            <button
                                                class="btn add-button-coamana text-white"
                                                @click.prevent="stepperNextOTP"
                                                :disabled="
                                                    (selectedmethod === 'buyer_wallet' && (paymentTotal > parseInt(buyerWalletBalance()))) ||
                                                    (selectedmethod === 'credit' && (amount > parseInt(borrowingLimitNew) || invalidDaysToPay)) ||
                                                    (selectedmethod === 'buyer_credit' && (amount > parseInt(buyerCreditBalance()) || invalidDaysToPay))
                                                "
                                            >
                                                Next
                                                <i class="bx bx-right-arrow-alt ms-2"></i>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <!---end row-->
                            </div>

                            <div
                                id="test-l-4"
                                role="tabpanel"
                                class="bs-stepper-pane"
                                aria-labelledby="stepper1trigger4"
                            >
                                <div class="row">
                                    <div class="col-12">
                                        <h5>Confirmation</h5>
                                    </div>
                                </div>
                                <div class="row g-3 mt-3">
                                    <template v-if="sendOTPToUser">
                                        <send-o-t-p-for-payment
                                            :errors="errors"
                                            @updatedotp="(args) => (otp = args)"
                                        ></send-o-t-p-for-payment>
                                    </template>
                                    <template v-else>
                                        <div class="col-12 mt-3">
                                            <div class="alert alert-danger" role="alert">
                                                <div class="d-flex justify-content-between">
                                                    <div>
                                                        <i class="fa fa-info-circle fa-3x"></i>
                                                    </div>
                                                    <div class="px-4 flex-grow-1">
                                                        <p>
                                                            There was an error
                                                            sending OTP to the
                                                            <strong>phone number</strong>
                                                            associated with your
                                                            account. Please
                                                            check your details
                                                            and try again. If
                                                            the problem
                                                            persists, please
                                                            contact our support
                                                            team.
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </template>
                                    <div class="row">
                                        <div class="col-12">
                                            <ResendOTP :ref="setChildRef" v-if="sendOTPToUser" :send-otp="sendOTP"/>
                                        </div>
                                    </div>
                                    <div class="col-12 d-flex justify-content-end">
                                        <div class="d-flex align-items-center gap-3">
                                            <button
                                                :style="{color: '#ff7900 !important'}"
                                                class="btn warn-button-border"
                                                @click.prevent="previous"
                                            >
                                                <i :style="{color: '#ff7900 !important'}"
                                                   class="bx bx-left-arrow-alt me-2"/>
                                                Previous
                                            </button>

                                            <template
                                                v-if="paymentTotal > parseInt(walletBalance) && paymentMethod !== 'credit'">
                                                <button
                                                    v-if="sendOTPToUser"
                                                    type="button"
                                                    class="btn btn-success px-4"
                                                    @click="updateTopUpAmount"
                                                    id="topupaccount"
                                                >
                                                    Complete Payment
                                                </button>
                                            </template>
                                            <template
                                                v-else-if="
                                                    (selectedmethod === 'buyer_wallet' && (paymentTotal > parseInt(buyerWalletBalance()))) ||
                                                    (selectedmethod === 'credit' && (amount > parseInt(borrowingLimitNew))) ||
                                                    (selectedmethod === 'buyer_credit' && (amount > parseInt(buyerCreditBalance())))
                                                "
                                            >
                                                <button class="btn btn-secondary px-4" disabled>
                                                    Next
                                                    <i class="fa fa-ban fa-thin"></i>
                                                </button>
                                            </template>
                                            <template v-else>
                                                <button
                                                    class="btn btn-success px-4"
                                                    @click.prevent="makeOrder(false)"
                                                    :disabled="finishing"
                                                >
                                                    Next
                                                    <i class="fa fa-spin fa-spinner" v-if="finishing"></i>
                                                </button>
                                            </template>
                                        </div>
                                    </div>
                                </div>
                                <!---end row-->
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
        <div v-else>
            <div class="container">
                <div class="col-12">
                    <div class="d-flex justify-content-center">
                        <div class="card">
                            <div class="card-body">
                                <h4 class="card-title">Your order has been placed</h4>
                                <div class="table-responsive">
                                    <table class="table table-sm table-bordered table-card">
                                        <tbody>
                                        <tr v-if="paymentMethod === 'credit'">
                                            <th>Method:</th>
                                            <td>
                                                You borrowed from {{ product.market.name }} market to pay this product
                                                or
                                                service
                                            </td>
                                        </tr>
                                        <tr v-else>
                                            <th>Amount Deducted from your account:</th>
                                            <td>{{ currency }}{{ parseFloat(paymentTotal).toLocaleString() }}</td>
                                        </tr>
                                        <tr>
                                            <th>Product or Service:</th>
                                            <td>{{ product.product.name }}</td>
                                        </tr>
                                        <tr>
                                            <th>Quantity:</th>
                                            <td>{{ quantity ? quantity : 1 }}</td>
                                        </tr>
                                        <tr>
                                            <th>Seller</th>
                                            <td>{{ product.member.name }}&mdash;<span class="text-muted">{{
                                                    product.member.phone
                                                }}</span>
                                            </td>
                                        </tr>
                                        <tr v-if="paymentMethod === 'credit'">
                                            <th>Payment Due</th>
                                            <td>{{ currency }}{{ parseFloat(amount).toLocaleString() }}</td>
                                        </tr>
                                        <tr v-if="paymentMethod === 'credit'">
                                            <th>Payment Due Date</th>
                                            <td>{{ calculateDays(numberofdays) }}</td>
                                        </tr>
                                        <tr v-if="paymentMethod === 'credit'">
                                            <th>Amount due on due Date</th>
                                            <td>{{ currency }}{{ parseFloat(paymentTotal,).toLocaleString() }}</td>
                                        </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row ">
                        <div class="div d-flex justify-content-center tw-mb-4">
                            <a class="btn btn-outline-success" href="/four/market/products?delivery=true">
                                Book Delivery
                            </a>
                        </div>
                    </div>
                    <div class="row">
                        <div
                            class="tw-flex tw-flex-col md:tw-justify-center justify-content-center"
                            v-if="product?.member?.name && product?.market?.name"
                        >
                            <div class="div d-flex justify-content-center tw-mb-4">
                                <div class="tw-w-full md:tw-w-[600px]">
                                    <div class="alert alert-default" role="alert">

                                        <div class="accordion" id="accordionExample">
                                            <div class="accordion-item">
                                                <h2 class="accordion-header" id="headingOne">
<!--                                                    <a class="accordion-button" type="button" data-bs-toggle="collapse"-->
<!--                                                       data-bs-target="#collapseOne" aria-expanded="true"-->
<!--                                                       aria-controls="collapseOne">-->
<!--                                                        <p>-->
<!--                                                            Insurance cover for your order&nbsp;<strong>-->
<!--                                                            {{ createdOrder.order_id }}</strong>-->
<!--                                                        </p>-->
<!--                                                    </a>-->
                                                </h2>
                                                <div id="collapseOne" class="accordion-collapse collapse show"
                                                     aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                                                    <div class="accordion-body">
                                                       <p>
                                                           You can get access to cover if you lose your products in transit
                                                           or storage and you can file a claim on Amana Market
                                                       </p>
                                                        <button class="btn btn-sm btn-success"  data-bs-toggle="modal" data-bs-target="#getcover">
                                                            Get Cover
                                                        </button>

                                                        <!-- Modal -->
                                                        <div class="modal fade" id="getcover" tabindex="-1"
                                                             role="dialog"
                                                             aria-labelledby="getcoverid" aria-hidden="true">
                                                            <div class="modal-dialog" role="document">
                                                                <div class="modal-content">
                                                                    <div class="modal-header">
                                                                        <h5 class="modal-title" id="getcoverid">
                                                                            Confirmation
                                                                        </h5>
                                                                        <button type="button" class="btn-close"
                                                                                data-bs-dismiss="modal"
                                                                                aria-label="Close">

                                                                        </button>
                                                                    </div>
                                                                    <div class="modal-body">
                                                                        <div class="container-fluid">
                                                                           <div class="row">
                                                                               <GetCover :order="createdOrder"></GetCover>
                                                                           </div>
                                                                        </div>
                                                                    </div>
<!--                                                                    <div class="modal-footer">-->
<!--                                                                        <button type="button" class="btn btn-secondary"-->
<!--                                                                                data-bs-dismiss="modal">Close-->
<!--                                                                        </button>-->
<!--                                                                        <button type="button" class="btn btn-primary">-->
<!--                                                                            Save-->
<!--                                                                        </button>-->
<!--                                                                    </div>-->
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="div d-flex justify-content-center tw-mb-4">
                                <div class="tw-w-full md:tw-w-[600px] tw-flex tw-content-center">
                                    <div class="alert alert-default" role="alert">
                                        <div>
                                            Please meet
                                            <strong>{{ product?.member?.name }}</strong> at
                                            <strong>{{ product?.market?.name }}</strong> to complete the transaction.
                                            Contact
                                            seller on
                                            <strong>{{ product?.member?.phone }}</strong>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>

        <div>
            <div
                class="modal fade"
                id="topupInline"
                tabindex="-1"
                role="dialog"
                aria-labelledby="topupInline"
                aria-hidden="true"
            >
                <div class="modal-dialog" role="document">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h4 class="modal-title" id="topupInline">Top up</h4>
                            <button
                                type="button"
                                class="btn-close"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                            ></button>
                        </div>
                        <div class="modal-body">
                            <div class="container-fluid">
                                <InlineTopUp
                                    ref="inlineTopUp"
                                    @walletToppedUp="walletToppedUp"
                                    :amountToPay="amountToTopUp"
                                    :virtual_bank_account="props.virtual_bank_account"
                                    @walletToppedUpAmount="(args) => (toppedUpAmount = args)"
                                ></InlineTopUp>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped>
.accordion-button {
    background-color: #e2e3e5;
}

.accordion-body {
    background-color: #e2e3e5;
}
</style>
